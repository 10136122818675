export class Functions {

  static groupByTyped<T>(array: T[], keyFn: (item: T) => (string | number)[]): { [key: string]: T[] } {
    const groups: { [key: string]: T[] } = {};
    array.forEach(o => {
      const groupKey = JSON.stringify(keyFn(o));
      groups[groupKey] = groups[groupKey] || [];
      groups[groupKey].push(o);
    });
    return groups;
  }

  static groupByKeyTyped<T extends Record<string, any>>(array: T[], key: keyof T): { [key: string]: T[] } {
    return array.reduce((hash, obj) => {
      const keyValue = obj[key];
      if (keyValue !== undefined) {
        const keyStr = String(keyValue);
        if (!hash[keyStr]) {
          hash[keyStr] = [];
        }
        hash[keyStr].push(obj);
      }
      return hash;
    }, {});
  }

  /**
   * @deprecated
   */
  static groupByKey(array, key): any {
    return array
      .reduce((hash, obj) => Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)}), {});
  }

  /**
   * @deprecated
   */
  static groupBy(array, f) {
    const groups = {};
    array.forEach(o => {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(group => groups[group]);
  }
}
