import {Component} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    translate.addLangs(['pl', 'en']);
    translate.setDefaultLang('pl');

    // Store language in localStorage
    const lang = localStorage.getItem('language');
    if (lang) {
      translate.use(lang);
    } else {
      translate.use(environment.defaultLanguage);
    }
    translate.onLangChange
      .subscribe(($event: LangChangeEvent) => localStorage.setItem('language', $event.lang));
  }
}
