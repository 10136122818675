import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnswersService} from 'src/app/modules/annonymous/answers/answers.service';
import {IndexFormData} from 'src/app/modules/annonymous/answers/model/index-form-data';
import {FormAnswer, OrganizationReportAssignedReviewerVO, SaveFormAnswersCommand} from 'src/app/openapi';
import {ConfirmDialogComponent} from 'src/app/shared/toolkit/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Department} from '../../../../admin/report-metadata-settings/models/department';
import {DepartmentService} from '../../../../admin/report-metadata-settings/services/department.service';
import {ProposalAction} from '../../model/answer-proposal.model';
import {ProposalService} from '../../proposal.service';
import {
  FAILED_SAVE_DIALOG_CONFIGURATION,
  SUCCESS_SAVE_DIALOG_CONFIGURATION,
  YOU_HAVE_TO_ANSWER_ALL_CHECKLIST_QUESTIONS
} from './answers-form.dialog';
import {
  ReviewerService
} from '../../../../user/dashboard/components/reviewer-notification-popup/reviewer-notification-modal/service/reviewer.service';
import {questionWithAnswersToIndexFormData} from './questions-group';
import {defaultAnswer} from '../mappers/default-answer-factory';

@Component({
  selector: 'app-answers-form',
  templateUrl: './answers-form.component.html',
  styleUrls: ['./answers-form.component.scss']
})
export class AnswersFormComponent implements OnInit {

  readonly token: string;

  indices: IndexFormData[];
  isSaving = false;
  organizationName = '';
  reviewer: OrganizationReportAssignedReviewerVO | null;
  globalProposal: boolean;
  reportId: number;

  constructor(
    private readonly router: Router,
    private readonly answersService: AnswersService,
    private readonly dialog: MatDialog,
    private readonly departmentService: DepartmentService,
    private readonly reviewerService: ReviewerService,
    private readonly proposalService: ProposalService,
    activatedRoute: ActivatedRoute
  ) {
    this.token = activatedRoute.snapshot.paramMap.get('token');
  }

  ngOnInit(): void {
    if (!this.token) {
      return;
    }

    // check if token is assigned for reviewer
    this.reviewerService.findReviewerByToken(this.token)
      .subscribe(
        reviewer => this.reviewer = reviewer,
        () => this.reviewer = null
      );

    this.departmentService.fetchSortedDepartments().subscribe((departments: Department[]) => {
      this.answersService.combineUserQuestionsWithPreviousAnswersIntoSingleObservableArray(this.token)
        .subscribe((questions) => {
          if (questions.length !== 0) {
            this.organizationName = questions[0].organizationName;
          }
          this.indices = questionWithAnswersToIndexFormData(questions, departments);
          this.reportId = this.indices.map(index => index.reportId)[0];

          this.indices
            .filter(question => question.taxonomyCategory)
            .filter(question => question.taxonomyCategory === 'IMPORTANT_CONTRIBUTION')
            .forEach(index => this.matchExcludingIndex(index));

          this.globalProposal = this.indices.some((form) => form.isAnyProposal);
          this.proposalService.isAnyProposal = this.globalProposal;
          if (this.globalProposal) {
            this.proposalService.setProposals(this.indices);
          }
        });
    });
  }

  globalProposalOnChange(action: ProposalAction) {
    this.globalProposal = false;
    this.proposalService.globalAction = action;
    if (action.skip) {
      this.proposalService.clearProposals(this.indices);
    } else if (action.use) {
      this.proposalService.useAllProposals(this.indices);
    }
  }

  saveAnswers(): void {
    this.isSaving = true;

    const shouldThrowError = this.checkIfAllChecklistQuestionsAreComplete();
    if (shouldThrowError) {
      this.isSaving = false;
      this.dialog.open(ConfirmDialogComponent, YOU_HAVE_TO_ANSWER_ALL_CHECKLIST_QUESTIONS);
      return;
    }

    const command: SaveFormAnswersCommand = {
      token: this.token,
      answers: this.prepareAnswersMap()
    } as SaveFormAnswersCommand;

    this.answersService.saveAnswers(command).subscribe(() => {
      this.isSaving = false;
      this.dialog.open(ConfirmDialogComponent, SUCCESS_SAVE_DIALOG_CONFIGURATION);
    }, () => {
      this.isSaving = false;
      this.dialog.open(ConfirmDialogComponent, FAILED_SAVE_DIALOG_CONFIGURATION);
    });
  }

  private checkIfAllChecklistQuestionsAreComplete(): boolean {
    let shouldThrowError = false;
    for (const index of this.indices) {
      const checklistQuestions = index.questions.filter(q => q.questionContent.answerTypes?.CHECKLIST);
      for (const question of checklistQuestions) {
        const values = question.questionContent.answerTypes.CHECKLIST.values;
        const selected = question.answerContent.answer.CHECKLIST.selected;
        if (!selected || !values.every(value => selected.includes(value))) {
          shouldThrowError = true;
          break;
        }
      }

      if (shouldThrowError) {
        break;
      }
    }
    return shouldThrowError;
  }


  private prepareAnswersMap = (): FormAnswer[] => {
    const answers: FormAnswer[] = [];
    this.indices.forEach(i => {
      i.questions.forEach(questionFormData => {
        const answerContent = defaultAnswer(questionFormData, i.customMetricName);
        answers.push({
          questionId: questionFormData.questionId,
          customMetricName: answerContent.customMetricName,
          answerContent
        });
      });
    });
    return answers;
  }

  private matchExcludingIndex(indexToMatch: IndexFormData) {
    const matchedIndex = this.indices
      .filter(question => question.taxonomyCategory === 'AVOID_SERIOUS_DAMAGE')
      .filter(question => this.equals(question, indexToMatch))
      .filter(index => index.indexName === indexToMatch.indexName)[0];

    if (matchedIndex) {
      indexToMatch.excludingIndex = matchedIndex;
    }
  }

  private equals(question: IndexFormData, indexToMatch: IndexFormData) {
    if (question.indexContent.departmentIds.length != indexToMatch.indexContent.departmentIds.length) {
      return false;
    }
    return question.indexContent.departmentIds.every(element => indexToMatch.indexContent.departmentIds.includes(element));
  }

  isReviewer = (): boolean => this.reviewer !== null;
}
