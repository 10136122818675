import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, Subscription} from 'rxjs';
import {SmartFormDataSourceDef} from '../smart-form/smart-form.api';

export interface SmartFormI18NDialogConfiguration {
  title: string;
  formDataSource: SmartFormDataSourceDef;
  cancelBtnLabel: string;
  acceptBtnLabel: string;
  postActionErrorMsg: string;
}

@Component({
  selector: 'app-smart-form-dialog',
  templateUrl: './smart-form-i18-n-dialog.component.html',
  styleUrls: ['./smart-form-i18-n-dialog.component.scss']
})
export class SmartFormI18NDialogComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() cancelBtnLabel = 'ui.user.dashboard.indices-list.cancel';
  @Input() acceptBtnLabel = 'ui.user.dashboard.indices-list.send';
  @Input() postActionErrorMsg = 'ui.user.dashboard.indices-list.fail-to-send-email';
  @Input() formDataSource: SmartFormDataSourceDef;

  private subscriptions: Subscription = new Subscription();
  saveDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<SmartFormI18NDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: SmartFormI18NDialogConfiguration
  ) {
    this.title = data?.title ?? this.title;
    this.cancelBtnLabel = data?.cancelBtnLabel ?? this.cancelBtnLabel;
    this.acceptBtnLabel = data?.acceptBtnLabel ?? this.acceptBtnLabel;
    this.postActionErrorMsg = data?.postActionErrorMsg ?? this.postActionErrorMsg;
    this.formDataSource = data?.formDataSource ?? this.formDataSource;
  }

  ngOnInit(): void {
    this.subscriptions.add(this.formDataSource.isNotValid$.subscribe(notValid => {
      this.saveDisabled$.next(notValid);
    }));
  }

  save() {
    this.saveDisabled$.next(true);
    this.subscriptions.add(this.formDataSource.postData().subscribe(_ => {
      this.dialogRef.close(true);
    }, error => {
      this.snackBar.open(this.postActionErrorMsg, null, {
        duration: 2500,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        panelClass: 'red-snackbar'
      });
      this.saveDisabled$.next(false);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
