import { Pipe, PipeTransform } from '@angular/core';
import {AnswerTypes} from 'src/app/modules/admin/report-metadata-settings/models/answer-types';

@Pipe({
  name: 'inGroup'
})
export class InGroupPipe<T extends {answerTypes?: AnswerTypes}> implements PipeTransform {

  transform(question: T, isAtPosition: 'first' | 'last', all: T[]): boolean {
    const index: number = all.indexOf(question);
    return isAtPosition === 'first' ? this.isFirstInGroup(question, index, all) : this.isLastInGroup(question, index, all);
  }

  isFirstInGroup(question: T, index: number, all: T[]): boolean {
    return index === 0 ||
        all[index - 1].answerTypes?.GROUP && question.answerTypes?.GROUP &&
        all[index - 1].answerTypes.GROUP.name !== question.answerTypes.GROUP.name ||
        !all[index - 1].answerTypes.GROUP;
  }

  isLastInGroup(question: T, index: number, all: T[]): boolean {
    return index === all.length - 1 ||
        all[index + 1].answerTypes?.GROUP && question.answerTypes?.GROUP &&
        all[index + 1].answerTypes.GROUP.name !== question.answerTypes.GROUP.name ||
        !all[index + 1].answerTypes.GROUP;
  }

}
