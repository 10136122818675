import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'angular-highcharts';
import { AngularMaterialModule } from '../../angular-material.module';
import { IsDatePipe } from '../pipes/is-date.pipe';
import { KeyValueMapPipe } from '../pipes/key-value-map.pipe';
import { TranslatedPropertyPipe } from '../pipes/translated-property.pipe';
import { UserNamePipe } from '../pipes/user-name.pipe';
import { ToolkitModule } from '../toolkit/toolkit.module';
import { AddEditModalComponent } from './add-edit-modal/add-edit-modal.component';
import { AspektChartComponent } from './aspekt-chart/aspekt-chart.component';
import {
  DownloadQuestionAttachmentsComponent
} from './download-question-attachments/download-question-attachments.component';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { EditModeControlsComponent } from './edit-mode-controls/edit-mode-controls.component';
import { FilterWithChipsComponent } from './filter-with-chips/filter-with-chips.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { PlusButtonComponent } from './plus-button/plus-button.component';
import { ReportDownloadSummaryComponent } from './report-download-summary/report-download-summary.component';
import { ReportFinishComponent } from './report-finish/report-finish.component';
import { YesNoQuestionComponent } from './yes-no-question/yes-no-question.component';
import { AspectChartSmartTableModalComponent } from './aspekt-chart/aspect-chart-smart-table-modal.component';


@NgModule({
  declarations: [
    PlusButtonComponent,
    FilterWithChipsComponent,
    EditButtonComponent,
    YesNoQuestionComponent,
    EditModeControlsComponent,
    AddEditModalComponent,
    UserNamePipe,
    IsDatePipe,
    LanguageSelectComponent,
    ReportFinishComponent,
    ReportDownloadSummaryComponent,
    DownloadQuestionAttachmentsComponent,
    ReportDownloadSummaryComponent,
    TranslatedPropertyPipe,
    KeyValueMapPipe,
    AspektChartComponent,
    AspectChartSmartTableModalComponent
  ],
  exports: [
    PlusButtonComponent,
    FilterWithChipsComponent,
    EditButtonComponent,
    YesNoQuestionComponent,
    EditModeControlsComponent,
    AddEditModalComponent,
    UserNamePipe,
    TranslatedPropertyPipe,
    IsDatePipe,
    KeyValueMapPipe,
    LanguageSelectComponent,
    ReportFinishComponent,
    DownloadQuestionAttachmentsComponent,
    ReportDownloadSummaryComponent,
    DownloadQuestionAttachmentsComponent,
    AspektChartComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ToolkitModule,
    FlexLayoutModule,
    MatCheckboxModule,
    TranslateModule,
    ChartModule,
    MatButtonToggleModule
  ],
  providers: [
    UserNamePipe,
    IsDatePipe,
    TranslatedPropertyPipe,
    KeyValueMapPipe
  ]
})
export class SharedComponentsModule {
}
