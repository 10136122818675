import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterChangeService {

  private subject: Subject<FilterChange> = new Subject<FilterChange>();

  publish(event: FilterChange): void {
    this.subject.next(event);
  }

  subscribe(next?: (value: FilterChange) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return this.subject.subscribe(next, error, complete);
  }
}

export interface FilterChange {
  column: string;
  value: string | boolean;
}
