/**
 * SReport API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationForDatabaseReportVO { 
    organizationId?: number;
    variantName?: string;
    reportId?: number;
    status?: OrganizationForDatabaseReportVO.StatusEnum;
    projectName?: string;
    createdAt?: string;
    finishedAt?: string;
}
export namespace OrganizationForDatabaseReportVO {
    export type StatusEnum = 'NONE' | 'AVAILABLE' | 'UNAVAILABLE';
    export const StatusEnum = {
        NONE: 'NONE' as StatusEnum,
        AVAILABLE: 'AVAILABLE' as StatusEnum,
        UNAVAILABLE: 'UNAVAILABLE' as StatusEnum
    };
}


