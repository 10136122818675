import {Injectable} from '@angular/core';
import {TranslationEndpointService} from "../../openapi";
import {TranslateLoader} from "@ngx-translate/core";
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService implements TranslateLoader {

  constructor(private readonly translationEndpointService: TranslationEndpointService) {
  }

  getTranslation(lang: string): Observable<any> {
    return this.translationEndpointService.fetchMessages(lang);
  }
}
