import {Component, Input, OnInit} from '@angular/core';
import {ReportVO} from '../../../openapi';
import {ConfirmDialogComponent} from '../../toolkit/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogConfig} from '@angular/material/dialog/dialog-config';
import {Observable} from 'rxjs';
import {TranslateFacadeService} from '../../services/translate-facade.service';
import {ReportService} from '../../../modules/admin/report-metadata-settings/services/report.service';

@Component({
  selector: 'app-report-finish',
  templateUrl: './report-finish.component.html',
  styleUrls: ['./report-finish.component.scss'],
})
export class ReportFinishComponent implements OnInit {

  @Input()
  public report: ReportVO;
  public isFinishingReport = false;

  private translations: { [p: string]: string };

  constructor(
    private readonly dialog: MatDialog,
    private readonly translate: TranslateFacadeService,
    private readonly reportService: ReportService,
  ) {
  }

  ngOnInit(): void {
    this.translate.translationsOnInit().subscribe(translations => this.translations = translations);
  }

  public openReopenReportModal() {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, this.getReactivationMainDialogConfig());

    confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          confirmDialogRef.close(result);
          this.isFinishingReport = true;
          this.finishReport(false).subscribe(() => {
            this.isFinishingReport = false;
            this.report.isFinished = false;
            this.dialog.open(ConfirmDialogComponent, this.getReactivationDialogSuccessConfig());
          }, () => {
            this.isFinishingReport = false;
            this.dialog.open(ConfirmDialogComponent, this.getReactivationDialogErrorConfig());
          });
        }
      },
    );
  }

  public openFinishReportModal(): void {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, this.getFinishMainDialogConfig());

    confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          confirmDialogRef.close(result);
          this.isFinishingReport = true;
          this.finishReport(true).subscribe(() => {
            this.isFinishingReport = false;
            this.report.isFinished = true;
            this.dialog.open(ConfirmDialogComponent, this.getFinishErrorDialogConfig());
          }, () => {
            this.isFinishingReport = false;
            this.dialog.open(ConfirmDialogComponent, this.getFinishSuccessDialogConfig());
          });
        }
      },
    );
  }

  private finishReport(isFinished: boolean): Observable<void> {
    const command = {
      reportId: this.report.id,
      isFinished,
    };
    return this.reportService.finishReport(command);
  }

  private getReactivationMainDialogConfig = (): MatDialogConfig => ({
    data: {
      title: this.translations['ui.user.dashboard.taxonomy-report-dashboard.reactivation.question.title'],
      message: this.translations['ui.user.dashboard.taxonomy-report-dashboard.reactivation.question.message'],
      confirmLabel: this.translations['ui.user.dashboard.taxonomy-report-dashboard.reactivation.question.confirmLabel'],
      noLabel: this.translations['ui.common.no'],
      icon: 'help_outline',
      iconColor: 'success',
    },
  })

  private getReactivationDialogErrorConfig = (): MatDialogConfig => ({
    data: {
      title: this.translations['ui.user.dashboard.taxonomy-report-dashboard.reactivation.error'],
      message: this.translations['ui.user.dashboard.taxonomy-report-dashboard.reactivation.errorMessage'],
      onlyOkOption: true,
      icon: 'error_outline',
      iconColor: 'error',
    },
  })

  private getReactivationDialogSuccessConfig = (): MatDialogConfig => ({
    data: {
      title: this.translations['ui.user.dashboard.taxonomy-report-dashboard.reactivation.success'],
      message: this.translations['ui.user.dashboard.taxonomy-report-dashboard.reactivation.successMessage'],
      onlyOkOption: true,
      icon: 'check_circle_outline',
      iconColor: 'success',
    },
  })


  private getFinishSuccessDialogConfig = (): MatDialogConfig => ({
    data: {
      title: this.translations['ui.user.dashboard.taxonomy-report-dashboard.finish.error'],
      message: this.translations['ui.user.dashboard.taxonomy-report-dashboard.finish.errorMessage'],
      onlyOkOption: true,
      icon: 'error_outline',
      iconColor: 'error',
    },
  })

  private getFinishErrorDialogConfig = (): MatDialogConfig => ({
    data: {
      title: this.translations['ui.user.dashboard.taxonomy-report-dashboard.finish.success'],
      message: this.translations['ui.user.dashboard.taxonomy-report-dashboard.finish.successMessage'],
      onlyOkOption: true,
      icon: 'check_circle_outline',
      iconColor: 'success',
    },
  })

  private getFinishMainDialogConfig = (): MatDialogConfig => ({
    data: {
      title: this.translations['ui.user.dashboard.taxonomy-report-dashboard.finish.question.title'],
      message: this.translations['ui.user.dashboard.taxonomy-report-dashboard.finish.question.message'],
      confirmLabel: this.translations['ui.user.dashboard.taxonomy-report-dashboard.finish.question.confirmLabel'],
      noLabel: this.translations['ui.common.no'],
      icon: 'help_outline',
      iconColor: 'success',
    },
  })
}
