import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';


@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {

  @HostBinding('className') hostClass = 'host';

  @Input() title: string;
  @Input() borderType: 'shadow' | 'solid' | 'solid-tab-content' = 'shadow';
  @Input() highlight: string | undefined = undefined;
  @Input() selectValues: PanelSelectItem[];
  @Input() selectLabel: string;
  @Input() selectedValue;
  @Output() selectedValueChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input() styleFillParent: boolean;
  @Input() icon: string;
  @Input() clickable: boolean;
  @Output() iconClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input() customCSSClassPostfix: string;

  constructor() {}

  click(): void {
    if (this.clickable) {
      this.iconClicked.emit();
    }
  }

  getCustomCssClass(classStart: string) {
    if (this.customCSSClassPostfix != null) {
      return `${classStart}-${this.customCSSClassPostfix}`;
    }
    return '';
  }
}

export interface PanelSelectItem {
  value: string;
  label: string;
}
