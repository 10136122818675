<form (ngSubmit)="submitEvent.emit(this.formGroup.value)" [formGroup]="formGroup" fxLayout="column">
  <ng-container *ngFor="let field of dataSourceDef.fields" [ngSwitch]="field.type">
    <ng-container *ngIf="!readonly">
      <app-smart-form-text *ngSwitchCase="'text'" [field]="field" [formGroup]="formGroup"></app-smart-form-text>
      <app-smart-form-number *ngSwitchCase="'number'" [field]="field" [formGroup]="formGroup"></app-smart-form-number>
      <app-smart-form-email *ngSwitchCase="'email'" [field]="field" [formGroup]="formGroup"></app-smart-form-email>
      <app-smart-form-dropdown *ngSwitchCase="'dropdown'" [field]="field"
                               [formGroup]="formGroup"></app-smart-form-dropdown>
      <app-smart-form-hidden *ngSwitchCase="'hidden'" [field]="field" [formGroup]="formGroup"></app-smart-form-hidden>
      <app-smart-form-file *ngSwitchCase="'file'" [field]="field" [formGroup]="formGroup"></app-smart-form-file>
      <!--        <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></checkbox>-->
      <!--        <radio *ngSwitchCase="'radio'" [field]="field" [form]="form"></radio>-->
    </ng-container>
    <ng-container *ngIf="readonly">
      <app-smart-form-text [field]="field" [formGroup]="formGroup" [readonly]="true"></app-smart-form-text>
    </ng-container>
  </ng-container>
</form>
