<div class="sidebar-open-btn" *ngIf="!opened && showToggleBtn">
  <button mat-icon-button color="primary" (click)="toggle()">
    <mat-icon>chevron_left</mat-icon>
  </button>
</div>

<div class="sidebar" fxLayout="column" fxLayoutAlign="start center" *ngIf="opened">
  <button class="sidebar-close-btn" mat-icon-button color="primary" (click)="toggle()">
    <mat-icon>chevron_right</mat-icon>
  </button>
  <ng-template [cdkPortalOutlet]="sidebarPortal"></ng-template>
</div>
