import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LangChangeEvent } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {
  SectionedResponse
} from '../../../modules/user/dashboard/components/status-dashboard/status-dashboard.service';
import { OrganizationReportAnswerStatusVO } from '../../../openapi';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import {
  SmartTableColumn,
  SmartTableColumnType,
  SmartTableDataProvider,
  SmartTableDataSourceDef,
  SmartTablePageData
} from '../../toolkit/smart-table/smart-table.api';

export interface AspectChartSmartTableModalInputData {
  response: SectionedResponse;
}

@Component({
  selector: 'app-aspect-chart-smart-table-modal',
  template: `
    <app-add-edit-modal [headerAdd]="header" [customIcon]="''" [buttonsEnabled]="false" [showIcon]="false">
      <app-smart-table *ngIf="!loading" [dataSourceDef]="definition" [hideCommandPanel]="true" [actionButtons]="[]"></app-smart-table>
      <mat-spinner *ngIf="loading" [diameter]="30" class="spinner"></mat-spinner>
    </app-add-edit-modal>
  `
})
export class AspectChartSmartTableModalComponent implements OnInit {

  definition!: SmartTableDataSourceDef;
  loading = true;

  header: string;

  private language: string;

  constructor(
    private readonly translate: TranslateFacadeService,
    @Inject(MAT_DIALOG_DATA) private readonly data: AspectChartSmartTableModalInputData
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(({lang}: LangChangeEvent) => this.language = lang);
    this.translate.translationsOnInit().subscribe(t => {
      this.header = t['ui.user.dashboard.default-report.status-section.header'] ?? 'Tabela danych Sekcji/Aspektu';
      this.definition = this.getDataSource(t, this.language);
      this.definition.reload();
      this.loading = false;
    });
  }

  /**
   * # Status section
   * ui.user.dashboard.default-report.status-section.section_name=Section name
   * ui.user.dashboard.default-report.status-section.index_number=Index number
   * ui.user.dashboard.default-report.status-section.index_name=Index name
   * ui.user.dashboard.default-report.status-section.user_email=E-mail
   * ui.user.dashboard.default-report.status-section.status=Status
   * ui.user.dashboard.default-report.status-section.status.not-assigned=Not assigned
   * ui.user.dashboard.default-report.status-section.status.not-send=Not sent
   * ui.user.dashboard.default-report.status-section.status.not-filled=Not filled
   * ui.user.dashboard.default-report.status-section.status.ready=Ready
   * ui.user.dashboard.default-report.status-section.answer-time=Answer time
   */
  getDataSource(translations: { [p: string]: string }, language: string): SmartTableDataSourceDef {
    const provider = new SmartTableProvider(this.data.response.answers);
    const definition = new SmartTableDataSourceDef(provider);

    function translateIndexName(row: any, value: any): string {
      const answer = row as OrganizationReportAnswerStatusVO;
      if (language !== 'pl') {
        return answer.indexTranslation[language]?.name || answer.indexName;
      } else {
        return answer.indexName;
      }
    }

    definition.addColumn(new SmartTableColumn('indexNumber', this.label(translations, 'index_number'), SmartTableColumnType.TEXT));
    definition.addColumn(new SmartTableColumn('indexName', this.label(translations, 'index_name'), SmartTableColumnType.TEXT,
      {overrideDisplayedValueFunction: translateIndexName}));
    definition.addColumn(new SmartTableColumn('userEmail', this.label(translations, 'user_email'), SmartTableColumnType.TEXT));
    definition.addColumn(new SmartTableColumn('status', this.label(translations, 'status'), SmartTableColumnType.ENUM, {
      enumColumnValues: [
        {value: 'not-assigned', label: this.label(translations, 'status.not-assigned')},
        {value: 'not-send', label: this.label(translations, 'status.not-send')},
        {value: 'not-filled', label: this.label(translations, 'status.not-filled')},
        {value: 'ready', label: this.label(translations, 'status.ready')}
      ],
      enumColumnIconFunction: (row, value) => {
        if (value === 'not-assigned') {
          return 'clear';
        }
        if (value === 'not-send') {
          return 'block';
        }
        if (value === 'not-filled') {
          return 'remove';
        }
        if (value === 'ready') {
          return 'check';
        }
        return undefined;
      },
      cellCustomCssClassFunction: (row, value) => {
        if (value === 'not-assigned') {
          return 'table-cell-status-not-assigned';
        }
        if (value === 'not-send') {
          return 'table-cell-status-not-send';
        }
        if (value === 'not-filled') {
          return 'table-cell-status-not-filled';
        }
        if (value === 'ready') {
          return 'table-cell-status-ready';
        }
        return undefined;
      }
    }));
    definition.addColumn(new SmartTableColumn('answerTime', this.label(translations, 'answer-time'), SmartTableColumnType.DATE_TIME));
    return definition;
  }

  private label(translations: { [p: string]: string }, code: string): string {
    return translations['ui.user.dashboard.default-report.status-section.' + code];
  }
}

class SmartTableProvider implements SmartTableDataProvider {

  constructor(private readonly data: OrganizationReportAnswerStatusVO[]) {}

  exportData(filter: any, sortBy: string, sortDescending: boolean, dataSource: SmartTableDataSourceDef): Observable<Blob> {
    throw new Error('Operation not supported');
  }

  fetchData(page: number, pageSize: number, filter: any, sortBy: string, sortDescending: boolean): Observable<SmartTablePageData> {
    let filteredData = this.data.filter(item => this.applyFilter(item, filter));
    filteredData = this.applySorting(filteredData, sortBy, sortDescending);
    const startIndex = (page) * pageSize;
    let endIndex = startIndex + pageSize;
    if (startIndex >= filteredData.length) {
      endIndex = filteredData.length;
    }

    const slicedData = filteredData.slice(startIndex, endIndex);
    return of({total: filteredData.length, items: slicedData} as SmartTablePageData);
  }

  private applyFilter(item: any, filter: any): boolean {
    return true;
  }

  private applySorting(data: any[], sortBy: string, sortDescending: boolean): any[] {
    if (!sortBy) {
      return data;
    }

    return data.sort((a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      if (valueA < valueB) {
        return sortDescending ? 1 : -1;
      }
      if (valueA > valueB) {
        return sortDescending ? -1 : 1;
      }
      return 0;
    });
  }
}
