<div class="multiselect-with-chips">
    <mat-form-field appearance="standard">
        <input *ngIf="edit"
               [placeholder]="placeholder"
               #input
               [formControl]="ctrl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="addSector($event)">
        <mat-chip-list [disabled]="!edit" #chipList>
            <mat-chip *ngFor="let chosen of selected"
                      [selectable]="true"
                      [removable]="true"
                      (removed)="remove(chosen)">
                {{chosen.name}}
                <mat-icon matChipRemove *ngIf="edit">cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="wasSelected($event)">
            <mat-option *ngFor="let suggestion of suggested | async" [value]="suggestion">
                {{suggestion}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
