<div [class]="getClassCssExpresion()"
     fxLayout="row" fxLayoutAlign="start center" [fxLayoutGap]="buttonGap">
  <div *ngFor="let btn of buttons;let i = index"
       matRipple
       [class]="getItemClassCssExpresion(i)"
       (click)="onButtonClicked(i)" fxLayout="row" fxLayoutAlign="start center">
      <div class="toggle-buttons-dot-space" *ngIf="btn.dotColor != null">
        <div class="toggle-buttons-dot" [style.background-color]="btn.dotColor"></div>
      </div>
      {{btn.label}}
  </div>
</div>
