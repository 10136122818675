<div class="question-form-container" [class.last-in-group]="lastInGroup">
  <app-question-header
    [token]="token"
    [content]="content"
    [questionFormData]="data"
    [language]="language"
    [isSub]="isSub"
    [isSubIndex]="subIndex"
    [firstInGroup]="firstInGroup"
    [emitChange]="emitChange">
  </app-question-header>

  <app-file-upload
    [token]="token"
    [questionFormData]="data"
    [content]="content"
    [isReviewer]="isReviewer"
    [isSub]="isSub"
  ></app-file-upload>

  <div *ngIf="!isCSRDForm(); else csrdAnswerForm">
    <app-default-answer-form
      [questionFormData]="data"
      [content]="content"
      [proposalsAction]="proposalsAction"
      [proposal]="proposal"
      [disabled]="disabled"
      [answer]="answer"
      [comment]="comment"
      [subIndex]="subIndex"
      (valueChanged)="emitChange()">
    </app-default-answer-form>
  </div>
  <ng-template #csrdAnswerForm>
    <app-csrd-answer-form
      [topicIds]="topicIds"
      [questionFormData]="data"
      [content]="content"
      [proposalsAction]="proposalsAction"
      [proposal]="proposal"
      [disabled]="disabled"
      [answer]="answer"
      [comment]="comment"
      [subIndex]="subIndex"
      (valueChanged)="emitChange()">
    </app-csrd-answer-form>
  </ng-template>
</div>
