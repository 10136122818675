<span *ngIf="!editState">{{formattedValue}}</span>

<mat-form-field *ngIf="editState" appearance="standard" class="form-field">
  <mat-label>{{label}}</mat-label>

  <input (ngModelChange)="onChange($event)" (blur)="_onBlur()" (keyup.enter)="_onKeyEnter()" matInput
         [id]="name" [name]="name" [formControl]="controller" [placeholder]="placeholder">

  <mat-error [id]="null" *ngIf="hasRequiredError()">
    Pole <strong>wymagane</strong>
  </mat-error>
</mat-form-field>
