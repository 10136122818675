import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SmartFormField} from '../smart-form.api';

@Component({
  selector: 'app-smart-form-email',
  styleUrls: ['./smart-field.scss'],
  template: `
    <mat-form-field appearance="standard" class="form-field">
      <mat-label>{{field.label}} <span *ngIf="field.required"> *</span></mat-label>
      <input matInput [id]="field.name" [name]="field.name" [formControl]="controller"
             [placeholder]="field.placeholder">
      <mat-error [id]="null" *ngIf="this.formGroup.controls[this.field.name].hasError('required')">
        Email jest <strong>wymagany</strong>
      </mat-error>
      <mat-error [id]="null" *ngIf="this.formGroup.controls[this.field.name].hasError('email')">
        Email jest <strong>nieprawidłowy</strong>
      </mat-error>
    </mat-form-field>
  `
})
export class SmartFormEmailComponent {
  @Input() field: SmartFormField;
  @Input() formGroup: FormGroup;

  get controller(): FormControl {
    return this.formGroup.controls[this.field.name] as FormControl;
  }

  constructor() {
  }

}
