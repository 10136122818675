<div class="outer" [ngStyle]="{
'background-color': color,
'background-image':
    'linear-gradient(' + calculateAngle() + 'deg, transparent 50%, ' +  calculateColor() + ' 50%),' +
     'linear-gradient(90deg, white 50%, transparent 50%)'}">
  <div class="inner">
    <span class="value" [style.color]="color">
    {{ value + '%'}}
    </span>
  </div>
</div>
