import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DocumentEndpointService, PublicDocumentEndpointService} from '../../openapi';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private documentEndpoint: DocumentEndpointService,
    private publicDocumentEndpoint: PublicDocumentEndpointService
  ) {
  }

  getSubOrganizationDocumentFile(organizationId: string, documentId: string): Observable<File> {
    return this.publicDocumentEndpoint.fetchSubOrganizationDocument(organizationId, documentId, 'response')
      .pipe(map(this.blobIntoFile()));
  }

  getDocumentFile(documentId: string): Observable<File> {
    return this.documentEndpoint.fetchDocument(documentId, 'response')
      .pipe(map(this.blobIntoFile()));
  }

  getDocumentFileUsingToken(instructionId: string, token: string): Observable<File> {
    return this.publicDocumentEndpoint.fetchDocumentUsingAccessToken(instructionId, token, 'response')
      .pipe(map(this.blobIntoFile()));
  }

  getZippedDocuments(reportId: number): Observable<File> {
    return this.publicDocumentEndpoint.fetchDocumentsForReport(reportId, 'response')
      .pipe(map(this.blobIntoFile()));
  }

  downloadDocumentFileUsingToken(instructionId: string, token: string): void {
    this.getDocumentFileUsingToken(instructionId, token).subscribe(file => {
      const url = URL.createObjectURL(file);
      const element = document.createElement('a');
      element.href = url;
      element.download = file.name;
      element.click();
    });
  }

  private blobIntoFile() {
    return response => {
      const content = response.headers.get('Content-Disposition');
      const filename = content.split(';')[1].split('=')[1].replace(/"/g, '');
      const type = response.headers.get('Content-Type').split(';')[0];
      return new File([response.body], filename, { type });
    };
  }
}
