import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PublicDepartmentEndpointService, DepartmentVO, FetchResultDepartmentVO} from '../../../../openapi';
import {map} from 'rxjs/operators';
import {Department} from '../models/department';

@Injectable({ providedIn: 'root' })
export class DepartmentService {

  constructor(private readonly departmentEndpointService: PublicDepartmentEndpointService) {
  }

  fetchDepartments(): Observable<Department[]> {
    return this.departmentEndpointService.fetchAll({ page: 0, pageSize: 0 }).pipe(
      map((fetchResult: FetchResultDepartmentVO) => this.transformVoToDepartment(fetchResult.rows)));
  }

  fetchSortedDepartments(): Observable<Department[]> {
    return this.departmentEndpointService.fetchAllSortedDepartments().pipe(
      map((departmentsVO: DepartmentVO[]) => this.transformVoToDepartment(departmentsVO))
    );
  }

  private transformVoToDepartment(departmentsVO: DepartmentVO[]): Department[] {
    return departmentsVO.map((departmentVO) => {
      return {
        id: departmentVO.id,
        name: departmentVO.name,
        uniqueNumber: departmentVO.uniqueNumber,
        description: departmentVO.description,
        pkdCodes: departmentVO.pkdCodes.map((code: string) => code.split(','))
          .reduce((a, b) => a.concat(b), [])
          .map((code) => code.trim()),
        deleted: departmentVO.deleted,
        hidden: departmentVO.hidden,
        translation: departmentVO.translation
      } as Department;
    });
  }
}
