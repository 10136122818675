<div class="container">
  <div class="container-question">
    <mat-icon class="icon">info</mat-icon>
    <span>{{ 'ui.anonymous.answers.proposal.global.question' | translate }}</span>
  </div>

  <ng-container *ngIf="!isGlobal else global">
    <div class="container-button">
      <button mat-icon-button (click)="click({use: true})">
        <mat-icon color="primary">check</mat-icon>
      </button>
      <button mat-icon-button (click)="click({skip: true})">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-template #global>
    <div class="container-button">
      <button mat-button (click)="click({use: true})">
        <mat-icon color="primary">check</mat-icon>
        <span>{{ 'ui.anonymous.answers.proposal.global.use' | translate }}</span>
      </button>
      <button mat-button (click)="click({skip: true})">
        <mat-icon color="warn">close</mat-icon>
        <span>{{ 'ui.anonymous.answers.proposal.global.skip' | translate }}</span>
      </button>
      <button mat-button (click)="click({search: true})">
        <mat-icon>search</mat-icon>
        <span>{{ 'ui.anonymous.answers.proposal.global.search' | translate }}</span>
      </button>
    </div>
  </ng-template>
</div>


