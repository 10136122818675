import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-answers-form-page-layout',
  templateUrl: './answers-form-page-layout.component.html',
  styleUrls: ['./answers-form-page-layout.component.scss']
})
export class AnswersFormPageLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
