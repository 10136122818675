import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import unlogedRoutes from './routes/unloged.routes';


@NgModule({
  imports: [RouterModule.forRoot(unlogedRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
