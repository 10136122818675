import {EventEmitter, Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {concat, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslateFacadeService {
  private _currentTranslations: { [key: string]: string };

  constructor(private readonly translateService: TranslateService) {
  }

  translationsOnInit(): Observable<{ [key: string]: string }> {
    const currentLang = this.translateService.currentLang;

    if (currentLang) {
      return concat(
        this.translateService.getTranslation(currentLang).pipe(take(1)),
        this.translateService.onLangChange.pipe(map($event => {
          this._currentTranslations = $event.translations;
          return $event.translations;
        }))
      );
    }
    return this.translateService.onLangChange.pipe(map($event => {
      this._currentTranslations = $event.translations;
      return $event.translations;
    }));
  }

  use(lang: string): Observable<any> {
    return this.translateService.use(lang);
  }

  get onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  translation(key: string, defaultValue: string): string {
    return this._currentTranslations != null ? (this._currentTranslations[key] != null ? this._currentTranslations[key] : defaultValue) : defaultValue;
  }
}
