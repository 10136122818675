export interface ReviewerNotificationContent {
  readonly email: string;
  readonly content: string;
  readonly dataValidationRequired: boolean;
}

export const EMPTY_REVIEWER_NOTIFICATION_CONTENT = {
  email: '',
  content: '',
  dataValidationRequired: false
} as ReviewerNotificationContent;
