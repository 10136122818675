<div class="panel {{borderType}} {{highlight !== undefined ? 'app-panel-highlight-' + highlight: ''}} {{getCustomCssClass('panel')}}"
[ngClass]="{'fill-parent':styleFillParent}">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div *ngIf="title != null" class="panel-title {{getCustomCssClass('panel-title')}}">{{title}}</div>
    <div *ngIf="icon" class="panel-button {{getCustomCssClass('panel-button')}}" [class.clickable]="clickable" (click)="click()"><mat-icon>{{ icon }}</mat-icon></div>
    <div *ngIf="title == null && selectValues != null" fxFlex></div>
    <mat-form-field appearance="standard" *ngIf="selectValues != null">
      <mat-label *ngIf="selectLabel != null">{{selectLabel}}</mat-label>
      <mat-select [(value)]="selectedValue" (selectionChange)="selectedValueChanged.emit($event.value)">
        <mat-option *ngFor="let item of selectValues" [value]="item.value">
          {{item.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <ng-content></ng-content>
</div>
