import {AuthenticationDTO} from '../services/authentication.service';

export class AuthLogInAction {
  static readonly type = '[Auth] Log-in';

  constructor(public username: string, public password: string) {
  }
}

export class AuthLogInWithTokenAction {
  static readonly type = '[Auth] Log-in-WithToken';

  constructor(public token: string) {
  }
}

export class AuthSendEmailAction {
  static readonly type = '[Auth] Send-email';

  constructor(public email: string) {
  }
}

export class AuthLogoutAction {
  static readonly type = '[Auth] Logout';

  constructor() {
  }
}

export class AuthUpdateUserDetailsAction {
  static readonly type = '[Auth] UpdateUserDetails';

  constructor(public newPrincipal: AuthenticationDTO) {
  }
}
