import {Component, Input} from '@angular/core';
import {
  ReviewerService
} from '../../../../user/dashboard/components/reviewer-notification-popup/reviewer-notification-modal/service/reviewer.service';
import {OrganizationReportAssignedReviewerVO, SendDataValidationFeedbackEmailCommand} from '../../../../../openapi';
import {InfoDialogI18nService} from '../../../../../shared/services/info-dialog-i18n.service';

@Component({
  selector: 'app-reviewer-data-validation-field',
  templateUrl: './reviewer-data-validation-field.component.html',
  styleUrls: ['./reviewer-data-validation-field.component.scss']
})
export class ReviewerDataValidationFieldComponent {

  isOptionClicked = false;
  isAnswerSend = false;

  @Input() private token: string;
  @Input() private languageTag: string;
  @Input() private reviewer: OrganizationReportAssignedReviewerVO;

  private selectedOption: boolean;

  constructor(
    private readonly reviewerService: ReviewerService,
    private readonly dialogService: InfoDialogI18nService
  ) {
  }

  click(confirmed: boolean): void {
    this.isOptionClicked = true;
    this.selectedOption = confirmed;
  }

  send() {
    this.isAnswerSend = true;

    const command = {
      token: this.token,
      confirmed: this.selectedOption,
      preferredLanguageTag: this.languageTag
    } as SendDataValidationFeedbackEmailCommand;

    this.reviewerService.sendDataValidationFeedback(command).subscribe(() => {
      this.dialogService.success('ui.reviewer.data_validation_label.success');
    }, () => {
      this.dialogService.error('ui.reviewer.data_validation_label.error');
    });
  }

  returnToSelectOption() {
    this.isAnswerSend = false;
    this.isOptionClicked = false;
  }

  hasDataValidationAssigment = () => this.reviewer?.dataValidationRequired && !this.isAlreadySendValidationReplay();

  isAlreadySendValidationReplay = () => this.reviewer?.alreadySendValidationReplay;
}
