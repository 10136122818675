import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import {AuthLogInWithTokenAction, AuthSendEmailAction} from '../../../../../state/auth.actions';
import {Observable} from 'rxjs';
import {AuthState} from '../../../../../state/auth.state';
import {HttpResponse} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {AccountPublicApiEndpointService} from '../../../../../openapi';
import {ActivatedRoute} from '@angular/router';
import {CustomValidators} from '../../../../../shared/services/custom-validators.service';

@Component({
  selector: 'app-database-log-in',
  templateUrl: './database-log-in.component.html',
  styleUrls: ['./database-log-in.component.scss']
})
export class DatabaseLogInComponent implements OnInit {

  loginForm: FormGroup;
  tokenForm: FormGroup;
  restoreForm: FormGroup;
  passwordForm: FormGroup;
  resetEmail: string;
  formState: 'login' | 'login-token' | 'restore' | 'restore-token' | 'restore-token-send' = 'login';
  errorMessage: string;

  @Select(AuthState.wasAuthError)
  wasAuthError$: Observable<boolean>;

  @Select(AuthState.wasEmailSendSuccess) wasEmailSendSuccess$: Observable<boolean>;

  constructor(private readonly store: Store,
              private readonly activatedRoute: ActivatedRoute,
              private readonly customValidators: CustomValidators,
              private readonly accountPublicApiEndpointService: AccountPublicApiEndpointService) {
  }

  ngOnInit(): void {
    const authToken = this.activatedRoute.snapshot.queryParams.token;

    if (authToken) {
      this.formState = 'login-token';
      this.login(authToken);
    }

    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required, Validators.email]),
    });

    this.restoreForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.passwordForm = new FormGroup(
      {
        token: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        password2: new FormControl('', [Validators.required])
      }
    );
  }

  onSubmitLogin() {
    if (this.loginForm.valid) {
      this.store.dispatch(new AuthSendEmailAction(this.loginForm.controls.userName.value));
    }
  }

  onSubmitRestore() {
    if (this.restoreForm.valid) {
      this.accountPublicApiEndpointService.sendPasswordResetToken({email: this.restoreForm.controls.email.value})
        .pipe(take(1)).subscribe(_ => {
        this.resetEmail = this.restoreForm.controls.email.value;
        this.formState = 'restore-token';
      });
    }
  }

  onSubmitPassword() {
    this.errorMessage = null;
    if (this.passwordForm.valid) {
      const password: string = '' + this.passwordForm.controls.password.value;
      const password2: string = '' + this.passwordForm.controls.password2.value;

      if (password !== password2) {
        this.errorMessage = 'Hasła nie są takie same';
      } else if (password.length < 8) {
        this.errorMessage = 'Hasła musi mieć co namniej 8 znaków';
      } else if (!this.hasDigits(password)) {
        this.errorMessage = 'Hasło musi zawierać przynajmniej jedną cyfrę';
      } else if (!this.hasLowerCaseLetters(password)) {
        this.errorMessage = 'Hasło musi zawierać przynajmniej jedną małą literę';
      } else if (!this.hasUpperCaseLetters(password)) {
        this.errorMessage = 'Hasło musi zawierać przynajmniej jedną wielką literę';
      } else if (!this.hasSpecialChars(password)) {
        this.errorMessage = 'Hasło musi zawierać przynajmniej jednen znak specjalny: ~!@#$%^&*()_-';
      } else {
        this.accountPublicApiEndpointService.resetPassword({
          email: this.resetEmail,
          password,
          token: '' + this.passwordForm.controls.token.value
        }, 'response')
          .pipe(take(1)).subscribe((res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.formState = 'restore-token-send';
          } else {
            this.errorMessage = 'Nie udało się ustawic hasła, zły token?';
          }
        });
      }
    }
  }

  private login(token: string): void {
    this.store.dispatch(new AuthLogInWithTokenAction(token));
  }

  private hasDigits(password: string): boolean {
    return /(?=.*[0-9]).*/.test(password);
  }

  private hasLowerCaseLetters(password: string): boolean {
    return /(?=.*[a-z]).*/.test(password);
  }

  private hasUpperCaseLetters(password: string): boolean {
    return /(?=.*[A-Z]).*/.test(password);
  }

  private hasSpecialChars(password: string): boolean {
    return /.*[~!@#$%^&*()_-].*/.test(password);
  }

  showPasswordRestoreForm() {
    this.restoreForm.controls.email.setValue('');
    this.errorMessage = null;
    this.formState = 'restore';
  }

  showLoginForm() {
    this.loginForm.controls.userName.setValue('');
    this.loginForm.controls.password.setValue('');
    this.errorMessage = null;
    this.formState = 'login';
  }
}
