import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ConfirmDialogI18nComponent} from '../toolkit/confirm-dialog-i18n/confirm-dialog-i18n.component';

@Injectable({ providedIn: 'root' })
export class InfoDialogI18nService {

  private readonly defaultSuccessTitle = 'ui.info-dialog-service.default.success.title';
  private readonly defaultSuccessMessage = 'ui.info-dialog-service.default.success.message';

  private readonly defaultErrorTitle = 'ui.info-dialog-service.default.error.title';
  private readonly defaultErrorMessage = 'ui.info-dialog-service.default.error.title';

  constructor(private readonly dialog: MatDialog) {
  }

  success(messageCode?: string, titleCode?: string): void {
    this.dialog.open(ConfirmDialogI18nComponent, {
      data: {
        title: (!titleCode ? this.defaultSuccessTitle : titleCode),
        message: (!messageCode ? this.defaultSuccessMessage : messageCode),
        onlyOkOption: true,
        icon: 'check_circle_outline',
        iconColor: 'success'
      },
      width: '300px'
    });
  }

  successWithCallback(messageCode?: string, titleCode?: string): Observable<any> {
    return this.dialog.open(ConfirmDialogI18nComponent, {
      data: {
        title: (!titleCode ? this.defaultSuccessTitle : titleCode),
        message: (!messageCode ? this.defaultSuccessMessage : messageCode),
        onlyOkOption: true,
        icon: 'check_circle_outline',
        iconColor: 'success'
      },
      width: '300px'
    }).afterClosed();
  }

  error(messageCode?: string, titleCode?: string): void {
    this.dialog.open(ConfirmDialogI18nComponent, {
      data: {
        title: (!titleCode ? this.defaultErrorTitle : titleCode),
        message: (!messageCode ? this.defaultErrorMessage : messageCode),
        onlyOkOption: true,
        icon: 'error_outline',
        iconColor: 'error'
      },
      width: '300px'
    });
  }
}
