<ol class="breadcrumb">
  <div class="breadcrumb-container">
    <ng-container class="breadcrumb" *ngFor="let breadcrumb of breadcrumbs; last as isLast;">
      <li *ngIf="!breadcrumb.skip"
          class="breadcrumb-item"
          [ngClass]="{'active': isLast}" aria-current="page">
        <a *ngIf="!isLast; else lastRoute"
           [routerLink]="[breadcrumb.url]"
           mat-button color="primary"
           routerLinkActive="active">
          {{ breadcrumb.label | translate }}
        </a>
        <ng-template #lastRoute><span class="breadcrumb-last-route">{{ breadcrumb.label | translate }}</span>
        </ng-template>
      </li>
    </ng-container>
  </div>

  <div class="breadcrumb-contact">
    <p>{{ 'ui.user.dashboard.contact.header' | translate }} </p>
    <p class="breadcrumb-contact-email">sreport@sapere.site</p>
  </div>
</ol>
