<div class="left-menu" fxLayout="column" fxLayoutAlign="start center">
  <div class="left-menu-logo">
    <img src="assets/logo.svg">
  </div>


  <div class="left-menu-links" fxLayout="column" fxLayoutAlign="start start">
    <ng-container *ngIf="roles.includes('OrganizationRole.AdminUser')">
      <a mat-button routerLink="/reports-dashboard" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">home</i>
        {{ 'ui.common.left-menu.user.dashboard' | translate }}
      </a>
      <a mat-button routerLink="/reports" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">description</i>
        {{ 'ui.common.left-menu.user.reports' | translate }}
      </a>
      <a mat-button routerLink="/reports-settings" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">settings</i>
        {{ 'ui.common.left-menu.user.settings' | translate }}
      </a>
      <a mat-button routerLink="/account-settings" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">person</i>
        {{ 'ui.common.left-menu.user.account' | translate }}
      </a>
      <a *ngIf="supportsCapitalGroups()" mat-button routerLink="/capital-group-companies"
         routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">business</i>
        {{ 'ui.common.left-menu.user.capital-group' | translate }}
      </a>
      <a mat-button routerLink="/buy-package" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">shopping_cart</i>
        {{ 'ui.common.left-menu.user.buy-package' | translate }}
      </a>
      <a mat-button routerLink="/help" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">help</i>
        {{ 'ui.common.left-menu.user.help' | translate }}
      </a>
    </ng-container>
    <ng-container *ngIf="roles.includes('Admin')">
      <a mat-button routerLink="/dashboard" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">home</i>
        {{ 'ui.common.left-menu.admin.home' | translate }}
      </a>
      <a mat-button routerLink="/companies" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">location_on</i>
        {{ 'ui.common.left-menu.admin.companies' | translate }}
      </a>
      <a mat-button routerLink="/database-companies" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">location_on</i>
        {{ 'ui.common.left-menu.admin.database-companies' | translate }}
      </a>
      <a mat-button routerLink="/report-metadata-settings" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">settings</i>
        {{ 'ui.common.left-menu.admin.settings' | translate }}
      </a>
    </ng-container>
    <ng-container *ngIf="roles.includes('OrganizationRole.DatabaseUser')">
      <a mat-button routerLink="/search" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">search</i>
        {{ 'ui.common.left-menu.database.search' | translate }}
      </a>
      <a mat-button routerLink="/links/generated" routerLinkActive="active-link">
        <i class="left-menu-links-icon material-icons-outlined">location_on</i>
        {{ 'ui.common.left-menu.database.my-companies' | translate }}
      </a>
    </ng-container>
  </div>

  <div fxFlex="auto"></div>
  <div class="left-menu-bottom-links" fxLayout="column" fxLayoutAlign="center center">
    <div class="language-select" *ngIf="roles.includes('OrganizationRole.AdminUser')">
      <button mat-button color="primary" (click)="changeLanguage('pl')">Polski</button>
      <button mat-button color="primary" (click)="changeLanguage('en')">English</button>
    </div>
    <a *ngIf="!roles.includes('OrganizationRole.DatabaseUser')" mat-button routerLink="/user-account" routerLinkActive="active-link">
      <mat-icon class="left-menu-links-icon">account_circle</mat-icon>
      {{ 'ui.common.left-menu.account' | translate }}
    </a>
    <a mat-button (click)="logout()">
      <mat-icon class="left-menu-links-icon">exit_to_app</mat-icon>
      {{ 'ui.common.left-menu.logout' | translate }}
    </a>
  </div>
</div>

