import {Injectable} from '@angular/core';
import {ProposalAction, ProposalAnswer, ProposalsPerQuestion} from './model/answer-proposal.model';
import {Subject} from 'rxjs';
import {IndexFormData} from './model/index-form-data';
import {AnswerPerType} from './model/answer-per-type';
import {QuestionFormData} from './model/question-form-data';
import {AnswerContentJson} from './model/answer-content-json';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  private _globalAction = new Subject<ProposalAction>();
  private _isAnyProposal: boolean;
  private proposals: ProposalsPerQuestion[];

  set isAnyProposal(value: boolean) {
    this._isAnyProposal = value;
  }

  get isAnyProposal() {
    return this._isAnyProposal;
  }

  set globalAction(action: ProposalAction) {
    this._globalAction.next(action);
  }

  fetchGlobalAction() {
    return this._globalAction.asObservable();
  }

  useAllProposals(indices: IndexFormData[]) {
    indices.forEach((form: IndexFormData) => {
      form.questions.forEach((question: QuestionFormData) => {
        if (question.answerContent) {
          const content = question?.answerContent;
          this.deleteProposalSignature(content?.answer);
          content?.subAnswers.forEach((subAnswer: AnswerPerType) => {
            this.deleteProposalSignature(subAnswer);
          });
          if (content.isCommentProposal) {
            delete content.isCommentProposal;
          }
        }
      });
    });
  }

  setProposals(list: IndexFormData[]) {
    const proposals: ProposalsPerQuestion[] = [];

    const indices: IndexFormData[] = list.filter(form => form.isAnyProposal);
    indices.forEach(index => {
      const proposalsPerQuestion: ProposalsPerQuestion[] = index.questions.map(question => {
        const answerContent = question.answerContent;
        return {
          id: question.questionId,
          ...(answerContent.isCommentProposal && {comment: true}),
          ...(!this.isEmpty(answerContent?.answer) && {answer: this.initProposalAnswer((answerContent?.answer))}),
          ...(!this.isEmpty(answerContent?.subAnswers) && {subAnswer: this.initProposalSubAnswers(answerContent?.subAnswers)}),
        } as ProposalsPerQuestion;
      });

      proposals.push(...proposalsPerQuestion);
    });
    this.proposals = proposals;
  }

  fetchProposal(questionId: number, subIndex?: number) {
    if (!this._isAnyProposal) {
      return;
    }
    const value = this.proposals.find((proposal) => questionId === proposal.id);
    return typeof (subIndex) === 'number'
      ? value?.subAnswer[subIndex]
      : {...value?.answer, ...(value?.hasOwnProperty('comment') && {comment: value?.comment})};
  }

  updateProposal(proposalId: number, value: ProposalAnswer & { comment?: boolean }, subIndex?: number) {
    const index = this.proposals.findIndex(Proposal => proposalId === Proposal.id);

    if (typeof (subIndex) === 'number') {
      this.proposals[index].subAnswer[subIndex] = value;
      return;
    }

    const {comment, ...answer} = value;
    this.proposals[index].answer = answer;
    this.proposals[index].comment = comment;
  }

  clearProposals(list: IndexFormData[]) {
    list.forEach((form: IndexFormData) => {
      form.questions.forEach((question: QuestionFormData) => {
        if (question.answerContent) {
          this.clearProposalComment(question?.answerContent);
          this.clearProposalAnswer(question?.answerContent?.answer);
          this.clearProposalSubAnswers(question?.answerContent?.subAnswers);
        }
      });
    });
  }

  private isEmpty(value: object) {
    if (typeof (value) === 'undefined' || value === null) {
      return true;
    }

    return Object.keys(value).length === 0;
  }

  private initProposalAnswer(answer: AnswerPerType) {
    return Object.keys(answer).reduce((acc, key) => {
      return {
        ...acc,
        ...(answer[key]?.isProposal && {[key]: true})
      };
    }, {});
  }

  private initProposalSubAnswers(answers: AnswerPerType[]) {
    return answers.map((answer: AnswerPerType) => {
      return this.initProposalAnswer(answer);
    });
  }

  private clearProposalComment(answerContent: AnswerContentJson) {
    if (answerContent?.isCommentProposal) {
      answerContent.comment = '';
      delete answerContent.isCommentProposal;
    }
  }

  private clearProposalAnswer(answer: AnswerPerType) {
    Object.entries(answer).forEach(([key, value]) => {
      if (value?.isProposal) {
        delete answer[key];
      }
    });
  }

  private clearProposalSubAnswers(answers: AnswerPerType[]) {
    if (answers) {
      answers.forEach((answer) => {
        this.clearProposalAnswer(answer);
      });
    }
  }

  private deleteProposalSignature(answer: AnswerPerType) {
    Object.entries(answer).forEach(([key, value]) => {
      if (value?.isProposal) {
        delete answer[key].isProposal;
      }
    });
  }
}
