import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export enum SmartFormFieldType {
  Text = 'text',
  Email = 'email',
  Dropdown = 'dropdown',
  Radio = 'radio',
  CheckBox = 'checkbox',
  File = 'file',
  Hidden = 'hidden',
  Number = 'number',
}

export interface SmartFormFieldOptions {
  key: string;
  label: string;
  preselect?: boolean;
}

export interface SmartFormField {
  type: SmartFormFieldType;
  name: string;
  label: string;
  value: any;
  required: boolean;
  placeholder?: string;
  multiline?: boolean;
  digits?: number;
  min?: number;
  max?: number;
  options?: SmartFormFieldOptions[];
}

export class SmartFormDataSourceDef {
  readonly dataProvider: SmartFormDataProvider;
  readonly fields: SmartFormField[];
  readonly isValidStream: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isValid$: Observable<boolean> = this.isValidStream.asObservable();
  readonly isNotValid$: Observable<boolean> = this.isValidStream.asObservable().pipe(map(valid => !valid));

  constructor(dataProvider: SmartFormDataProvider, columns: SmartFormField[] = []) {
    this.dataProvider = dataProvider;
    this.fields = columns;
  }

  public addField(field: SmartFormField) {
    this.fields.push(field);
  }

  public getValues(): any {
    const values: any = {};
    this.fields.forEach(field => {
      values[field.name] = field.value;
    });
    return values;
  }

  public postData(): Observable<any> {
    const formValues = this.getValues();
    return this.dataProvider.postData(formValues);
  }
}

export interface SmartFormDataProvider {
  fetchData(id: number): Observable<any>;

  postData(formData: any): Observable<any>;
}
