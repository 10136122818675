import {Pipe, PipeTransform} from '@angular/core';
import {TranslateFacadeService} from '../services/translate-facade.service';

type Translatable<T> = {
  translation: Translations<T>;
};

type Translations<T> = {
  [locale: string]: {
    [property in keyof T]?: string;
  };
};

@Pipe({name: 'TranslatedProperty'})
export class TranslatedPropertyPipe implements PipeTransform {

  constructor(
    private readonly translate: TranslateFacadeService,
  ) {
  }

  /**
   * The `transform` method performs the translation of an object's property based on
   * the currently set language.
   *
   * @param object    The Translatable object whose property is to be translated.
   * @param property  The name of the property to translate (default is 'name').
   * @returns         The translated value of the object's property or the original value
   *                  if translation is not available.
   */
  transform<T extends Translatable<T>>(object: T, property: string = 'name'): string {
    const language = this.translate.currentLang;
    if (object.translation && object.translation[language]) {
      const translation: Translations<T>[string] = object.translation[language];
      return translation[property];
    }

    return object[property];
  }
}
