<h1 mat-dialog-title>
  <header>
    <mat-icon *ngIf="icon"
              [class.green]="iconColor === 'success'"
              [class.orange]="iconColor === 'warn'"
              [class.red]="iconColor === 'error'">{{icon}}</mat-icon>
    {{title}}
  </header>
</h1>

<div mat-dialog-content>
  <p [innerHTML]="message"></p>
</div>

<footer mat-dialog-actions>
  <ng-container *ngIf="!onlyOkOption">
    <button class="button cancel" mat-button (click)="onDismiss()">{{noLabel}}</button>
    <button class="button save" mat-raised-button (click)="onConfirm()">{{confirmLabel}}</button>
  </ng-container>
  <button *ngIf="onlyOkOption"  mat-button class="button save" mat-dialog-close>OK</button>
</footer>
