<div class="login-title">
  {{ 'ui.public.login.title' | translate }}
</div>
<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3rem">
    <div class="logo">
      <img src="assets/login-logo.png">
    </div>

    <div class="login-header" *ngIf="formState === 'login'">{{ 'ui.public.login.login-form.header.login' | translate }}</div>
    <div class="login-header" *ngIf="formState === 'restore'">{{ 'ui.public.login.login-form.header.restore' | translate }}</div>
    <div class="login-header" *ngIf="formState === 'restore-token'">{{ 'ui.public.login.login-form.header.restore-token' | translate }}</div>

    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="formState === 'login'">
      <mat-card-content>
        <mat-form-field class="full-width">
          <input matInput [placeholder]="loginFormUsernamePlaceholder" formControlName="userName">
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput type="password" [placeholder]="loginFormPasswordPlaceholder" formControlName="password">
        </mat-form-field>

        <mat-error *ngIf="wasAuthError$ | async; let error">
          {{ 'ui.public.login.error' | translate }}
        </mat-error>
      </mat-card-content>
      <div class="buttons-wrapper">
        <button mat-raised-button color="primary">{{ 'ui.public.login.login-form.button.login' | translate }}</button>
        <button mat-button (click)="showPasswordRestoreForm()">{{ 'ui.public.login.login-form.button.restore' | translate }}</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="register-button-container">
        <button mat-button color="primary" [routerLink]="'/register'">{{ 'ui.public.login.login-form.button.register' | translate }}</button>
      </div>
    </form>

    <form class="restore-form" [formGroup]="restoreForm" (ngSubmit)="onSubmitRestore()" *ngIf="formState === 'restore'">
      <p>
        {{ 'ui.public.login.restore-form.header' | translate }}
      </p>
      <mat-card-content>
        <mat-form-field class="full-width">
          <input matInput placeholder="E-mail" formControlName="email">
        </mat-form-field>
      </mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <button mat-button color="ih-alt-primary" fxFlex>{{ 'ui.public.login.restore-form.button.restore' | translate }}</button>
        <button mat-button (click)="showLoginForm()">{{ 'ui.public.login.restore-form.button.login' | translate }}</button>
      </div>
    </form>

    <form class="password-form" [formGroup]="passwordForm" (ngSubmit)="onSubmitPassword()"
          *ngIf="formState === 'restore-token'">
      <p>
        {{ 'ui.public.login.password-form.header' | translate}}
      </p>
      <mat-card-content>
        <mat-form-field class="full-width">
          <input matInput placeholder="Token z e-maila" formControlName="token">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput type="password" placeholder="Hasło" formControlName="password">
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput type="password" placeholder="Hasło" formControlName="password2">
        </mat-form-field>
      </mat-card-content>
      <mat-error *ngIf="errorMessage != null">
        {{errorMessage}}
      </mat-error>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <button mat-button color="ih-alt-primary" fxFlex>{{ 'ui.public.login.password-form.button.change' | translate }}</button>
        <button mat-button (click)="showLoginForm()">{{ 'ui.public.login.password-form.button.login' | translate }}</button>
      </div>
    </form>

    <form *ngIf="formState === 'restore-token-send'">
      <p>
        {{ 'ui.public.login.restore-token-send.header' | translate }}
      </p>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <button mat-button color="ih-alt-primary" fxFlex (click)="showLoginForm()">{{ 'ui.public.login.restore-token-send.button.login' | translate }}</button>
      </div>
    </form>
    <div class="language-select">
      <button mat-button color="primary" (click)="changeLanguage('pl')">Polski</button>
      <button mat-button color="primary" (click)="changeLanguage('en')">English</button>
    </div>
  </mat-card>
</div>
