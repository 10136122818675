import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FetchResultVariantVO, VariantEndpointService, VariantVO} from '../../../../openapi';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VariantService {

  private readonly VARIANTS: BehaviorSubject<VariantVO[]> = new BehaviorSubject<VariantVO[]>([]);
  readonly VARIANTS$ = this.VARIANTS.asObservable();

  constructor(private variantEndpointService: VariantEndpointService) {
    this.fetchVariants().subscribe((variants) => {
      this.variants = variants;
    });
  }

  get variants(): VariantVO[] {
    return this.VARIANTS.getValue();
  }

  set variants(val: VariantVO[]) {
    this.VARIANTS.next(val);
  }

  fetchVariants(): Observable<VariantVO[]> {
    return this.variantEndpointService.fetchAllVariants({page: 0, pageSize: 0}).pipe(
      map((fetchResultVariantVO: FetchResultVariantVO) => fetchResultVariantVO.rows)
    );
  }
}
