import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SmartFormField} from '../smart-form.api';

@Component({
  selector: 'app-smart-form-hidden',
  styleUrls: ['./smart-field.scss'],
  template: `
    <input type="hidden" [id]="field.name" [name]="field.name" [formControl]="controller" [value]="field.value">
  `
})
export class SmartFormHiddenComponent {
  @Input() field: SmartFormField;
  @Input() formGroup: FormGroup;

  get controller(): FormControl {
    return this.formGroup.controls[this.field.name] as FormControl;
  }

  constructor() {
  }

}
