<ng-container *ngFor="let group of indicesGroups">
  <mat-label *ngIf="group.groupName" class="group-name">{{ group.groupName }}</mat-label>
  <mat-label *ngIf="group.customMetricName" class="metric-name">{{ group.customMetricName }}</mat-label>
  <app-index-form *ngFor="let index of group.indices"
                  [reportId]="reportId"
                  [data]="index"
                  [token]="chosenSectionToken"
                  [disabled]="disabled"
                  [isReviewer]="isReviewer"
  ></app-index-form>
</ng-container>
