import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProposalAction} from '../../model/answer-proposal.model';

@Component({
  selector: 'app-answer-proposal',
  templateUrl: './answer-proposal.component.html',
  styleUrls: ['./answer-proposal.component.scss']
})
export class AnswerProposalComponent {
  @Input() isGlobal = false;
  @Output() action = new EventEmitter<ProposalAction>();

  click(value) {
    this.action.emit({
      use: false,
      skip: false,
      search: false,
      ...value
    });
  }
}
