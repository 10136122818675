import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss']
})
export class StatusPillComponent implements OnInit {

  @Input()
  icon: string;

  @Input()
  style: 'good' | 'alert' | 'info' | 'standout' = 'info';

  @Input()
  selected = false;

  @Input()
  styleBig = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
