import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  AuthLogInAction,
  AuthLogInWithTokenAction,
  AuthLogoutAction,
  AuthSendEmailAction,
  AuthUpdateUserDetailsAction
} from './auth.actions';
import {AuthenticationDTO, AuthenticationService} from '../services/authentication.service';
import {Navigate} from '@ngxs/router-plugin';
import {Router} from '@angular/router';
import unlogedRoutes from '../routes/unloged.routes';
import adminRoutes from '../routes/admin.routes';
import clientRoutes from '../routes/user.routes';
import databaseRoutes from '../routes/database.routes';
import {TranslateFacadeService} from '../shared/services/translate-facade.service';
import {environment} from '../../environments/environment';

export class AuthStateModel {
  public authenticated: boolean;
  public authError: boolean;
  public emailSentSuccess: boolean;
  public principal: AuthenticationDTO;
}

const defaults = {
  authenticated: false,
  authError: false,
  emailSentSuccess: false,
  principal: null,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults
})
@Injectable()
export class AuthState implements NgxsOnInit {

  @Selector()
  public static isAuthenticated(state: AuthStateModel) {
    return state.authenticated;
  }

  @Selector()
  public static wasAuthError(state: AuthStateModel) {
    return state.authError;
  }

  @Selector()
  public static wasEmailSendSuccess(state: AuthStateModel) {
    return state.emailSentSuccess;
  }

  @Selector()
  public static principal(state: AuthStateModel) {
    return state.principal;
  }

  constructor(private readonly authenticationService: AuthenticationService,
              private readonly store: Store,
              private readonly router: Router,
              private readonly translate: TranslateFacadeService) {
  }

  ngxsOnInit(ctx?: StateContext<AuthStateModel>): any {
    const state = ctx.getState();
    if (state.authError) {
      ctx.patchState({
        authError: false
      });
    }

    this.initRoutes(state.principal);
  }

  @Action(AuthSendEmailAction)
  async sendEmail(ctx: StateContext<AuthStateModel>, {email}: AuthSendEmailAction) {
    const emailSent = await this.authenticationService.sendEmailToGetToken(email);

    ctx.patchState({
      authError: !emailSent,
      emailSentSuccess: emailSent
    });

    if (emailSent === false) {
      setTimeout(_ => {
        ctx.patchState({
          authError: false,
          emailSentSuccess: false,
        });
      }, 3000);
    } else {
      setTimeout(_ => {
        ctx.patchState({
          emailSentSuccess: false,
        });
      }, 5000);
    }
  }

  @Action(AuthLogInWithTokenAction)
  async logInWithToken(ctx: StateContext<AuthStateModel>, {token}: AuthLogInWithTokenAction) {
    const authenticationDTO = await this.authenticationService.getAuthenticateData(token);

    ctx.setState({
      authenticated: authenticationDTO != null,
      authError: authenticationDTO == null,
      principal: authenticationDTO,
      emailSentSuccess: false
    });

    if (authenticationDTO == null) {
      setTimeout(_ => {
        ctx.patchState({
          authError: false
        });
      }, 3000);
    } else {
      this.initRoutes(authenticationDTO);
      this.store.dispatch(new Navigate(['/']));
    }
  }

  @Action(AuthLogInAction)
  async logIn(ctx: StateContext<AuthStateModel>, {username, password}: AuthLogInAction) {
    const authenticationDTO = await this.authenticationService.authenticate(username, password);

    ctx.setState({
      authenticated: authenticationDTO != null,
      authError: authenticationDTO == null,
      principal: authenticationDTO,
      emailSentSuccess: false
    });

    if (authenticationDTO == null) {
      setTimeout(_ => {
        ctx.patchState({
          authError: false
        });
      }, 3000);
    } else {
      this.initRoutes(authenticationDTO);
      this.store.dispatch(new Navigate(['/']));
    }
  }


  @Action(AuthLogoutAction)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.setState({
      authenticated: false,
      authError: false,
      principal: null,
      emailSentSuccess: false
    });
    this.initRoutes(null);
    this.store.dispatch(new Navigate(['/login']));
  }

  @Action(AuthUpdateUserDetailsAction)
  updateUserDetails(ctx: StateContext<AuthStateModel>, {newPrincipal}: AuthUpdateUserDetailsAction) {
    const state: AuthStateModel = ctx.getState();

    ctx.setState({
      authenticated: state.authenticated,
      authError: state.authError,
      principal: newPrincipal,
      emailSentSuccess: false
    });
  }

  private initRoutes(principal: AuthenticationDTO): void {
    let routes = unlogedRoutes;

    if (principal !== null) {
      if (principal.roles.split(';').includes('OrganizationRole.AdminUser')) {
        routes = clientRoutes;
      } else if (principal.roles.split(';').includes('OrganizationRole.DatabaseUser')) {
        routes = databaseRoutes;
        this.setDefaultLanguage();
      } else {
        routes = adminRoutes;
        this.setDefaultLanguage();
      }
    }

    this.router.resetConfig(routes);
  }

  private setDefaultLanguage() {
    if (this.translate.currentLang !== environment.defaultLanguage) {
      this.translate.use(environment.defaultLanguage);
    }
  }
}
