import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {EMPTY_REVIEWER_NOTIFICATION_CONTENT, ReviewerNotificationContent} from '../reviewer-notification-content';
import {
  OrganizationReportAssignedReviewerVO,
  ReviewerEndpointService, SendDataValidationFeedbackEmailCommand,
  SendReviewerNotificationCommand
} from '../../../../../../../openapi';
import {catchError, map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReviewerService {

  constructor(
    private readonly endpoint: ReviewerEndpointService
  ) {
  }

  findPreviousReportAssignedReviewer(reportId: number, sectionId: number, indexId: number): Observable<ReviewerNotificationContent> {
    return this.endpoint.findByReference(reportId, sectionId, indexId).pipe(
      map(reviewer => ({
          email: reviewer.email,
          content: reviewer.emailContent,
          dataValidationRequired: reviewer.dataValidationRequired
        } as ReviewerNotificationContent)
      ),
      catchError(() => of(EMPTY_REVIEWER_NOTIFICATION_CONTENT))
    );
  }

  findReviewerByToken(token: string): Observable<OrganizationReportAssignedReviewerVO> {
    return this.endpoint.findByToken(token);
  }

  notify = (command: SendReviewerNotificationCommand): Observable<any> => this.endpoint.sendNotificationEmail(command);

  sendDataValidationFeedback = (command: SendDataValidationFeedbackEmailCommand): Observable<any> =>
    this.endpoint.sendDataValidationFieldAnswerUsingEmail(command);
}
