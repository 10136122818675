<div class="main-layout">
  <div class="main-layout-header">
    <div class="main-layout-header-logo">
      <img src="assets/logo.svg">
    </div>
  </div>
  <div class="language-select-div">
    <app-language-select></app-language-select>
  </div>
  <div class="main-layout-content">
    <ng-content></ng-content>
  </div>
</div>
