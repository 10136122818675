import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SmartFormField} from '../smart-form.api';

@Component({
  selector: 'app-smart-form-text',
  styleUrls: ['./smart-field.scss'],
  template: `
    <mat-form-field  [appearance]="readonly ? 'none' : 'standard'" class="form-field" [class.read-only-field]="readonly">
      <mat-label>{{field.label}} <span *ngIf="field.required"> *</span></mat-label>
      <input matInput *ngIf="!field.multiline" [id]="field.name" [name]="field.name" [formControl]="controller"
             [placeholder]="field.placeholder" [readonly]="readonly" [disabled]="readonly" >

      <textarea matInput *ngIf="field.multiline" [class.is-invalid]="isDirty && !isValid" [formControl]="controller" [id]="field.name"
                [placeholder]="field.placeholder" [readonly]="readonly"></textarea>

      <mat-error [id]="null" *ngIf="this.formGroup.controls[this.field.name].hasError('required')">
        Pole <strong>wymagane</strong>
      </mat-error>
    </mat-form-field>
  `
})
export class SmartFormTextComponent {
  @Input() field: SmartFormField;
  @Input() formGroup: FormGroup;
  @Input() readonly = false;

  get isValid() {
    return this.controller.valid;
  }

  get isDirty() {
    return this.controller.dirty;
  }

  get controller(): FormControl {
    return this.formGroup.controls[this.field.name] as FormControl;
  }

  constructor() {
  }

}
