import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IndexFormData, IndexFormDataGroup} from '../../model/index-form-data';
import {Functions} from '../../../../../shared/toolkit/functions';
import {TranslateFacadeService} from '../../../../../shared/services/translate-facade.service';
import {DepartmentTranslation} from '../../../../admin/report-metadata-settings/models/department';
import {IndexService} from '../../../../admin/report-metadata-settings/services/index.service';

@Component({
  selector: 'app-grouped-index-forms',
  templateUrl: './grouped-index-forms.component.html',
  styleUrls: ['./grouped-index-forms.component.scss']
})
export class GroupedIndexFormsComponent implements OnChanges, OnInit {

  @Input() isReviewer = false;
  @Input() indices: IndexFormData[];
  @Input() chosenSectionToken: string;
  @Input() disabled: boolean;
  @Input() blankGroupI18NCode: string;
  @Input() reportId: number;

  indicesGroups: IndexFormDataGroup[];
  currentLang: string;

  private blankGroupName = '';

  constructor(
    private readonly translate: TranslateFacadeService,
    private readonly indexService: IndexService
  ) {
    this.currentLang = translate.currentLang;
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(langChangeEvent => {
      this.currentLang = langChangeEvent.lang;
      this.loadIndicesGroups();
    });
    this.translate.translationsOnInit().subscribe(translations => {
      this.blankGroupName = translations[this.blankGroupI18NCode];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.indices && this.currentLang != null) {
      this.loadIndicesGroups();
    }
  }

  private loadIndicesGroups() {
    this.indicesGroups = Object.entries(Functions.groupBy(this.indices, it => [it.reportDepartmentName, it.customMetricName]))
      .map(([index, value]) => {
        return {
          groupName: this.getGroupName(value[index]?.reportDepartmentName, value),
          customMetricName: this.getCustomMetricName(value),
          indices: value
        } as IndexFormDataGroup;
      })
      .sort((previous, next) => {
        // TODO: if there is needed custom order this is good place to implement it
        return (previous.groupName > next.groupName) ? 1 : ((next.groupName > previous.groupName) ? -1 : 0);
      })
      .map(group => {
        group.indices.sort((previous, next) => this.indexService.sortRaw(previous.indexNumber, next.indexNumber));
        return group;
      });
  }

  private getCustomMetricName = (value: IndexFormData[]) =>
    [...new Set(value?.filter(i => i.customMetricName).map(i => i.customMetricName))].join(' | ')

  private getGroupName = (name: string, value): string => name
    ? this.getTranslatedGroupName(name, value[0].reportDepartmentTranslation)
    : this.blankGroupName

  private getTranslatedGroupName(key: string, translation: { [key: string]: DepartmentTranslation }) {
    if (translation && translation[this.currentLang]?.name) {
      return translation[this.currentLang].name;
    }
    return key;
  }
}
