import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';
import {OrganizationPublicEndpointService} from '../../../../../openapi';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  errorMessage: string;
  formState: 'register' | 'success' = 'register';

  constructor(private readonly organizationPublicEndpointService: OrganizationPublicEndpointService) {
  }

  ngOnInit(): void {
    this.registerForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  onSubmit() {
    if (this.registerForm.valid) {
      this.organizationPublicEndpointService.createDemoAccount({
        adminUserEmail: this.registerForm.controls.email.value,
        adminUserName: this.registerForm.controls.userName.value
      }, 'response').pipe(take(1)).subscribe(response => {
        if (response.status === 200) {
          this.formState = 'success';
        } else {
          this.errorMessage = 'Błąd podczas zakładania konta demo, spróbuj ponownie za jakiś czas.';
        }
      });
    }
  }
}
