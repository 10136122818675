import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pie-chart-percentage',
  templateUrl: './pie-chart-percentage.component.html',
  styleUrls: ['./pie-chart-percentage.component.scss']
})
export class PieChartPercentageComponent implements OnInit {

  @Input() value: number;
  @Input() color = 'green';
  @Input() customCssClassPostfix: string;
  readonly cssClassPrefix = 'pie-chart';

  constructor() { }

  ngOnInit(): void {
  }

  calculateAngle(): number {
    if (this.value <= 50) {
      return (360  * this.value / 100) + 90;
    } else {
      return (360  * this.value / 100) - 90;
    }
  }

  calculateColor(): string {
    return this.value <= 50 ? 'white' : this.color;
  }
}
