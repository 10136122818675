<!--<mat-card class="index-form-container" *ngIf="!data.excluded" [class.lock-by-global-proposal]="isAnyProposal">-->
<mat-card class="index-form-container" [class.lock-by-global-proposal]="isAnyProposal">
    <div class="index-header" (click)="expand()">
        <div class="dot-slot"><span *ngIf="!complete" class="incomplete-exclamation-mark">!</span></div>
        <div class="index-number">{{data.indexNumber}}</div>
        <div class="index-name" [class.bold]="expanded">{{translateIndexName()}}</div>
        <div class="actions">
            <mat-icon *ngIf="data.indexInstructionId" class="action-icon question-mark" (click)="showInstruction(); $event.stopPropagation()">help_outline</mat-icon>
            <mat-icon *ngIf="expanded" matRipple class="action-icon">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!expanded" matRipple class="action-icon">keyboard_arrow_down</mat-icon>
        </div>
    </div>
    <div *ngIf="expanded">
      <app-question-answer-form *ngFor="let question of data.questions"
                                [topicIds]="topicIds"
                                [customMetricName]="data.customMetricName"
                                [isReviewer]="isReviewer"
                                [data]="question"
                                [content]="question.questionContent"
                                [firstInGroup]="question.questionContent | inGroup: 'first': contents"
                                [lastInGroup]="question.questionContent | inGroup: 'last': contents"
                                (valueChanged)="checkIfComplete()"
                                [token]="token"
                                [disabled]="disabled"
                                [proposalsAction]="proposalsAction">
      </app-question-answer-form>
    </div>
</mat-card>
