import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {SmartFormDataSourceDef, SmartFormFieldType} from './smart-form.api';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-smart-form',
  templateUrl: './smart-form.component.html',
  styleUrls: ['./smart-form.component.scss']
})
export class SmartFormComponent implements OnInit, OnDestroy {

  @Output() submitEvent = new EventEmitter();
  @Input() dataSourceDef: SmartFormDataSourceDef;
  @Input()
  readonly = false;
  @Input()
  dataSourceDef$: Observable<SmartFormDataSourceDef>;

  formGroup: FormGroup;
  private subscriptions: Subscription = new Subscription();

  constructor() {
  }

  ngOnInit() {
    if (this.dataSourceDef$ != null) {
      this.subscriptions.add(this.dataSourceDef$.subscribe(dataSource => {
          this.dataSourceDef = dataSource;
          this.buildFormFromDatasource();
      }));
    } else {
      this.buildFormFromDatasource();
    }

    setTimeout(() => this.formGroup.updateValueAndValidity(), 0);
  }

  private buildFormFromDatasource() {
    const fieldsCtrls = {};
    for (const field of this.dataSourceDef.fields) {
      if (field.type !== 'checkbox') {
        fieldsCtrls[field.name] = new FormControl(field.value || '');
        const validators: ValidatorFn[] = [];
        if (field.required) {
          validators.push(Validators.required);
        }
        if (field.type === SmartFormFieldType.Email) {
          validators.push(Validators.email);
        }
        if (validators.length > 0) {
          fieldsCtrls[field.name].setValidators(validators);
        }
      } else {
        const opts = {};
        for (const opt of field.options) {
          opts[opt.key] = new FormControl(opt.label);
        }
        fieldsCtrls[field.name] = new FormGroup(opts);
      }
    }
    this.formGroup = new FormGroup(fieldsCtrls);

    this.subscriptions.add(this.formGroup.statusChanges.subscribe(value => {
      this.dataSourceDef.isValidStream.next(value === 'VALID');
    }));

    this.subscriptions.add(this.formGroup.valueChanges.subscribe(value => {
      for (const field of this.dataSourceDef.fields) {
        field.value = value[field.name];
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}


