import {Injectable} from '@angular/core';
import {VariantService} from './variant.service';
import {ReportDefinition} from '../models/report-definition';
import {ReportType} from '../models/report-type';
import {
  FetchCommand,
  FetchResultReportVO,
  FinishReportCommand,
  OrganizationReportAssignedUserVO,
  OrganizationReportSectionAssignedUserVO,
  QuestionReportVO,
  QuestionReportWithOrganizationNameVO,
  ReportEndpointService,
  ReportVO,
  UserOrganizationReportEndpointService,
  VariantVO,
} from 'src/app/openapi';
import {BehaviorSubject, Observable} from 'rxjs';
import {Report} from '../models/report';
import {map} from 'rxjs/operators';
import {
  SmartTableColumn,
  SmartTableColumnType,
  SmartTableDataProvider,
  SmartTableDataSourceDef,
  SmartTablePageData
} from '../../../../shared/toolkit/smart-table/smart-table.api';
import {OptionalSector} from '../models/optional-sector';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  report: ReportVO;
  private readonly DEFINITIONS: BehaviorSubject<ReportDefinition[]> = new BehaviorSubject<ReportDefinition[]>([]);

  constructor(private variantService: VariantService,
              private reportEndpointService: ReportEndpointService,
              private readonly userOrganizationReportEndpointService: UserOrganizationReportEndpointService) {
    this.variantService.VARIANTS$.subscribe((variants) => {
      this.definitions = this.transform(variants);
      this.addCustomRules();
    });
  }

  get definitions(): ReportDefinition[] {
    return this.DEFINITIONS.getValue();
  }

  set definitions(val: ReportDefinition[]) {
    this.DEFINITIONS.next(val);
  }

  getDefinitions(): Observable<ReportDefinition[]> {
    return this.DEFINITIONS.asObservable();
  }

  getReport(def: ReportDefinition, variants: number[]): Report {
    return {
      definition: def,
      requiredIn: def.types.filter((t) => variants.includes(t.id)),
      sectors: def.sectors.filter((sector) => variants.includes(sector.id))
    } as Report;
  }

  transformVariantIdsToReports(variants: number[]): Report[] {
    return this.definitions.map((def) => {
      return variants?.includes(def.id) ? this.getReport(def, variants) : null;
    }).filter((report) => report !== null);
  }

  reduceReportsToVariantIds(reports: Report[]): number[] {
    const variants: number[] = [];
    reports.forEach((report) => {
      variants.push(report.definition.id);
      report.requiredIn.forEach((type) => {
        variants.push(type.id);
      });
      report.sectors.forEach((sector) => {
        variants.push(sector.id);
      });
    });
    return variants;
  }

  getOrganizationReports(fetchCommand: FetchCommand): Observable<FetchResultReportVO> {
    return this.reportEndpointService.fetchAllOrganizationReports(fetchCommand);
  }

  getOrganizationReport(id: number): Observable<ReportVO> {
    return this.getOrganizationReports({
      pageSize: 1,
      search: {
        id: id.toString(),
      },
    }).pipe(map(result => result.rows[0]));
  }

  finishReport(command: FinishReportCommand): Observable<void> {
    return this.reportEndpointService.finishReport(command);
  }

  private transform(variants: VariantVO[]): ReportDefinition[] {
    return variants.filter((v) => v.parentVariantId === null && v.type === VariantVO.TypeEnum.REPORT)
      .map((v) => {
        return {
          id: v.id,
          name: v.name,
          order: v.order,
          types: variants.filter((v1) => v1.parentVariantId === v.id && v1.type === VariantVO.TypeEnum.REPORTTYPE)
            .map((v1) => {
              return {id: v1.id, name: v1.name, order: v1.order} as ReportType;
            }),
          sectors: variants.filter((v2) => v2.parentVariantId === v.id && v2.type === VariantVO.TypeEnum.OPTIONAL)
            .map((v2) => {
              return {id: v2.id, name: v2.name, order: v2.order} as OptionalSector;
            }),
        } as ReportDefinition;
      });
  }

  private addCustomRules(): void {
    // Rule that for GRI Index can be required in Core and Comprehensive, only Comprehensive or neither (be optional)
    const def: ReportDefinition = this.definitions.find((d) => d.name === 'GRI');
    if (def) {
      const indexGRI: number = this.definitions.indexOf(def);
      // [] - neither Core nor Comprehensive were selected
      // [4] - only Comprehensive (id = 4) can be selected
      // [3, 4] - Core (id = 3) and Comprehensive (id = 4) were selected
      this.definitions[indexGRI].typeRules = [[], [4], [3, 4]];
    }
  }

  fetchReportQuestionsWithAnswers(reportId: number, indexId?: number): Observable<QuestionReportVO[]> {
    return this.reportEndpointService.fetchReportIndexAnswers({
      page: 0,
      pageSize: 0,
      search: {reportId: reportId.toString(), indexId: indexId.toString()}
    }).pipe(
      map(value => value.rows)
    );
  }

  fetchSubsidiariesReportQuestionsWithAnswers(reportId: number, indexId?: number): Observable<QuestionReportWithOrganizationNameVO[]> {
    return this.reportEndpointService.fetchSubsidiariesReportIndexAnswers({
      page: 0,
      pageSize: 0,
      search: {reportId: reportId.toString(), indexId: indexId.toString()}
    }).pipe(
      map(value => value.rows)
    );
  }

  fetchReportAssignedUsers(reportId: number): Observable<OrganizationReportAssignedUserVO[]> {
    return this.reportEndpointService.fetchUsersAssignedToReport({
      page: 0,
      pageSize: 0,
      search: {reportId: reportId.toString()}
    }).pipe(
      map(value => value.rows)
    );
  }

  fetchUsersAssignedToPreviousReport(reportId: number): Observable<OrganizationReportAssignedUserVO[]> {
    return this.reportEndpointService.fetchUsersAssignedToPreviousReport(reportId);
  }

  fetchUsersAssignedToReportAndSection(reportId: number): Observable<OrganizationReportSectionAssignedUserVO[]> {
    return this.reportEndpointService.fetchUsersAssignedToReportAndSection({
      page: 0,
      pageSize: 0,
      search: {reportId: reportId.toString()}
    }).pipe(
      map(value => value.rows)
    );
  }

  fetchUsersAssignedToPreviousReportAndSection(reportId: number): Observable<OrganizationReportSectionAssignedUserVO[]> {
    return this.reportEndpointService.fetchUsersAssignedToPreviousReportAndSection(reportId);
  }

  changeReportVariant(reportId: number, variant: number): Observable<void> {
    return this.reportEndpointService.changeReportVariant({reportId, variant});
  }
}
