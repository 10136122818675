import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent {

  @Input() disabled: boolean;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  buttonClicked(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }

}
