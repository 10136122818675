import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss']
})
export class AddEditModalComponent implements OnChanges {

  @Input() edit: boolean;
  @Input() isSaving: boolean;
  @Input() headerAdd: string;
  @Input() headerEdit: string;
  @Input() showError: boolean;
  @Input() errorMessage: string;
  @Input() canDelete = true;
  @Input() validForSave = true;
  @Input() customIcon: string;
  @Input() showIcon = true;
  @Input() buttonsEnabled = true;
  @Input() saveButtonCode = 'ui.save-dialog.action.save';
  @Output() saveClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteClick: EventEmitter<void> = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
  }

  save(): void {
    this.saveClick.emit();
  }

  delete(): void {
    this.deleteClick.emit();
  }

}
