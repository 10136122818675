import {Routes} from '@angular/router';
import {LayoutComponent} from '../shared/components/layout/layout.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    loadChildren: () => import('../modules/database/database.module')
      .then(c => c.DatabaseModule)
  }
];

export default routes;
