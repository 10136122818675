import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-multiselect-with-chips',
  templateUrl: './multiselect-with-chips.component.html',
  styleUrls: ['./multiselect-with-chips.component.scss']
})
export class MultiselectWithChipsComponent<T extends { name: string, order: number }> implements OnInit {

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() edit: boolean;
  @Input() options: T[];
  @Input() selected: T[];
  @Input() placeholder: string;

  ctrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  suggested: Observable<string[]>;

  constructor() { }

  ngOnInit(): void {
    this.suggested = this.ctrl.valueChanges.pipe(
        startWith(null as string),
        map((elem: string | null) => elem ? this.filterAsStrings(elem) : this.getAvailableAsStrings()));
  }

  addSector(event: MatChipInputEvent): void {
    if (event.value.trim()) {
      const found: T[] = this.filter(event.value.trim());

      if (found?.length) {
        this.addToSelected(found[0]);
        this.resetInput();
        this.resetCtrl();
      }
    }
  }

  remove(elem: T): void {
    this.selected.splice(this.selected.indexOf(elem), 1);
  }

  wasSelected(event: MatAutocompleteSelectedEvent): void {
    const elem: T = this.options.find((e) => e.name === event.option.viewValue);

    if (elem) {
      this.addToSelected(elem);
      this.resetInput();
      this.resetCtrl();
    }
  }

  private addToSelected(elem: T): void {
    this.selected.push(elem);
    this.selected.sort((s1, s2) => s1.order - s2.order);
  }

  private filterAsStrings(value: any): string[] {
    return this.getAvailableAsStrings().filter(s => s.toLowerCase().indexOf(value.toLowerCase()) >= 0);
  }

  private getAvailableAsStrings(): string[] {
    return this.getAvailable().map((s) => s.name);
  }

  private filter(value: any): T[] {
    return this.getAvailable().filter(s => s.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
  }

  private getAvailable(): T[] {
    return this.options
        .filter(s => !this.selected.includes(s))
        .sort((s1, s2) => s1.order - s2.order);
  }

  private resetInput(): void {
    this.input.nativeElement.value = '';
  }

  private resetCtrl(): void {
    this.ctrl.setValue(null);
  }
}
