import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {EditableComponentValueChange} from './editable-component-value-change.interface';

function transformToNumberString(x: any, decimalPlaces: number) {
  const val = x.toString()
    .replace(/\./g, ',') // replace . with ,
    .replace(/[,](?=.*[,])/g, '') // remove all , but last
    .replace(/(?!^)-/g, ''); // remove all - but first

  if (decimalPlaces !== undefined) {
    const valueParts = val.split(',');

    if (valueParts[0] === '') {
      valueParts[0] = '0';
    } else if (valueParts[0] === '-') {
      valueParts[0] = '-0';
    }

    if (decimalPlaces === 0) {
      return valueParts[0];
    }

    if (valueParts.length === 1) {
      return valueParts[0] + ',' + '0'.repeat(decimalPlaces);
    }

    const lengthOfDecimalPoints = valueParts[1].length;
    if (lengthOfDecimalPoints < decimalPlaces) {
      return valueParts[0] + ',' + valueParts[1] + '0'.repeat(decimalPlaces - lengthOfDecimalPoints);
    } else if (lengthOfDecimalPoints === decimalPlaces) {
      return valueParts[0] + ',' + valueParts[1];
    } else {
      return valueParts[0] + ',' + valueParts[1].substr(0, decimalPlaces);
    }
  }

  return val;
}

@Component({
  selector: 'app-numeric-editable',
  templateUrl: './numeric-editable.component.html',
  styleUrls: ['./numeric-editable.component.scss']
})
export class NumericEditableComponent implements OnInit, OnDestroy {
  @Input()
  editState = false;

  @Input()
  value: any;

  formattedValue: any;
  initialFormattedValue: any;

  @Input()
  name: string;

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  editAccepted: EventEmitter<any>;

  @Input()
  editCanceled: EventEmitter<any>;

  @Output()
  valueChanged: EventEmitter<EditableComponentValueChange> = new EventEmitter<EditableComponentValueChange>();

  @Input() formGroup: FormGroup;

  @Input()
  defaultValue = '';

  @Input()
  decimalPoints: number;

  @Input()
  max: number;

  @Input()
  min: number;

  private subscriptions: Subscription = new Subscription();

  get isValid() {
    return this.controller.valid;
  }

  get isDirty() {
    return this.controller.dirty;
  }

  get controller(): FormControl {
    return this.formGroup.controls[this.name] as FormControl;
  }

  ngOnInit(): void {
    if (this.value !== undefined && this.value !== null) {
      this.formattedValue = transformToNumberString(this.value, this.decimalPoints);
      this.controller.setValue(this.formattedValue);
      this.initialFormattedValue = this.formattedValue;
    }

    if (this.editCanceled !== undefined) {
      this.subscriptions.add(this.editCanceled.subscribe(_ => {
        this.formattedValue = this.initialFormattedValue;
        this.value = this.initialFormattedValue;
        this.controller.setValue(this.initialFormattedValue);
      }));
    }

    if (this.editAccepted !== undefined) {
      this.subscriptions.add(this.editAccepted.subscribe(_ => {
        this.initialFormattedValue = transformToNumberString(this.value, this.decimalPoints);
        this.formattedValue = this.initialFormattedValue;
      }));
    }
  }

  onChange($event: any) {
    if ($event.toString().trim() === '') {
      this.value = '';
    } else {
      const val = $event.toString().replace(/[^\d,.-]/g, '');
      this.value = val;
      if ($event !== val) {
        this.controller.setValue(val);
      }
    }
  }

  private valueAsNumber() {
    return Number(this.value.replace(',', '.'));
  }

  _onBlur() {
    this.formatValue(this.controller.value);
  }

  _onKeyEnter() {
    this.formatValue(this.controller.value);
  }

  private formatValue(value: string | null) {
    if (value !== null && value !== undefined && value.trim() !== '') {
      this.value = transformToNumberString(value, this.decimalPoints);
      this.formattedValue = this.value;
    } else {
      this.value = '';
      this.formattedValue = '';
    }

    const numValue = this.valueAsNumber();
    if (!isNaN(numValue) && this.min !== undefined) {
      if (numValue < this.min) {
        this.value = transformToNumberString(this.min, this.decimalPoints);
        this.formattedValue = this.value;
      }
    }

    if (!isNaN(numValue) && this.max !== undefined) {
      if (numValue > this.max) {
        this.value = transformToNumberString(this.max, this.decimalPoints);
        this.formattedValue = this.value;
      }
    }

    if (this.controller.value !== this.value) {
      this.controller.setValue(this.value);
    }

    this.valueChanged.emit({
      name: this.name,
      value: this.valueAsNumber(),
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasRequiredError() {
    if (this.controller !== undefined) {
      return this.controller.hasError('required');
    } else {
      console.log(`NumericEditableComponent (${name}) has undefined controller`);
      return false;
    }
  }
}
