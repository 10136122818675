import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

export interface BreadcrumbNameProvider {
  getName(id: string): Observable<string>;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private breadcrumbNames = new Map<string, string>();
  private breadcrumbProviders = new Map<string, BreadcrumbNameProvider>();
  public readonly breadcrumbNameLoaded: EventEmitter<void> = new EventEmitter<void>();

  public get(url: string, defaultLabel: string, provider?: string, id?: string) {
    const cachedName = this.breadcrumbNames.get(url);
    if (cachedName) {
      return cachedName;
    }

    if (provider) {
      const breadcrumbNameProvider = this.breadcrumbProviders.get(provider);
      if (breadcrumbNameProvider) {
        breadcrumbNameProvider.getName(id).pipe(take(1)).subscribe(name => {
          this.breadcrumbNames.set(url, name);
          this.breadcrumbNameLoaded.emit();
        });
      }
    }
    return defaultLabel;
  }

  public setProvider(name: string, provider: BreadcrumbNameProvider) {
    this.breadcrumbProviders.set(name, provider);
  }
}
