import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SmartFormField} from '../smart-form.api';

@Component({
  selector: 'app-smart-form-file',
  styleUrls: ['./smart-field.scss'],
  template: `
    <mat-form-field appearance="standard" class="form-field">
      <mat-label>{{field.label}} <span *ngIf="field.required"> *</span></mat-label>
      <ngx-mat-file-input [id]="field.name" [formControl]="controller">
      </ngx-mat-file-input>

      <mat-error [id]="null" *ngIf="this.formGroup.controls[this.field.name].hasError('required')">
        Pole <strong>wymagane</strong>
      </mat-error>
    </mat-form-field>
  `
})
export class SmartFormFileComponent {
  @Input() field: SmartFormField;
  @Input() formGroup: FormGroup;

  get isValid() {
    return this.controller.valid;
  }

  get isDirty() {
    return this.controller.dirty;
  }

  get controller(): FormControl {
    return this.formGroup.controls[this.field.name] as FormControl;
  }

  constructor() {
  }

}
