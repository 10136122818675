<div *ngIf="subtitle" class="subtitle" [class]="getSubtitleClassCssExpression()" [style.font-size]="subtitleSize">
  {{subtitle}}
</div>
<div *ngIf="!subtitle" class="fill">
  <!--      No subheader present in this panel, this is used to make heights of all panels equal  -->
</div>
<section *ngFor="let row of rows" class="section-row"
         [class.justify-left]="row.justifyContent === 'left'"
         [class.justify-right]="row.justifyContent === 'right'"
         [class.justify-space-between]="row.justifyContent === 'space-between'"
         [class.justify-space-evenly]="row.justifyContent === 'space-evenly'">
  <section *ngFor="let cell of row.cells">
    <div class="section-header">{{cell.label}}</div>

    <mat-chip-list *ngIf="cell | displayAsChip: true" class="mat-chip-list-row">
      <mat-chip *ngFor="let val of cell.value" class="chip-active mat-chip-company-settings" [class]="cell.chipActive ? getActiveChipClassCssExpression() : getChipClassCssExpression()">
        {{ val }}
      </mat-chip>
    </mat-chip-list>

    <mat-chip *ngIf="cell | displayAsChip: false" class="mat-chip-company-settings">
      <mat-icon *ngIf="cell.icon" [color]="cell.iconColor">{{ cell.icon }}</mat-icon>
      {{cell.value}}
    </mat-chip>

    <div *ngIf="!cell.displayAsChip" class="section-value"
         [class.positive]="cell.valueColor === 'positive'"
         [class.negative]="cell.valueColor === 'negative'">
      {{cell.value}}
    </div>
  </section>
</section>
