import {Component, OnInit} from '@angular/core';
import {TranslateFacadeService} from "../../services/translate-facade.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  languages = [
    {label: 'Polski', code: 'pl'},
    {label: 'English', code: 'en'},
  ];
  currentLang: string;

  constructor(private readonly translate: TranslateFacadeService) {
  }

  ngOnInit(): void {
    this.changeCurrentLanguage(this.translate.currentLang);
    this.translate.onLangChange.subscribe(langChange => this.changeCurrentLanguage(langChange.lang));
  }

  changeCurrentLanguage(newLang: string) {
    this.currentLang = newLang;
  }

  onLanguageSelect(language: string) {
    this.translate.use(language);
  }
}
