import {QuestionWithAnswer} from '../../model/question-with-answer';
import {IndexFormData} from '../../model/index-form-data';
import {QuestionFormData} from '../../model/question-form-data';
import {Department} from '../../../../admin/report-metadata-settings/models/department';
import {AnswerContentJson} from '../../model/answer-content-json';

interface ProjectGroup {
  metricName: string;
  groups: QuestionsGroup[];
}

interface QuestionsGroup {
  indexId: number;
  metricName: string;
  data: QuestionWithAnswer;
  questions: {
    questionId: number;
    answers: QuestionWithAnswer[];
  }[];
}

export const questionWithAnswersToIndexFormData = (questions: QuestionWithAnswer[], departments: Department[]): IndexFormData[] => {
  const groups: QuestionsGroup[] = [];
  questions.forEach(ques => {
    const group = groups.find(g => g.indexId === ques.indexId && g.metricName === ques.customMetricName);
    if (group) {
      const question = group.questions.find(q => q.questionId === q.questionId);
      if (question) {
        question.answers.push(ques);
      } else {
        group.questions.push({questionId: ques.questionId, answers: [ques]});
      }
    } else {
      groups.push({
        indexId: ques.indexId,
        metricName: ques.customMetricName,
        data: ques,
        questions: [{questionId: ques.questionId, answers: [ques]}]
      });
    }
  });

  groups.sort((a, b) => a.metricName?.localeCompare(b.metricName));
  return toIndexFormData(groups, departments);
};

const toIndexFormData = (groups: QuestionsGroup[], departments: Department[]): IndexFormData[] => {
  const projects: ProjectGroup[] = [];
  groups.forEach(group => {
    const project = projects.find(p => p.metricName === group.metricName);
    if (project) {
      project.groups.push(group);
    } else {
      projects.push({
        metricName: group.metricName,
        groups: [group]
      });
    }
  });

  const result: IndexFormData[] = [];
  projects.map(project => {
    const uniqueDepartmentIds: Set<number> = new Set(project.groups.map(group => group.data.reportDepartmentId));
    const departmentId: number = uniqueDepartmentIds.size === 1 ? uniqueDepartmentIds.values().next().value : null;
    const department: Department = departments.find(d => d.id === departmentId);
    project.groups.forEach(group => {
      result.push(...getSubQuestions(group, department));
    });
  });
  return result;
};

const getSubQuestions = (group: QuestionsGroup, department: Department): IndexFormData[] => {
  const data = group.data;
  return group.questions.map(question => {
    const sub = question.answers.map(answer => (answer as QuestionFormData));
    const proposal = sub.some(q => isProposalApply(q.answerContent));
    return {
      reportId: data.reportId,
      indexId: data.indexId,
      indexName: data.indexName,
      indexTranslation: data.indexTranslation,
      indexNumber: data.indexNumber,
      indexOrder: data.indexOrder,
      indexContent: data.indexContent,
      indexInstructionId: data.indexInstructionId,
      taxonomyCategory: data.indexContent?.taxonomyCategory,
      taxonomyJoinType: data.indexContent?.taxonomyJoinType,
      taxonomyIndexDepartment: data.departmentId,
      reportDepartmentId: department?.id,
      reportDepartmentName: department?.name || '',
      customMetricName: data.customMetricName,
      questions: sub,
      reportDepartmentTranslation: department?.translation || {},
      isAnyProposal: proposal
    } as IndexFormData;
  });
};

function isProposalApply(content: AnswerContentJson) {
  const answer = content?.answer;
  if (!answer) {
    return false;
  }

  if (answer.OPTION?.isProposal) {
    return true;
  }
  if (answer.TEXT?.isProposal) {
    return true;
  }
  if (answer.NUMBER?.isProposal) {
    return true;
  }
  if (answer.TRUTH?.isProposal) {
    return true;
  }
  if (answer.DATASOURCE?.isProposal) {
    return true;
  }
  if (answer.userText?.isProposal) {
    return true;
  }
  return false;
}
