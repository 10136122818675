import {Routes} from '@angular/router';
import {DatabaseLogInComponent} from '../modules/annonymous/database-login/components/database-log-in/database-log-in.component';
import {AnswersFormComponent} from 'src/app/modules/annonymous/answers/components/answers-form/answers-form.component';
import {RegisterComponent} from '../modules/annonymous/register/components/log-in/register.component';
import {LogInComponent} from '../modules/annonymous/login/components/log-in/log-in.component';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('../modules/annonymous/login/login.module')
      .then(m => m.LoginModule), component: LogInComponent},
  {path: 'login-database', loadChildren: () => import('../modules/annonymous/database-login/database-login.module')
      .then(m => m.DatabaseLoginModule), component: DatabaseLogInComponent},
  {path: 'register', loadChildren: () => import('./../modules/annonymous/register/register.module')
      .then(m => m.RegisterModule), component: RegisterComponent},
  {path: 'answers/:token', loadChildren: () => import('./../modules/annonymous/answers/answers.module')
        .then(m => m.AnswersModule), component: AnswersFormComponent},
  {path: '**', redirectTo: 'login'}
];

export default routes;
