import {AnswerContentJson} from '../../model/answer-content-json';
import {QuestionFormData} from '../../model/question-form-data';
import {AnswerPerType} from '../../model/answer-per-type';
import {QuestionContentJson} from '../../../../admin/report-metadata-settings/models/question-content-json';

export function defaultAnswer(questionFormData: QuestionFormData, metricName: string): AnswerContentJson {
  if (questionFormData.answerContent) {
    clearAllProposalsFromAnswerContent(questionFormData.answerContent);
    if (!isAnyProposalActive(questionFormData.answerContent.answer)) {
      if (questionFormData.answerContent?.subAnswers) {
        questionFormData.answerContent.subAnswers = questionFormData.answerContent?.subAnswers
          .filter(ans => !isAnyProposalActive(ans));
      }
      return questionFormData.answerContent;
    }
  }

  questionFormData.answerContent = {
    answer: initAnswer(questionFormData.questionContent),
    subAnswers: [],
    comment: '',
    customMetricName: metricName,
  } as AnswerContentJson;

  if (questionFormData.questionContent.answerTypes.TRUTH) {
    const {notApplicable, noCriteria, yes, no} = questionFormData.questionContent.answerTypes.TRUTH;
    const isNotApplicable = notApplicable && noCriteria && !yes && !no;
    if (isNotApplicable) {
      questionFormData.answerContent.answer.TRUTH.value = 'notApplicable';
    }
  }

  return questionFormData.answerContent;
}

function initAnswer(content: QuestionContentJson): AnswerPerType {
  return updateAnswerProperties(content, {} as AnswerPerType);
}

function updateAnswerProperties(questionContent: QuestionContentJson, answer: AnswerPerType): AnswerPerType {
  const answerKeys: string[] = Object.keys(questionContent.answerTypes)
    .filter(key => ['TEXT', 'NUMBER', 'OPTION', 'SUPPORT', 'DATASOURCE', 'TRUTH', 'CHECKLIST'].includes(key));
  if (questionContent.usersText) {
    answerKeys.push('userText');
  }

  answerKeys.forEach((key) => {
    // Create property if do not exist
    if (!(key in answer)) {
      answer[key] = {};
    }
  });

  // Remove if was deleted from question
  Object.keys(answer).forEach((key) => {
    if (!answerKeys.includes(key)) {
      delete answer[key];
    }
  });

  return answer;
}

function clearAllProposalsFromAnswerContent(content: AnswerContentJson): AnswerContentJson {
  if (!content) {
    return content;
  }

  if (!content.isCommentProposal) {
    delete content.isCommentProposal;
  }

  cleanupAnswerProposalSignature(content.answer);
  content?.subAnswers.forEach(ans => cleanupAnswerProposalSignature(ans));
  return content;
}

function cleanupAnswerProposalSignature(answer: AnswerPerType): void {
  if (isFalse(answer?.NUMBER?.isProposal)) {
    delete answer.NUMBER.isProposal;
  }
  if (isFalse(answer?.userText?.isProposal)) {
    delete answer.userText.isProposal;
  }
  if (isFalse(answer?.TEXT?.isProposal)) {
    delete answer.TEXT.isProposal;
  }
  if (isFalse(answer?.OPTION?.isProposal)) {
    delete answer.OPTION.isProposal;
  }
  if (isFalse(answer?.TRUTH?.isProposal)) {
    delete answer.TRUTH.isProposal;
  }
  if (isFalse(answer?.DATASOURCE?.isProposal)) {
    delete answer.DATASOURCE.isProposal;
  }
}

function isAnyProposalActive(answer: AnswerPerType): boolean {
  if (answer?.NUMBER?.isProposal) {
    return true;
  }
  if (answer?.userText?.isProposal) {
    return true;
  }
  if (answer?.TEXT?.isProposal) {
    return true;
  }
  if (answer?.OPTION?.isProposal) {
    return true;
  }
  if (answer?.TRUTH?.isProposal) {
    return true;
  }
  if (answer?.DATASOURCE?.isProposal) {
    return true;
  }
}

function isFalse(object: any): boolean {
  return object !== undefined && object === false;
}
