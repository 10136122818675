import {Routes} from '@angular/router';
import {LayoutComponent} from '../shared/components/layout/layout.component';
import {AppAccessGuardService} from '../shared/guards/app-access-guard.service';
import {AnswersFormComponent} from '../modules/annonymous/answers/components/answers-form/answers-form.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent, canActivate: [AppAccessGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'reports-dashboard'
      },
      {
        path: 'reports-dashboard',
        loadChildren: () => import('./../modules/user/reports-dashboard/reports-dashboard.module')
          .then(m => m.ReportsDashboardModule)
      },
      {
        path: 'user-dashboard',
        loadChildren: () => import('./../modules/user/dashboard/dashboard.module')
          .then(m => m.DashboardModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./../modules/user/reports/reports.module')
          .then(m => m.ReportsModule)
      },
      {
        path: 'reports-settings',
        loadChildren: () => import('./../modules/user/reports-settings/reports-settings.module')
          .then(m => m.ReportsSettingsModule)
      },
      {
        path: 'account-settings',
        loadChildren: () => import('./../modules/user/account-settings/account-settings.module')
          .then(m => m.AccountSettingsModule)
      },
      {
        path: 'user-account',
        loadChildren: () => import('../shared/components/user-account/user-account.module')
          .then(m => m.UserAccountModule),
        data: {breadcrumb: 'ui.breadcrumbs.user.user-account'}
      },
      {
        path: 'buy-package',
        loadChildren: () => import('./../modules/user/buy-package/buy-package.module')
          .then(m => m.BuyPackageModule),
        data: {breadcrumb: 'ui.breadcrumbs.user.buy-package'}
      },
      {
        path: 'capital-group-companies',
        loadChildren: () => import('./../modules/user/capital-group-companies/capital-group-companies.module')
          .then(m => m.CapitalGroupCompaniesModule),
        data: {breadcrumb: 'ui.breadcrumbs.user.capital-group'}
      },
      {
        path: 'help',
        loadChildren: () => import('./../modules/user/help/help.module')
          .then(m => m.HelpModule),
        data: {breadcrumb: 'ui.breadcrumbs.user.help'}
      }
    ]
  },
  {
    path: 'answers/:token', loadChildren: () => import('./../modules/annonymous/answers/answers.module')
      .then(m => m.AnswersModule), component: AnswersFormComponent
  },
  {path: '**', redirectTo: ''}
];

export default routes;
