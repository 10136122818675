<div class="login-title">
  System sprawnego<br/>
  raportowania<br/>
  danych<br/>
  niefinansowych
</div>
<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3rem">
    <div class="logo">
      <img src="assets/login-logo.png">
    </div>

    <div class="login-header" *ngIf="formState === 'register'">Załóż konto demo</div>
    <div class="login-header" *ngIf="formState === 'success'">Konto demo założone</div>

    <form class="login-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()" *ngIf="formState === 'register'">
      <mat-card-content>
        <mat-form-field class="full-width">
          <input matInput placeholder="Nazwa użytkownika" formControlName="userName">
        </mat-form-field>

        <mat-form-field class="full-width">
          <input matInput type="email" placeholder="E-mail" formControlName="email">
        </mat-form-field>
      </mat-card-content>
      <mat-error *ngIf="errorMessage != null">
        {{errorMessage}}
      </mat-error>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <button mat-raised-button color="primary" fxFlex>Załóż konto</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="register-button-container">
        <button mat-button color="primary" [routerLink]="'/login'">Masz konto? Zaloguj się</button>
      </div>
    </form>

    <form *ngIf="formState === 'success'">
      <p>
        Dziękujemy,<br/>
        Konto zostało założone, email aktywujący dostęp powinien przyjść niedługo.
      </p>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <a mat-raised-button color="primary" fxFlex href="/">Przejdź do logowania</a>
      </div>
    </form>
  </mat-card>
</div>
