import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-info-rows',
  templateUrl: './info-rows.component.html',
  styleUrls: ['./info-rows.component.scss']
})
export class InfoRowsComponent implements OnInit {

  @Input() rows: BigInfoRow[];
  @Input() subtitle: string;
  @Input() subtitleColor: string;
  @Input() subtitleSize: string;
  @Input() customCssClassPostfix: string;

  readonly cssClassPrefix = 'big-info';

  constructor() { }

  ngOnInit(): void {
  }

  // CSS class name returned by the method is present in app-theme.scss
  getActiveChipClassCssExpression() {
    return this.cssClassPrefix + '-chip-active-' + this.customCssClassPostfix;
  }

  // CSS class name returned by the method is present in app-theme.scss
  getChipClassCssExpression() {
    return this.cssClassPrefix + '-chip-' + this.customCssClassPostfix;
  }

  // CSS class name returned by the method is present in app-theme.scss
  getSubtitleClassCssExpression() {
    return this.cssClassPrefix + '-subtitle-' + this.customCssClassPostfix;
  }
}

export interface BigInfoRow {
  cells: BigInfoCell[];
  justifyContent: 'space-between' | 'space-evenly' | 'left' | 'right';
}

export interface BigInfoCell {
  chipActive?: boolean;
  label: string;
  value: string | string[];
  valueColor?: 'positive' | 'negative' | 'grey' | '';
  displayAsChip?: boolean;
  icon?: string;
  iconColor?: 'warn' | 'primary' | 'accent' | '';
}
