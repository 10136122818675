<div class="login-title">
  System sprawnego<br/>
  raportowania<br/>
  danych<br/>
  niefinansowych
</div>
<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="3rem">
    <div class="logo">
      <img src="assets/login-logo.png">
    </div>

    <div class="login-header" *ngIf="formState === 'login'">Zaloguj sie</div>

    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()" *ngIf="formState === 'login'">
      <mat-card-content>
        <mat-form-field class="full-width">
          <input matInput placeholder="E-mail" formControlName="userName">
          <mat-error *ngIf="loginForm.get('userName').hasError('required')">Email jest wymagany</mat-error>
          <mat-error *ngIf="!loginForm.get('userName').hasError('required') && loginForm.get('userName').hasError('email')">Zły format adresu</mat-error>
        </mat-form-field>
        <mat-error *ngIf="wasAuthError$ | async; let error">
          Twoja organizacja nie posiada konta w aplikacji
        </mat-error>
        <p *ngIf="wasEmailSendSuccess$ | async; let error" class="success">Na podany adres email został wysłany link do logowania</p>
      </mat-card-content>
      <div class="buttons-wrapper">
        <button mat-raised-button color="primary" fxFlex>Zaloguj</button>
      </div>
    </form>
  </mat-card>
</div>
