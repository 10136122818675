<button (click)="openFinishReportModal()" *ngIf="!report?.isFinished" [disabled]="isFinishingReport"
        class="finish-report-button"
        mat-button>
  <mat-icon color="primary">done</mat-icon>
  {{ 'ui.user.dashboard.default-report.buttons.finish' | translate }}
  <mat-spinner *ngIf="isFinishingReport" [diameter]="25"></mat-spinner>
</button>
<button (click)="openReopenReportModal()" *ngIf="report?.isFinished && report?.canBeReopened"
        [disabled]="isFinishingReport"
        class="finish-report-button" mat-button>
  <mat-icon color="primary">undo</mat-icon>
  {{ 'ui.user.dashboard.default-report.buttons.activate' | translate }}
  <mat-spinner *ngIf="isFinishingReport" [diameter]="25"></mat-spinner>
</button>
