import {Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {AuthLogoutAction} from '../../../state/auth.actions';
import {AuthState} from '../../../state/auth.state';
import {AuthenticationDTO} from '../../../services/authentication.service';
import {FetchResultOrganizationPackageSummaryVO, OrganizationPackageSummaryVO} from '../../../openapi';
import {ActivatedRoute} from '@angular/router';
import {OrganizationService} from '../../services/organization.service';
import {TranslateFacadeService} from '../../services/translate-facade.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  organizationId: number;
  organizationPackages: OrganizationPackageSummaryVO[];
  authenticationDTO: AuthenticationDTO;
  roles: string[];

  constructor(private readonly store: Store,
              private readonly translate: TranslateFacadeService,
              private route: ActivatedRoute,
              private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    this.authenticationDTO = this.store.selectSnapshot(AuthState.principal);
    this.roles = this.authenticationDTO.roles.split(';');
    const userEmail = this.store.selectSnapshot(AuthState.principal).email;
    this.fetchOrganizationId(userEmail);
  }

  fetchOrganizationId(userEmail: string): void {
    this.organizationService.getOrganizationPackageSummary({
      page: 0,
      pageSize: 0,
      search: {adminEmail: userEmail}
    }).subscribe((result: FetchResultOrganizationPackageSummaryVO) => {
        this.organizationId = result.rows[0]?.organizationId;
        this.organizationPackages = result.rows;
      }
    );
  }

  logout() {
    this.roles = [];
    this.store.dispatch(new AuthLogoutAction());
  }

  supportsCapitalGroups(): boolean {
    if (this.organizationPackages == null) { return false; }
    const organizationPackage = this.organizationPackages.find((op) => op.capitalGroupRole === 'OWNER');
    return organizationPackage != null;
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }
}
