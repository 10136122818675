import {Injectable} from '@angular/core';
import {OrganizationReportPublicEndpointService, SaveFormAnswersCommand} from 'src/app/openapi';
import {forkJoin, Observable, of} from 'rxjs';
import {QuestionWithAnswer} from 'src/app/modules/annonymous/answers/model/question-with-answer';
import {map} from 'rxjs/operators';
import {AnswerPerType} from './model/answer-per-type';
import {CompleteQuestionChecker} from './components/complete-question-checker.service';

@Injectable({providedIn: 'root'})
export class AnswersService {

  constructor(
    private service: OrganizationReportPublicEndpointService,
    private readonly completePipe: CompleteQuestionChecker,
  ) {
  }

  fetchQuestionsAssignedToToken(token: string): Observable<QuestionWithAnswer[]> {
    return this.service.getReportFormByToken(token).pipe(map(form => form as QuestionWithAnswer[]));
  }

  saveAnswers(command: SaveFormAnswersCommand): Observable<void> {
    command.answers.filter(answer => answer.answerContent)

    return this.service.saveReportFormAnswer(command);
  }

  combineUserQuestionsWithPreviousAnswersIntoSingleObservableArray(token: string): Observable<QuestionWithAnswer[]> {
    const currentUsersAnswers = this.fetchQuestionsAssignedToToken(token);
    const previousUsersAnswers = this.fetchPreviousUsersAnswers(token);
    return forkJoin([currentUsersAnswers, previousUsersAnswers]).pipe(map(([current, previous]) => {
      if (previous.length === 0) {
        return current;
      }

      return current.map(question => {
        if (this.completePipe.isComplete(question)) {
          return question;
        }

        if (question.answerId) {
          return question;
        }

        const previousQuestion = previous.find(prev => prev.questionId === question.questionId);
        if (previousQuestion === undefined || previousQuestion === null) {
          return question;
        }

        const previousAnswerContent = previousQuestion.answerContent;
        if (previousAnswerContent === undefined || previousAnswerContent === null) {
          return question;
        }

        const originalComment = question?.answerContent?.comment;
        if (!(originalComment && originalComment.length !== 0 && originalComment.trim() !== '')) {
          const previousComment = previousAnswerContent.comment;
          if (previousComment && previousComment.length !== 0 && previousComment.trim() !== '') {
            previousAnswerContent.isCommentProposal = true;
          }
        }

        const originalContent = question?.answerContent?.answer;
        if (!originalContent) {
          this.setupAnswer(previousAnswerContent.answer);
        } else if (!this.isAnswerContentFulfilled(originalContent)) {
          this.setupAnswer(previousAnswerContent.answer);
        }

        previousAnswerContent.subAnswers?.forEach((ans, index) => {
          const originalSubAnswer = question?.answerContent?.subAnswers[index];
          if (originalSubAnswer && this.isAnswerContentFulfilled(originalSubAnswer)) {
            return;
          }

          this.setupAnswer(ans);
        });
        question.answerContent = previousAnswerContent;
        return question;
      });
    }));
  }

  private fetchPreviousUsersAnswers(token: string): Observable<QuestionWithAnswer[]> {
    return this.service.getPreviousReportFormByToken(token).pipe(map(form => form as QuestionWithAnswer[]));
  }

  private isAnyContentAware(str: string): boolean {
    return str && str.trim().length !== 0 && str.trim() !== '';
  }

  private setupAnswer = (answer: AnswerPerType) => {
    if (answer.NUMBER) {
      answer.NUMBER.isProposal = true;
    }
    if (answer.userText && this.isAnyContentAware(answer.userText.userText)) {
      answer.userText.isProposal = true;
    }
    if (answer.TEXT && this.isAnyContentAware(answer.TEXT.text)) {
      answer.TEXT.isProposal = true;
    }
    if (answer.OPTION) {
      answer.OPTION.isProposal = true;
    }
    if (answer.TRUTH) {
      answer.TRUTH.isProposal = true;
    }
    if (answer.DATASOURCE && this.isAnyContentAware(answer.DATASOURCE.link)) {
      answer.DATASOURCE.isProposal = true;
    }
  }

  private isAnswerContentFulfilled(content: AnswerPerType): boolean {
    if (content.NUMBER) {
      return content.NUMBER.number !== undefined && content.NUMBER.number !== null;
    }
    if (content.TEXT) {
      return content.TEXT.text !== undefined && content.TEXT.text !== null && content.TEXT.text.trim().length !== 0;
    }
    if (content.userText) {
      return content.userText.userText !== undefined && content.userText.userText !== null && content.userText.userText.trim().length !== 0;
    }
    if (content.OPTION) {
      return content.OPTION.value !== undefined && content.OPTION.value !== null;
    }
    if (content.TRUTH) {
      return content.TRUTH.value !== undefined && content.TRUTH.value !== null;
    }
    if (content.DATASOURCE) {
      return content.DATASOURCE.link !== undefined && content.DATASOURCE.link !== null && content.DATASOURCE.link.trim().length !== 0;
    }
  }
}
