import {Injectable} from '@angular/core';
import {
  CreateReportTopicAssigmentCommand,
  DeleteReportTopicAssigmentCommand,
  ReportTopicAssigmentEndpointService,
  ReportTopicAssignmentView, SelectedTopicsForReportView,
} from '../../../../../openapi';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ReportTopicAssigmentService {

  constructor(
    private readonly endpoint: ReportTopicAssigmentEndpointService
  ) {
  }

  getSelectedTopicsForReport(reportId: number): Observable<SelectedTopicsForReportView[]> {
    return this.endpoint.getSelectedTopicsForReport(reportId);
  }

  getTopicAssignmentsByReportId(reportId: number): Observable<ReportTopicAssignmentView[]> {
    return this.endpoint.getAssignments(reportId);
  }

  save(commands: CreateReportTopicAssigmentCommand[]): Observable<void> {
    return this.endpoint.save(commands);
  }

  delete(command: DeleteReportTopicAssigmentCommand): Observable<void> {
    return this.endpoint.deleteReportTopicAssignment(command);
  }
}
