import {Component, OnDestroy, OnInit} from '@angular/core';
import {Portal} from '@angular/cdk/portal';
import {SidebarService} from './sidebar.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  sidebarPortal: Portal<any>;
  opened: boolean;
  showToggleBtn: boolean;
  private subscriptions: Subscription = new Subscription();

  constructor(private sidebarService: SidebarService) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.sidebarService.$opened.subscribe(state => this.opened = state));
    this.subscriptions.add(this.sidebarService.$showSidebarToggleBtn.subscribe(state => this.showToggleBtn = state));
    this.subscriptions.add(this.sidebarService.$sidebarPortalComponent.subscribe(component => this.sidebarPortal = component));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggle() {
    this.sidebarService.toggle();
  }
}
