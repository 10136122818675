import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-i18n',
  templateUrl: './confirm-dialog-i18n.component.html',
  styleUrls: ['./confirm-dialog-i18n.component.scss']
})
export class ConfirmDialogI18nComponent implements OnInit {
  title: string;
  message: string;
  confirmLabel = 'ui.user.dialog.yesLabel';
  noLabel = 'ui.user.dialog.noLabel';
  icon: string;
  iconColor: 'success' | 'warn' | 'error' = 'success';
  onlyOkOption = false;

  @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogI18nModel;

  constructor(private dialogRef: MatDialogRef<ConfirmDialogI18nComponent>,
              @Inject(MAT_DIALOG_DATA) data: ConfirmDialogI18nModel) {
    this.data = data;
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.icon = data.icon;
    this.iconColor = data.iconColor;
    this.onlyOkOption = data.onlyOkOption != null ? data.onlyOkOption : false;
    this.confirmLabel = this.data.confirmLabel != null ? this.data.confirmLabel : 'ui.user.dialog.yesLabel';
    this.noLabel = this.data.noLabel != null ? this.data.noLabel : 'ui.user.dialog.noLabel';
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export interface ConfirmDialogI18nModel {
  title: string;
  message: string;
  confirmLabel?: string;
  noLabel?: string;
  icon?: string;
  iconColor?: 'success' | 'warn' | 'error';
  onlyOkOption?: boolean;
}
