import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-filter-with-chips',
  templateUrl: './filter-with-chips.component.html',
  styleUrls: ['./filter-with-chips.component.scss']
})
export class FilterWithChipsComponent implements OnInit {

  @Output() filterChange: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();

  @Input() filterFieldsToChoose: Filter[] = [];

  form: FormGroup;
  chosenFilters: Filter[] = [];
  filterTypeEnum = FilterType;
  chosenFilterField: Filter;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      filterField: [''],
      filterValue: ['']
    });
  }

  removeChosenFilter(i: number): void {
    this.chosenFilters.splice(i, 1);
    this.filterChange.emit(this.chosenFilters);
  }

  addFilter(): void {
    if (this.getControlValue('filterField') && this.getControlValue('filterValue')) {
      const filter: Filter = Object.create(this.filterFieldsToChoose.find((f: Filter) => f.filterField === this.getControlValue('filterField')));
      filter.filterValue = this.getControlValue('filterValue');
      this.chosenFilters.push(filter);
      this.resetControlValue('filterField');
      this.resetControlValue('filterValue');
      this.chosenFilterField = null;
      this.filterChange.emit(this.chosenFilters);
    } else {
      console.log('make sure filter value and filter field are provided');
    }
  }

  getControlValue(control: string): string {
    return this.form.get(control).value;
  }

  resetControlValue(control: string): void {
    this.form.get(control).setValue('');
  }

  updateChosenFilterField(event: string) {
    this.chosenFilterField = this.filterFieldsToChoose.find((f: Filter) => f.filterField === event);
  }
}

export interface Filter {
  filterField: string;
  filterValue?: string;
  filterType?: FilterType;
  valuesToChoose?: string[];
  displayName?: string;
  like?: boolean;
}

export enum FilterType {
  text,
  date,
  dropdown
}
