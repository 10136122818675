import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {BreadcrumbsService} from './breadcrumbs.service';
import {Subscription} from 'rxjs';


export interface BreadCrumb {
  label: string;
  url: string;
  skip: boolean;
  id?: string;
  paramName?: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

  breadcrumbs: BreadCrumb[];
  private subscriptions: Subscription = new Subscription();

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly breadcrumbsService: BreadcrumbsService) {
    this.subscriptions.add(this.router.events.pipe(
      startWith(new NavigationEnd(0, '/', '/')),
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map(event => this.buildBreadCrumb(this.activatedRoute.root))
    ).subscribe(bc => this.breadcrumbs = bc));

    this.subscriptions.add(this.breadcrumbsService.breadcrumbNameLoaded.subscribe(_ => {
      this.refreshBreadcrumbLabels();
    }));
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    const noData = !route?.routeConfig?.data;
    const path = route?.routeConfig?.path ?? '';
    const paramName: string = route?.routeConfig?.data?.breadcrumbParamName ?? 'id';
    const id = route?.snapshot?.paramMap.get(paramName);
    const nextUrl = `${url}${path.replace(':' + paramName, id)}/`;
    const label = this.breadcrumbsService.get(nextUrl, route?.routeConfig?.data?.breadcrumb ?? 'Home', route?.routeConfig?.data?.breadcrumbProvider, id);
    const skip = noData ? true : route?.routeConfig?.data?.breadcrumbSkip ?? false;
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const breadcrumb = {
      label,
      url: nextUrl,
      skip,
      id
    };

    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs.filter(item => !item.skip);
  }

  private refreshBreadcrumbLabels() {
    const newBreadcrumbs: BreadCrumb[] = [];
    this.breadcrumbs.forEach(item => {
      newBreadcrumbs.push({
        url: item.url,
        label: this.breadcrumbsService.get(item.url, item.label, undefined, item.id),
        skip: item.skip,
        id: item.id
      });
    });
    this.breadcrumbs = newBreadcrumbs;
  }
}
