import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface IToggleButton {
  label: string;
  dotColor?: string;
}

@Component({
  selector: 'app-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.scss']
})
export class ToggleButtonsComponent implements OnInit {

  @Input()
  buttons: IToggleButton[];

  @Input()
  selectedButtonIndex = 0;

  @Output()
  changed: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  customCssClassPostfix: string;

  @Input()
  buttonGap = '0';

  constructor() {
  }

  ngOnInit(): void {
  }

  onButtonClicked(index: number) {
    this.selectedButtonIndex = index;
    this.changed.emit(index);
  }

  getClassCssExpresion() {
    return 'toggle-buttons' + ((this.customCssClassPostfix != null) ? ' toggle-buttons-' + this.customCssClassPostfix : '');
  }

  getItemClassCssExpresion(index: number) {
    const itemCss = 'toggle-buttons-item' + ((this.customCssClassPostfix != null) ? ' toggle-buttons-item-' + this.customCssClassPostfix : '');
    if (index === this.selectedButtonIndex) {
      return itemCss + 'toggle-buttons-item-selected' + ((this.customCssClassPostfix != null) ? ' toggle-buttons-item-selected-' + this.customCssClassPostfix : '');
    }
    return itemCss;
  }
}
