import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConfirmDialogComponent} from '../../toolkit/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-edit-mode-controls',
  templateUrl: './edit-mode-controls.component.html',
  styleUrls: ['./edit-mode-controls.component.scss']
})
export class EditModeControlsComponent {

  @Input() active: boolean;
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() accept: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  private countChanges = 0;

  constructor(private dialog: MatDialog) { }

  editToggled(): void {
    this.active = true;
    this.changed.emit(this.active);
  }

  acceptEdits(): void {
    if (this.countChanges > 0) {
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Czy zapisać zmiany?',
          message: 'Przywrócenie poprzedniego stanu nie będzie możliwe',
          confirmLabel: 'Tak, zapisz',
          icon: 'help_outline',
          color: 'warn'
        },
      });

      confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.accept.emit();
          confirmDialogRef.close(result);
        }
      });
    } else {
      this.active = false;
      this.changed.emit(this.active);
    }
  }

  cancelEdits(): void {
    if (this.countChanges > 0) {
      this.cancel.emit();
    }
    this.resetCount();
    this.active = false;
    this.changed.emit(this.active);
  }

  changeHappened(): void {
    this.countChanges += 1;
  }

  resetCount(): void {
    this.countChanges = 0;
  }

}
