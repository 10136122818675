<form [formGroup]="form">
  <section class="filters-container">
    <section class="section-filter-input">
      <ng-container [ngSwitch]="chosenFilterField?.filterType">
        <mat-form-field *ngSwitchCase="filterTypeEnum.dropdown" class="custom-input-style no-border-radius-on-right">
          <mat-select formControlName="filterValue" [placeholder]="'ui.filter-with-chips.value' | translate">
            <mat-option *ngFor="let option of chosenFilterField.valuesToChoose"
                        [value]="option">{{ option }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngSwitchCase="filterTypeEnum.date" class="custom-input-style no-border-radius-on-right">
          <input matInput type="date" formControlName="filterValue" [placeholder]="'ui.filter-with-chips.value' | translate"/>
        </mat-form-field>
        <mat-form-field *ngSwitchDefault="" class="custom-input-style no-border-radius-on-right">
          <input matInput formControlName="filterValue" [placeholder]="'ui.filter-with-chips.value' | translate"/>
        </mat-form-field>
      </ng-container>

      <mat-form-field class="field-selector custom-input-style no-border-radius-on-left no-border-radius-on-right">
        <mat-select formControlName="filterField" [placeholder]="'ui.filter-with-chips.field' | translate" (valueChange)="updateChosenFilterField($event)">
          <mat-option *ngFor="let filter of filterFieldsToChoose"
                      [value]="filter.filterField">{{ filter.displayName ? filter.displayName: filter.filterField }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="filter-icon-container" (click)="addFilter()">
        <mat-icon color="primary">filter_alt</mat-icon>
      </div>
    </section>
    <mat-form-field>
      <mat-chip-list class="filter-chip-list">
        <mat-chip *ngFor="let filter of chosenFilters; let i = index" [selectable]="false"
                  [removable]="true" (removed)="removeChosenFilter(i)">
          <mat-icon matChipRemove>cancel</mat-icon>
          <div class="chip-filter-display-name">{{ filter.displayName ? filter.displayName: filter.filterField }}</div>
          {{(filter.filterType === filterTypeEnum.date ? ' ' : ' ' + ('ui.filter-with-chips.is' | translate) + ' ') }}
          <div class="chip-filter-value">{{filter.filterValue }}</div>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>
  </section>
</form>
