import {Injectable} from '@angular/core';
import {AnswerPerType} from '../model/answer-per-type';
import {QuestionContentJson} from '../../../admin/report-metadata-settings/models/question-content-json';
import {QuestionFormData} from '../model/question-form-data';

type CompleteCheckerFunction = (answer: AnswerPerType, content: QuestionContentJson) => boolean;

@Injectable({providedIn: 'root'})
export class CompleteQuestionChecker {

  readonly records: Record<string, CompleteCheckerFunction> = {
    TEXT: this.textComplete.bind(this),
    NUMBER: this.numberComplete.bind(this),
    OPTION: this.optionComplete.bind(this),
    CHECKLIST: this.checklistComplete.bind(this),
  };

  isAllComplete(questions: QuestionFormData[]): boolean {
    for (const q of questions) {
      const complete = this.isComplete(q);
      if (!complete) {
        return false;
      }
    }
    return true;
  }

  isComplete(question: QuestionFormData): boolean {
    if (!question.answerContent?.answer) {
      return false;
    }

    if (!question.questionContent.hasSubqueries) {
      const answer = question.answerContent.answer;
      const content = question.questionContent;
      return this.isAnswerComplete(answer, content);
    }

    if (!question.answerContent?.subAnswers) {
      return false;
    }

    const questions = question.questionContent.subqueries;
    let result = true;
    questions.forEach((sub: QuestionContentJson, index: string | number) => {
      const answer = question.answerContent?.subAnswers[index];
      if (!answer) {
        result = false;
      }

      if (!this.isAnswerComplete(answer, sub)) {
        result = false;
      }
    });
    return result;
  }

  private isAnswerComplete(answer: AnswerPerType, content: QuestionContentJson) {
    if (!answer) {
      return false;
    }

    if (content.usersText && !this.userTextComplete(answer, content)) {
      return false;
    }

    for (const type in answer) {
      if (!answer[type]) {
        continue;
      }

      if (this.records[type]) {
        const result = this.records[type](answer, content);
        if (!result) {
          return false;
        }
      }
    }

    return true;
  }

  private isTypeComplete(answer: AnswerPerType, content: QuestionContentJson, answerType: string): boolean {
    const types = content.answerTypes;
    if (types.ROW_DUPLICATION) {
      return !!answer[answerType]?.multiText?.length && answer[answerType].multiText.every(t => !!t);
    } else {
      return !!answer[answerType]?.text;
    }
  }

  private textComplete(answer: AnswerPerType, content: QuestionContentJson) {
    return this.isTypeComplete(answer, content, 'TEXT');
  }

  private numberComplete(answer: AnswerPerType, content: QuestionContentJson) {
    return this.isTypeComplete(answer, content, 'NUMBER');
  }

  private userTextComplete(answer: AnswerPerType, content: QuestionContentJson): boolean {
    return this.isTypeComplete(answer, content, 'userText');
  }

  private optionComplete(answer: AnswerPerType, content: QuestionContentJson): boolean {
    return answer.OPTION?.value !== undefined || answer.OPTION?.text !== undefined;
  }

  private checklistComplete(answer: AnswerPerType, content: QuestionContentJson) {
    return answer.CHECKLIST.selected && answer.CHECKLIST.selected.length > 1;
  }
}
