<h2 mat-dialog-title>{{title | translate}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="form-wrapper">
    <app-smart-form [dataSourceDef]="formDataSource"></app-smart-form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{cancelBtnLabel | translate}}</button>
  <button mat-button [disabled]="saveDisabled$ | async" (click)="save()">{{acceptBtnLabel | translate}}</button>
</mat-dialog-actions>
