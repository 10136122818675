import {Component, Input, OnInit} from '@angular/core';
import {DocumentService} from '../../services/document.service';
import {OrganizationReportPublicEndpointService, ReportVO} from '../../../openapi';
import {map} from 'rxjs/operators';
import {QuestionWithAnswer} from '../../../modules/annonymous/answers/model/question-with-answer';
import {InfoDialogI18nService} from '../../services/info-dialog-i18n.service';
import {TranslateFacadeService} from '../../services/translate-facade.service';

@Component({
  selector: 'app-download-question-attachments',
  templateUrl: './download-question-attachments.component.html',
  styleUrls: ['./download-question-attachments.component.scss']
})
export class DownloadQuestionAttachmentsComponent implements OnInit {

  @Input() report: ReportVO;
  @Input() currentLang: string;

  public downloading: boolean;

  constructor(
    private readonly documentService: DocumentService,
    private readonly organizationReportPublicEndpoint: OrganizationReportPublicEndpointService,
    private readonly infoDialogService: InfoDialogI18nService,
    private readonly translate: TranslateFacadeService
  ) {
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }

  downloadAttachments(): void {
    this.downloading = true;
    this.documentService.getZippedDocuments(this.report.id).subscribe(file => {
      this.download(file);
      this.downloading = false;
    }, err => {
      console.log('Attachment download error: ' + err);
      this.downloading = false;
      this.infoDialogService.error('ui.user.dashboard.download_question_attachments.error_message_something_went_wrong');
    });
  }

  isAnyAttachment = (): boolean => true;

  private download(file: File): void {
    const url = URL.createObjectURL(file);
    const element = document.createElement('a');
    element.href = url;
    element.download = file.name;
    element.click();
  }
}
