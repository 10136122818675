<div class="filter-wrapper" fxLayout="column">
  <div *ngFor="let filter of activeFilters" fxLayout="row">
    <mat-form-field class="filter-column-selector">
      <mat-label>{{ 'ui.common.table.column' | translate }}</mat-label>
      <mat-select [value]="filter.column.name" (valueChange)="filteredColumnChanged($event, filter)">
        <mat-option [value]="filter.column.name">
          {{filter.column.label}}
        </mat-option>
        <mat-option *ngFor="let availableFilter of availableFilters" [value]="availableFilter.name">
          {{availableFilter.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-value-input" *ngIf="filter.column.type == 'text'">
      <mat-label>{{ 'ui.common.table.filter' | translate }}</mat-label>
      <input matInput [ngModel]="filter.value" (ngModelChange)="filterValueChange($event, filter)"/>
    </mat-form-field>

    <mat-form-field class="filter-value-input" *ngIf="filter.column.type == 'boolean'">
      <mat-label>{{ 'ui.common.table.filter' | translate }}</mat-label>
      <mat-select [value]="filter.value" (valueChange)="filterValueChange($event, filter)">
        <mat-option [value]="true">
          {{ 'ui.common.table.filter.yes' | translate }}
        </mat-option>
        <mat-option [value]="false">
          {{ 'ui.common.table.filter.no' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-value-input" *ngIf="filter.column.type == 'comma_separated_boolean_for_filter'">
      <mat-label>{{ 'ui.common.table.filter' | translate }}</mat-label>
      <mat-select [value]="filter.value" (valueChange)="filterValueChange($event, filter)">
        <mat-option [value]="true">
          {{ 'ui.common.table.filter.yes' | translate }}
        </mat-option>
        <mat-option [value]="false">
          {{ 'ui.common.table.filter.no' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-value-input" *ngIf="filter.column.type == 'enum'">
      <mat-label>{{ 'ui.common.table.filter' | translate }}</mat-label>
      <mat-select [value]="filter.value" (valueChange)="filterValueChange($event, filter)">
        <mat-option *ngFor="let enumVal of filter.column.enumColumnValues" [value]="enumVal.value">
          {{enumVal.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="filter-value-input" *ngIf="filter.column.type == 'date_time'">
      <mat-label>{{ 'ui.common.table.filter' | translate }}</mat-label>
      <input matInput [ngModel]="filter.value" (ngModelChange)="filterValueChange($event, filter)"
             [ngxMatDatetimePicker]="picker" placeholder="Choose a date" (click)="picker.open()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [stepHour]="1"
                               [stepMinute]="5" [stepSecond]="1" [touchUi]="false"
                               [enableMeridian]="false" [disableMinute]="false" [hideTime]="false">
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-form-field class="filter-value-input" *ngIf="filter.column.type == 'date'">
      <mat-label>{{ 'ui.common.table.filter' | translate }}</mat-label>
      <input matInput [matDatepicker]="datepicker" [ngModel]="filter.value"
             (click)="datepicker.open()"
             (ngModelChange)="filterValueChange($event, filter)"/>
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker></mat-datepicker>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="removeFilter(filter)">
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>
  <button *ngIf="availableFilters.length > 0" mat-button (click)="addFilter()" color="primary">
    <mat-icon>add</mat-icon>
    {{ 'ui.common.table.addFilter' | translate }}
  </button>
</div>
