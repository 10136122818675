import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidators {

  constructor() {
  }

  phoneNumber(
    control: AbstractControl,
  ): ValidationErrors | null {
    const regExp = /^[0-9]{9,12}$/;
    return control.value && !control.value.match(regExp)
      ? {phone_number: true}
      : null;
  }

  emailAddress(control: AbstractControl): ValidationErrors | null {
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return control.value && !control.value.match(regExp)
      ? {email: true}
      : null;
  }

  postalCode(control: AbstractControl): ValidationErrors | null {
    const regExp = /^[0-9]{2}\-[0-9]{3}$/;
    return control.value && !control.value.match(regExp)
      ? {postal_code: true}
      : null;
  }
}
