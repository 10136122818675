import {Injectable} from '@angular/core';
import {
  FetchResultIndexVO,
  IndexEndpointService,
  IndexReportVO,
  IndexVO,
  OrganizationReportAnswerCompanyTypeVO,
  Question,
  SaveIndexRequest,
  UserOrganizationReportEndpointService
} from 'src/app/openapi';
import {ReportService} from './report.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Index} from '../models';
import {IndexReport} from 'src/app/modules/admin/report-metadata-settings/models/index-report';

@Injectable({ providedIn: 'root' })
export class IndexService {

  constructor(
    private readonly indexEndpointService: IndexEndpointService,
    private readonly userOrganizationReportEndpointService: UserOrganizationReportEndpointService,
    private readonly reportService: ReportService
  ) {
  }

  sortRaw = ((previousIndexNumber: string, nextIndexNumber: string): number => {
    const ax = [];
    const bx = [];

    // @ts-ignore
    previousIndexNumber.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      ax.push([$1 || Infinity, $2 || '']);
    });
    // @ts-ignore
    nextIndexNumber.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
      bx.push([$1 || Infinity, $2 || '']);
    });

    while (ax.length && bx.length) {
      const an = ax.shift();
      const bn = bx.shift();
      const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
      if (nn) {
        return nn;
      }
    }

    return ax.length - bx.length;
  });

  sort = (previousIndex: Index, nextIndex: Index): number =>
    this.sortRaw(previousIndex.number, nextIndex.number)

  fetchAnswerCompanyTypes(reportId: number): Observable<OrganizationReportAnswerCompanyTypeVO[]> {
    return this.userOrganizationReportEndpointService.fetchAnswerCompanyTypes({
      page: 0,
      pageSize: 0,
      search: {
        reportId: reportId.toString()
      }
    }).pipe(map(result => result.rows));
  }

  fetchIndicesForReport(sectionId: number, reportId: number, ignoreBelongsToReport?: boolean): Observable<IndexReport[]> {
    const search = {
      sectionId: sectionId.toString(),
      reportId: reportId.toString(),
      belongsToReport: 'true'
    };
    if (ignoreBelongsToReport) {
      delete search.belongsToReport;
    }

    return this.indexEndpointService.fetchAllIndicesReport({
      page: 0,
      pageSize: 0,
      search
    }).pipe(
      map((fetchResultIndexVO: FetchResultIndexVO) => this.transformVoToIndicesReport(fetchResultIndexVO.rows))
    );
  }

  public fetchAllIndicesAssignedToPreviousReport(section: number, report: number): Observable<IndexReport[]> {
    return this.indexEndpointService.fetchAllIndicesAssignedToPreviousReport(report, section)
      .pipe(map(result => this.transformVoToIndicesReport(result)));
  }

  fetchNonOrganizationIndicesMetadata(sectionId: number): Observable<Index[]> {
    return this.indexEndpointService.fetchAllIndices({
      page: 0,
      pageSize: 0,
      search: {
        sectionId: sectionId.toString(),
        'organizationId#NULL': ''
      }
    }).pipe(
      map((fetchResultIndexVO: FetchResultIndexVO) => this.transformVoToIndices(fetchResultIndexVO.rows))
    );
  }

  save(index: Index): Observable<void> {
    const command: SaveIndexRequest = {
      id: index.id ? index.id : undefined,
      number: index.number,
      name: index.name,
      sectionId: index.sectionId,
      isConditional: index.isConditional,
      organizationId: index.organizationId,
      variants: index.variantsIdList
        ? index.variantsIdList
        : this.reportService.reduceReportsToVariantIds(index.reports),
      pkds: index.pkdIdList,
      questions: index.questions as Question[],
      instructionFileChanged: index.instructionChanged,
      content: index.content,
      translation: index.translation
    };
    return this.indexEndpointService.saveIndex(command, index.instruction);
  }

  delete(indexId: number): Observable<void> {
    return this.indexEndpointService.deleteIndexById(indexId);
  }

  duplicate(indexId: number): Observable<void> {
    return this.indexEndpointService.duplicate(indexId);
  }

  duplicateToOtherSection(indexId: number, sectionId: number): Observable<void> {
    return this.indexEndpointService.duplicateToOtherSection({
      indexId,
      sectionId
    });
  }

  changeOrder(indices: Index[]): Observable<void> {
    const idPerOrder: { [p: string]: number } = {};
    indices.forEach((i) => {
      idPerOrder[i.id] = i.order;
    });
    return this.indexEndpointService.changeIndexOrder(idPerOrder);
  }

  private transformVoToIndicesReport(indicesVo: IndexReportVO[]): IndexReport[] {
    return indicesVo.map((indexReportVO) => {
      return {
        id: indexReportVO.id,
        number: indexReportVO.number,
        name: indexReportVO.name,
        order: indexReportVO.order,
        sectionId: indexReportVO.sectionId,
        isRecommended: indexReportVO.isRecommended,
        isRequired: indexReportVO.isRequired,
        isConditional: indexReportVO.isConditional,
        email: indexReportVO.email,
        indexOrganizationId: indexReportVO.indexOrganizationId,
        instructionId: indexReportVO.instructionId,
        answerCompanyType: indexReportVO.answerCompanyType,
        parentAnswerCompanyType: indexReportVO.parentAnswerCompanyType,
        translation: indexReportVO.indexTranslation,
        content: indexReportVO.content,
      } as IndexReport;
    });
  }

  private transformVoToIndices(indicesVo: IndexVO[]): Index[] {
    return indicesVo.map((indexVO) => {
      return {
        id: indexVO.id,
        number: indexVO.number,
        name: indexVO.name,
        order: indexVO.order,
        sectionId: indexVO.sectionId,
        isConditional: indexVO.isConditional,
        reports: this.reportService.transformVariantIdsToReports(indexVO.variants),
        pkdIdList: indexVO.pkds,
        instructionId: indexVO.instructionId,
        content: indexVO.content,
        translation: indexVO.translation
      } as Index;
    });
  }
}
