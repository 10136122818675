<div class="container">
  <div *ngIf="hasDataValidationAssigment()">
    <div *ngIf="!isOptionClicked">
      <span>{{ 'ui.anonymous.answers.data-validation.label' | translate }}</span>
      <button mat-button (click)="click(true)">
        <mat-icon color="primary">check</mat-icon>
      </button>
      <button mat-button (click)="click(false)">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </div>
    <div *ngIf="isOptionClicked && !isAnswerSend">
      <button mat-button (click)="send()">
        {{ 'ui.anonymous.answers.data-validation.send_button_label' | translate }}
      </button>
      <button mat-button (click)="returnToSelectOption()">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </div>
    <div *ngIf="isAnswerSend && isOptionClicked">
      <span>{{ 'ui.anonymous.answers.data-validation.message' | translate }}</span>
    </div>
  </div>
  <div *ngIf="isAlreadySendValidationReplay()">
    <span class=" already-send">{{ 'ui.anonymous.answers.data-validation.already_send' | translate }}</span>
  </div>
</div>
