import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SmartTableColumn, SmartTableColumnType, SmartTableDataSourceDef} from '../smart-table.api';
import {SmartTableFilter} from './smart-table-filter.api';
import {FilterChangeService} from '../../../../services/filter-change.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-smart-table-filter',
  templateUrl: './smart-table-filter.component.html',
  styleUrls: ['./smart-table-filter.component.scss']
})
export class SmartTableFilterComponent implements OnInit, OnDestroy {

  @Input()
  readonly dataSourceDef: SmartTableDataSourceDef;

  @Input()
  allFiltersOpened = false;

  activeFilters: SmartTableFilter[];
  availableFilters: SmartTableColumn[];
  originalColumnsOrderForFiltering: SmartTableColumn[];

  @Output()
  filterChanged: EventEmitter<any> = new EventEmitter<any>();

  private filterSubscription: Subscription;
  private commaSeparatedAggregateColumnMap: Map<string, string> = new Map<string, string>();
  private jsonArrayAggregateColumnMap: Map<string, string> = new Map<string, string>();

  constructor(private filterChangeService: FilterChangeService) {
  }

  ngOnInit(): void {
    this.filterSubscription = this.filterChangeService.subscribe(filterChange => {
      this.clearFilters();
      const column =
        this.availableFilters.find(availableFilter => availableFilter.name === filterChange.column);
      if (column) {
        this.availableFilters.splice(this.availableFilters.indexOf(column), 1);
        this.activeFilters.push({
          column,
          value: filterChange.value,
        });

        this.sortAvailableFilters();
        this.notifyFiltersChange();
      }
    });

    this.availableFilters = [];
    this.activeFilters = [];
    this.originalColumnsOrderForFiltering = [];


    this.dataSourceDef.columns.forEach(column => {
      if (column.type === SmartTableColumnType.AGGREGATE_BOOLEAN) {
        column.aggregatedColumns.forEach(aggregatedColumn => {
          const tempColumn = new SmartTableColumn(aggregatedColumn.name, aggregatedColumn.label, SmartTableColumnType.BOOLEAN);
          this.availableFilters.push(tempColumn);
          this.originalColumnsOrderForFiltering.push(tempColumn);
        });
      } else if (column.type === SmartTableColumnType.AGGREGATE_COMMA_SEPARATED_BOOLEAN) {
        column.aggregatedColumns.forEach(aggregatedColumn => {
          const tempColumn = new SmartTableColumn(aggregatedColumn.name, aggregatedColumn.label, SmartTableColumnType.BOOLEAN);
          this.commaSeparatedAggregateColumnMap.set(aggregatedColumn.name, column.name);
          this.availableFilters.push(tempColumn);
          this.originalColumnsOrderForFiltering.push(tempColumn);
        });
      } else if (column.type === SmartTableColumnType.AGGREGATE_FROM_JSON_ARRAY) {
        column.aggregatedJsonArrayColumns.forEach(aggregatedColumn => {
          const virtualColumnName = `${aggregatedColumn.fieldName}.${aggregatedColumn.fieldValue}`;
          const tempColumn = new SmartTableColumn(virtualColumnName, aggregatedColumn.fieldValue, SmartTableColumnType.BOOLEAN);
          this.jsonArrayAggregateColumnMap.set(virtualColumnName, column.name);
          this.availableFilters.push(tempColumn);
          this.originalColumnsOrderForFiltering.push(tempColumn);
        });
      } else if (column.type === SmartTableColumnType.DATE_TIME || column.type === SmartTableColumnType.DATE) {
        const fromColumn = new SmartTableColumn(column.name + '#FROM', column.label + ' (Od)', column.type);
        const toColumn = new SmartTableColumn(column.name + '#TO', column.label + ' (Do)', column.type);
        this.availableFilters.push(fromColumn);
        this.originalColumnsOrderForFiltering.push(fromColumn);
        this.availableFilters.push(toColumn);
        this.originalColumnsOrderForFiltering.push(toColumn);
      } else {
        this.availableFilters.push(column);
        this.originalColumnsOrderForFiltering.push(column);
      }
    });

    if (this.allFiltersOpened) {
      const filtersCount = this.availableFilters.length;
      for (let i = 0; i < filtersCount; i += 1) {
        this.addFilter();
      }
    }
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  addFilter() {
    const smartTableColumn = this.availableFilters.shift();
    this.activeFilters.push({
      column: smartTableColumn,
      value: ''
    });
  }

  removeFilter(filter: SmartTableFilter) {
    const indexOfFilter = this.activeFilters.indexOf(filter);
    this.activeFilters.splice(indexOfFilter, 1);
    this.availableFilters.push(filter.column);

    this.sortAvailableFilters();
    this.notifyFiltersChange();
  }

  filteredColumnChanged(newColumnName: string, filter: SmartTableFilter) {
    const currentColumnIndexInActiveFilters = this.activeFilters.findIndex(item => item.column.name === filter.column.name);

    this.availableFilters.push(filter.column);
    const newColumnIndex = this.availableFilters.findIndex(column => column.name === newColumnName);
    this.activeFilters[currentColumnIndexInActiveFilters].value = '';
    this.activeFilters[currentColumnIndexInActiveFilters].column = this.availableFilters[newColumnIndex];
    this.availableFilters.splice(newColumnIndex, 1);

    this.sortAvailableFilters();
    this.notifyFiltersChange();
  }

  filterValueChange($event: any, filter: SmartTableFilter) {
    filter.value = $event;
    this.notifyFiltersChange();
  }

  private clearFilters() {
    this.activeFilters.forEach(value => this.availableFilters.push(value.column));
    this.activeFilters = [];
  }

  private sortAvailableFilters() {
    this.availableFilters = this.availableFilters.sort((a, b) =>
      this.originalColumnsOrderForFiltering.indexOf(a) - this.originalColumnsOrderForFiltering.indexOf(b));
  }

  private notifyFiltersChange() {
    const filterMap: any = {};
    this.activeFilters.forEach(filter => {
      if (filter.value !== undefined && filter.value !== null && filter.value !== '') {
        if (this.commaSeparatedAggregateColumnMap.get(filter.column.name) != null) {
          const originalColumnName = this.commaSeparatedAggregateColumnMap.get(filter.column.name);
          if (filter.value) {
            filterMap[originalColumnName] = '%' + filter.column.name + '%';
          } else {
            filterMap[`${originalColumnName}#NOT`] = '%' + filter.column.name + '%';
          }
        } else if (this.jsonArrayAggregateColumnMap.get(filter.column.name) != null) {
          const originalColumnName = this.jsonArrayAggregateColumnMap.get(filter.column.name);

          let valuesArray = filterMap[originalColumnName];
          if (valuesArray == null) {
            valuesArray = [];
            filterMap[originalColumnName] = valuesArray;
          }

          if (filter.value) {
            valuesArray.push({v: filter.column.name});
          } else {
            valuesArray.push({v: filter.column.name, o: 'NOT'});
          }
        } else if (filter.column.autocomplete) {
          filterMap[filter.column.name] = filter.value + '%';
        } else if (filter.column.type === SmartTableColumnType.DATE) {
          filterMap[filter.column.name] = this.dateToDateIsoString(filter.value as Date);
        } else {
          filterMap[filter.column.name] = filter.value;
        }
      }
    });
    this.filterChanged.emit(filterMap);
  }

  private dateToDateIsoString(date: Date): string {
    const year = date.getFullYear();
    let month = '' + (date.getMonth() + 1);
    let dt = '' + date.getDate();
    if (date.getDate() < 10) {
      dt = '0' + dt;
    }
    if (date.getMonth() + 1 < 10) {
      month = '0' + month;
    }
    return `${year}-${month}-${dt}`;
  }
}
