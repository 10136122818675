import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {DatabaseAccountPublicApiEndpointService, LoginEndpointService} from '../openapi';
import {Observable} from 'rxjs';


export interface AuthenticationDTO {
  token: string;
  id: number;
  name: string;
  email: string;
  roles: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private readonly loginEndpointService: LoginEndpointService,
    private readonly databaseAccountPublicApiEndpointService: DatabaseAccountPublicApiEndpointService
    ) {
  }

  getAuthenticateData(token: string): AuthenticationDTO {
    const decodedToken: any = jwt_decode(token);
    return {
      token: `Bearer ${token}`,
      id: decodedToken.id,
      name: decodedToken.name,
      email: decodedToken.email,
      roles: decodedToken.roles,
    };
  }

  sendEmailToGetToken(email: string): Promise<boolean> {
    return new Observable<boolean>(obs => {
      this.databaseAccountPublicApiEndpointService.loginDatabaseAccount({
        email,
      }, 'response').subscribe(
        res => {
          obs.next(true);
          obs.complete();
        },
        err => {
          obs.next(false);
          obs.complete();
        }
      );
    }).toPromise();
  }

  public authenticate(username: string, password: string): Promise<AuthenticationDTO> {
    return new Observable<AuthenticationDTO>(obs => {
      this.loginEndpointService.login({
        login: username,
        password
      }, 'response').subscribe(
        res => {
          const authHeader = res.headers.get('authorization');
          const decodedToken: any = jwt_decode(authHeader);
          obs.next({
            token: authHeader,
            id: decodedToken.id,
            name: decodedToken.name,
            email: decodedToken.email,
            roles: decodedToken.roles,
          });
          obs.complete();
        },
        err => {
          obs.next(null);
          obs.complete();
        }
      );
    }).toPromise();
  }
}
