import {Component, HostBinding, Input, OnInit} from '@angular/core';

export enum InfoPanelColor {
  INFO = 'info',
  WARN = 'warn',
  ALERT = 'alert',
  GOOD = 'good',
}

export interface InfoPanelData {
  label: string;
  value: string;
  color: InfoPanelColor;
  displayAsDoughnut?: boolean;
}

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
})
export class InfoPanelComponent implements OnInit {
  @HostBinding('className') hostClass = 'host';

  @Input()
  borderType: 'shadow' | 'solid' | 'solid-tab-content' = 'shadow';

  @Input() data: InfoPanelData[];

  @Input() customCSSClassPostfix: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
