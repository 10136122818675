import {Injectable} from '@angular/core';
import {
  FetchCommand,
  FetchResultOrganizationPackageSummaryVO,
  FetchResultOrganizationUserVO,
  OrganizationEndpointService,
  OrganizationPackageEndpointService,
  OrganizationPackageStatisticsVO,
  OrganizationPackageSummaryVO,
  SendReportEmailsCommand,
  UserOrganizationReportEndpointService
} from '../../openapi';
import {Observable} from 'rxjs';
import {OrganizationPackageSummary} from '../../modules/admin/companies/models/organization-package-summary';
import {
  SmartTableColumn,
  SmartTableColumnType,
  SmartTableDataProvider,
  SmartTableDataSourceDef,
  SmartTablePageData
} from '../toolkit/smart-table/smart-table.api';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {

  organizationPackageSummary: OrganizationPackageSummaryVO;

  constructor(private organizationPackageEndpoint: OrganizationPackageEndpointService,
              private organizationEndpoint: OrganizationEndpointService,
              private userOrganizationReportEndpointService: UserOrganizationReportEndpointService) {
  }

  getOrganizationPackageSummary(fetchCommand: FetchCommand): Observable<FetchResultOrganizationPackageSummaryVO> {
    return this.organizationPackageEndpoint.fetchAllSummaries(fetchCommand).pipe(
      map((result: FetchResultOrganizationPackageSummaryVO) => {
        result.rows = result.rows.map((organizationPackageSummary: OrganizationPackageSummary) => {
          organizationPackageSummary.hasGri = organizationPackageSummary.packages.toLowerCase().includes('gri');
          organizationPackageSummary.hasUoR = organizationPackageSummary.packages.toLowerCase().includes('uor');
          organizationPackageSummary.hasGriAndUoR = organizationPackageSummary.packages.toLowerCase().includes('g+u');
          organizationPackageSummary.activeDaysLeft = Math.ceil((new Date(organizationPackageSummary.activeTo).getTime() - Date.now()) / (1000 * 3600 * 24)) + 1;
          return organizationPackageSummary;
        });
        return result;
      })
    );
  }

  getOrganizationPackageStatistics(fetchCommand: FetchCommand): Observable<OrganizationPackageStatisticsVO> {
    return this.organizationPackageEndpoint.fetchAllStatistics(fetchCommand);
  }

  deleteOrganizationById(organizationId: number): Observable<void> {
    return this.organizationEndpoint.deleteOrganizationById(organizationId);
  }

  public createReportTableDataSource(organizationId: number): SmartTableDataSourceDef {
    const service = this.organizationEndpoint;
    const dataSourceDef = new SmartTableDataSourceDef(new (class implements SmartTableDataProvider {
      fetchData(page: number, pageSize: number, filter: any, sortBy: string, sortDescending: boolean): Observable<SmartTablePageData> {
        return service.fetchOrganizationReport({
          page,
          pageSize,
          search: {...filter, organizationId},
          orderBy: sortBy,
          orderDesc: sortDescending
        }).pipe(
          map(result => {
            return {
              total: result.totalRows,
              items: result.rows
            };
          })
        );
      }

      exportData(filter: any, sortBy: string, sortDescending: boolean, dataSource: SmartTableDataSourceDef): Observable<Blob> {
        return null;
        /*return service.fetchClientUsersExcel(
          {
            pageSize: 0,
            page: 0,
            search: filter,
            orderBy: sortBy,
            orderDesc: sortDescending,
            fileName: 'excel.xlsx',
            labels: dataSource.getVisibleColumnLabels()
          }
        );*/
      }
    })());

    dataSourceDef.addColumn(new SmartTableColumn('versionValidFrom', 'Rok', SmartTableColumnType.DATE));
    dataSourceDef.addColumn(new SmartTableColumn('reportType', 'Pakiet', SmartTableColumnType.TEXT));
    dataSourceDef.addColumn(new SmartTableColumn('status', 'Status', SmartTableColumnType.ENUM, {
      enumColumnValues: [
        {
          value: 'in progress',
          label: 'W trakcie'
        },
        {
          value: 'finished',
          label: 'Sporządzono'
        },
        {
          value: '',
          label: 'Brak sporządzonego raportu'
        }
      ],
      enumColumnIconFunction: (row, value) => {
        switch (value) {
          case 'in progress':
            return 'remove';
          case 'finished':
            return 'check';
          case '':
            return 'remove';
        }
      },
      cellCustomCssClassFunction: (row, value) => {
        switch (value) {
          case 'in progress':
            return 'mat-icon-red';
          case 'finished':
            return 'mat-icon-primary';
          case '':
            return 'mat-icon-red';
        }
      }
    }));
    dataSourceDef.addColumn(new SmartTableColumn('finishedAt', 'Sporządzono', SmartTableColumnType.DATE));
    dataSourceDef.addColumn(new SmartTableColumn('isGdprAccepted', 'Zgoda', SmartTableColumnType.BOOLEAN));
    return dataSourceDef;
  }

  public createOrganizationSettlementsTableDataSource(organizationId: number): SmartTableDataSourceDef {
    const service = this.organizationPackageEndpoint;
    const dataSourceDef = new SmartTableDataSourceDef(new (class implements SmartTableDataProvider {
      fetchData(page: number, pageSize: number, filter: any, sortBy: string, sortDescending: boolean): Observable<SmartTablePageData> {
        return service.fetchAllOrganizationPackages({
          page,
          pageSize,
          search: {...filter, organizationId},
          orderBy: sortBy,
          orderDesc: sortDescending
        }).pipe(
          map(result => {
            return {
              total: result.totalRows,
              items: result.rows
            };
          })
        );
      }

      exportData(filter: any, sortBy: string, sortDescending: boolean, dataSource: SmartTableDataSourceDef): Observable<Blob> {
        return null;
        /*return service.fetchClientUsersExcel(
          {
            pageSize: 0,
            page: 0,
            search: filter,
            orderBy: sortBy,
            orderDesc: sortDescending,
            fileName: 'excel.xlsx',
            labels: dataSource.getVisibleColumnLabels()
          }
        );*/
      }
    })());

    dataSourceDef.addColumn(new SmartTableColumn('buyDate', 'Data', SmartTableColumnType.DATE));
    dataSourceDef.addColumn(new SmartTableColumn('packageName', 'Pakiet', SmartTableColumnType.TEXT));
    dataSourceDef.addColumn(new SmartTableColumn('priceNet', 'Netto', SmartTableColumnType.TEXT));
    dataSourceDef.addColumn(new SmartTableColumn('vat', 'VAT', SmartTableColumnType.TEXT));
    dataSourceDef.addColumn(new SmartTableColumn('priceGross', 'Brutto', SmartTableColumnType.TEXT));
    dataSourceDef.addColumn(new SmartTableColumn('status', 'Status', SmartTableColumnType.ENUM, {
      enumColumnValues: [
        {
          value: 'paid',
          label: 'Opłacone'
        },
        {
          value: 'unpaid',
          label: 'Nieopłacone'
        }
      ],
      enumColumnIconFunction: (row, value) => {
        switch (value) {
          case 'paid':
            return 'check';
          case 'unpaid':
            return 'clear';
          case 'expired':
            return 'blocked';
        }
      },
      cellCustomCssClassFunction: (row, value) => {
        switch (value) {
          case 'paid':
            return 'mat-icon-primary';
          case 'unpaid':
            return 'mat-icon-red';
          case 'expired':
            return 'status-column';
        }
      }
    }));
    return dataSourceDef;
  }

  sendIndicesViaEmail(command: SendReportEmailsCommand): Observable<void> {
    return this.userOrganizationReportEndpointService.requestEmailSend(command);
  }
}
