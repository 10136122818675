<div class="status-pill">
  <div [ngClass]="{
      'status-pill-wrapper': true,
      'status-pill-size-big': styleBig,
      'status-pill-standout': style === 'standout',
      'status-pill-good': style === 'good',
      'status-pill-alert': style === 'alert',
      'status-pill-info': style === 'info',
      'status-pill-good-selected': style === 'good' && selected,
      'status-pill-alert-selected': style === 'alert' && selected,
      'status-pill-info-selected': style === 'info' && selected
    }">
    <mat-icon *ngIf="icon !== undefined" [ngClass]="{
      'icon': true,
      'status-pill-icon': true,
      'status-pill-icon-good': style === 'good',
      'status-pill-icon-alert': style === 'alert',
      'status-pill-icon-info': style === 'info',
      'status-pill-icon-good-selected': style === 'good' && selected,
      'status-pill-icon-alert-selected': style === 'alert' && selected,
      'status-pill-icon-info-selected': style === 'info' && selected
    }">{{icon}}</mat-icon>
    <div class="status-pill-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
