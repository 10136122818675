export const SUCCESS_SAVE_DIALOG_CONFIGURATION = {
  data: {
    title: 'Udało się!',
    message: `Odpowiedzi zostały zapisane.`,
    onlyOkOption: true,
    icon: 'check_circle_outline',
    iconColor: 'success'
  },
  width: '300px'
};

export const FAILED_SAVE_DIALOG_CONFIGURATION = {
  data: {
    title: 'Wystąpił błąd!',
    message: `Nie udało się zapisać odpowiedzi. Wystąpił nieoczekiwany błąd`,
    onlyOkOption: true,
    icon: 'error_outline',
    iconColor: 'error'
  },
  width: '300px'
};

export const YOU_HAVE_TO_ANSWER_ALL_CHECKLIST_QUESTIONS = {
  data: {
    title: 'Wystąpił błąd!',
    message: `Nie udało się zapisać odpowiedzi. Nie uzupełniłeś wymaganych pytań!`,
    onlyOkOption: true,
    icon: 'error_outline',
    iconColor: 'error'
  },
  width: '300px'
};
