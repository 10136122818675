<app-answers-form-page-layout>
  <div class="greetings-header">{{ 'ui.anonymous.answers.greetings.header' | translate }}</div>
  <app-answer-proposal *ngIf="globalProposal && indices"
                       class="greetings-text"
                       [isGlobal]="true"
                       (action)="globalProposalOnChange($event)"></app-answer-proposal>
  <div class="greetings-and-data-validation-wrapper">
    <div>
      <div class="greetings-text">{{ 'ui.anonymous.answers.greetings.organizationString' | translate }} {{organizationName}}</div>
    </div>
    <app-reviewer-data-validation-field *ngIf="isReviewer()" [reviewer]="reviewer" [token]="token">
    </app-reviewer-data-validation-field>
  </div>
  <mat-card>
    <div class="header-row">
      <div class="list-header">{{ 'ui.anonymous.answers.list.header' | translate }}</div>
      <div class="list-legend">
        <span class="incomplete-exclamation-mark">!&nbsp;</span>- {{ 'ui.anonymous.answers.list.legend.incomplete' | translate }}
      </div>
    </div>

    <app-grouped-index-forms *ngIf="indices"
                             [isReviewer]="isReviewer()"
                             [blankGroupI18NCode]="''"
                             [chosenSectionToken]="token"
                             [indices]="indices"
                             [reportId]="reportId"
    >
    </app-grouped-index-forms>

    <div (click)="saveAnswers()" class="save-button" matRipple> {{ 'ui.anonymous.answers.save' | translate }}
      <mat-spinner *ngIf="isSaving" [diameter]="20"></mat-spinner>
    </div>
  </mat-card>
</app-answers-form-page-layout>
