import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatChip, MatChipList} from '@angular/material/chips';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-chips-multi-select',
  templateUrl: './chips-multi-select.component.html',
  styleUrls: ['./chips-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChipsMultiSelectComponent,
      multi: true,
    },
  ],
})
export class ChipsMultiSelectComponent<T extends { name: string }>
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @ViewChild(MatChipList)
  chipList!: MatChipList;

  @Input() selected: T[];
  @Input() options: T[] = [];


  onChange!: (value: T[]) => void;
  onTouch: any;

  disabled = false;

  subscriptions: Subscription = new Subscription();

  constructor() {}

  writeValue(value: T[]): void {
    // When form value set when chips list initialized
    if (this.chipList && value) {
      this.selectChips(value);
    } else if (value) {
      // When chips not initialized
      this.selected = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.selectChips(this.selected);

    this.subscriptions.add(this.chipList.chipSelectionChanges
      .pipe(
        map((event) => event.source)
      )
      .subscribe((chip) => {
        if (chip.selected) {
          this.selected.push(chip.value);
        } else {
          const indexOfItem = this.selected.findIndex(item => item.name === chip.value.name);
          this.selected.splice(indexOfItem, 1);
        }

        this.propagateChange(this.selected);
      }));
  }

  propagateChange(value: T[]) {
    if (this.onChange) {
      this.onChange(value);
    }
  }

  selectChips(value: T[]) {
    this.chipList.chips.forEach((chip) => chip.deselect());

    const chipsToSelect = this.chipList.chips.filter((c) =>
      value.findIndex(item => item.name === c.value.name) !== -1
    );

    chipsToSelect.forEach((chip) => chip.select());
  }

  toggleSelection(chip: MatChip) {
    if (!this.disabled) { chip.toggleSelected(); }
  }
}
