import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularMaterialModule} from './angular-material.module';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ApiModule, Configuration} from './openapi';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgxsModule} from '@ngxs/store';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {AuthState} from './state/auth.state';
import {TokenInterceptor} from './shared/interceptors/token.interceptor';
import {environment} from '../environments/environment';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {NGX_MAT_DATE_FORMATS, NgxMatDateFormats} from '@angular-material-components/datetime-picker';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ToolkitModule} from './shared/toolkit/toolkit.module';
import {SharedComponentsModule} from './shared/components/shared-components.module';
import {LayoutComponent} from './shared/components/layout/layout.component';
import {LeftMenuComponent} from './shared/components/left-menu/left-menu.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslationService} from "./shared/services/translation.service";

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LT'
  },
  display: {
    dateInput: 'l, LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LeftMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    ApiModule.forRoot(() => new Configuration({basePath: environment.baseServerUrl})),
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forRoot([AuthState], {developmentMode: !environment.production}),
    NgxsStoragePluginModule.forRoot({key: AuthState}),
    NgxsLoggerPluginModule.forRoot({disabled: environment.production}),
    NgxsRouterPluginModule.forRoot(),
    NgxChartsModule,
    ToolkitModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader, useClass: TranslationService
      },
      useDefaultLang: true
    }),
    SharedComponentsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {provide: MAT_DATE_LOCALE, useValue: 'pl'},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
