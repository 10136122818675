import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Chart } from 'angular-highcharts';
import * as fileSaver from 'file-saver';
import { SeriesPieOptions } from 'highcharts';
import {
  SectionedResponse
} from '../../../modules/user/dashboard/components/status-dashboard/status-dashboard.service';
import { TranslateFacadeService } from '../../services/translate-facade.service';
import {
  AspectChartSmartTableModalComponent,
  AspectChartSmartTableModalInputData
} from './aspect-chart-smart-table-modal.component';

enum ChartType {
  COLUMN, PIE
}

@Component({
  selector: 'app-aspekt-chart',
  template: `
    <div>
      <div class="buttons-container">
        <button mat-icon-button (click)="changeChartType()">
          <mat-icon>{{ provideChartIcon() }}</mat-icon>
        </button>
        <button mat-icon-button (click)="exportToSvg()">
          <mat-icon>image</mat-icon>
        </button>
        <button mat-icon-button (click)="openSmartTableData()">
          <mat-icon>format_list_bulleted</mat-icon>
        </button>
      </div>
      <div #chartContainer [chart]="chart">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [`
    mat-icon {
      font-size: 20px;
      color: #9bd1bf;
      transition: 0.3s ease-in-out;
    }

    mat-icon:hover {
      color: #52575C;
    }

    .buttons-container {
      margin: 5px;
    }
  `]
})
export class AspektChartComponent implements OnInit {

  readonly statusColors = {
    // tslint:disable-next-line:object-literal-key-quotes
    'ready': '#53a688',
    'not-filled': '#9bd1bf',
    'not-send': '#a0a0a0',
    'not-assigned': '#52575C'
  };

  @Input() response!: SectionedResponse;
  @ViewChild('chartContainer') chartContainer!: ElementRef;

  loading = true;
  downloading = false;

  chart!: Chart;

  private type: ChartType = ChartType.COLUMN;
  private translations: Map<string, string> = null;

  constructor(private readonly translationService: TranslateFacadeService, private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;

    this.translationService.translationsOnInit().subscribe(t => {
      const translations = new Map<string, string>();
      translations.set('ready', t['ui.user.dashboard.default-report.status-section.status.ready'] ?? 'Uzupełnione');
      translations.set('not-filled', t['ui.user.dashboard.default-report.status-section.status.not-filled'] ?? 'Brak odpowiedzi');
      translations.set('not-send', t['ui.user.dashboard.default-report.status-section.status.not-send'] ?? 'Nie wysłano');
      translations.set('not-assigned', t['ui.user.dashboard.default-report.status-section.status.not-assigned'] ?? 'Nie przypisano');
      translations.set('filled', t['ui.user.dashboard.default-report.status-section.status.filled'] ?? 'Wypełniono');
      translations.set('x-axis-title', t['ui.user.dashboard.default-report.status-section.status.x-axis-title'] ?? 'Ilość wskaźników w poszczególnym statusie');
      translations.set('y-axis-title', t['ui.user.dashboard.default-report.status-section.status.y-axis-title'] ?? 'Liczba wskaźników');
      this.translations = translations;

      this.changeChartType();
      this.loading = false;
    });
  }

  changeChartType(): void {
    this.type = this.type === ChartType.COLUMN ? ChartType.PIE : ChartType.COLUMN;
    this.buildChart(this.response, this.type);
  }

  exportToSvg(): void {
    this.downloading = true;
    const chartElement = this.chartContainer.nativeElement.cloneNode(true);
    const svg = chartElement.querySelector('svg').outerHTML;
    const blob = new Blob([svg], {type: 'image/svg+xml'});
    fileSaver.saveAs(blob, 'wykres.svg');
    this.downloading = false;
  }

  provideChartIcon(): 'bar_chart' | 'pie_chart' {
    return this.type === ChartType.COLUMN ? 'bar_chart' : 'pie_chart';
  }

  openSmartTableData(): void {
    const data: AspectChartSmartTableModalInputData = {response: this.response};
    this.dialog.open(AspectChartSmartTableModalComponent, {data});
  }

  private buildChart(response: SectionedResponse, type: ChartType): void {
    this.loading = true;

    this.chart = type === ChartType.COLUMN ? this.buildPieChart(response) : this.buildColumnChart(response);

    this.loading = false;
  }

  private buildColumnChart(response: SectionedResponse): Chart {
    const {data, records} = this.extractChartData(response);

    const series = [];
    data.forEach((count: number, status: string) => {
      const record = {
        name: this.translations.get(status) ?? 'xxx',
        type: 'column',
        data: [{
          name: this.translations.get(status) ?? 'xxx',
          y: count
        }],
        color: this.statusColors[status] ?? '#505050'
      };
      series.push(record);
    });

    return new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: `${response.sectionName}`
      },
      subtitle: {
        text: `${this.translations.get('filled')} ${records}/${response.answers.length}`
      },
      xAxis: {
        title: {
          text: `${this.translations.get('x-axis-title')}`
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: `${this.translations.get('y-axis-title')}`
        }
      },
      tooltip: {
        valueSuffix: ''
      },
      series
    });
  }

  private buildPieChart(response: SectionedResponse): Chart {
    const {data, records} = this.extractChartData(response);

    const chartData: { name: string, y: number }[] = [];
    data.forEach((count: number, status: string) => {
      const record = {
        name: this.translations.get(status) ?? 'xxx',
        y: count
      };
      chartData.push(record);
    });

    const series: SeriesPieOptions = {
      type: 'pie',
      name: this.translations.get('y-axis-title'),
      data: chartData
    };

    return new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: `${response.sectionName}`
      },
      subtitle: {
        text: `${this.translations.get('filled')} ${records}/${response.answers.length}`
      },
      tooltip: {
        valueSuffix: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true
          },
          showInLegend: true
        }
      },
      series: [series]
    });
  }

  private extractChartData(response: SectionedResponse): {
    data: Map<string, number>, records: number
  } {
    const data: Map<string, number> = new Map<string, number>();
    response.answers.forEach(({status}) => {
      const count = data.get(status) ?? 0;
      data.set(status, count + 1);
    });

    let records = 0;
    data.forEach((value, status) => {
      if (['ready'].includes(status)) {
        records += value;
      }
    });

    return {data, records};
  }
}
