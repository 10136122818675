/**
 * SReport API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationVO { 
    id?: number;
    name?: string;
    taxNumber?: string;
    krs?: string;
    regon?: string;
    street?: string;
    buildingNumber?: string;
    apartmentNumber?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    isWithinEu?: boolean;
    isNonFinancialReportingObligated?: boolean;
    companySize?: OrganizationVO.CompanySizeEnum;
    companyType?: OrganizationVO.CompanyTypeEnum;
    email?: string;
}
export namespace OrganizationVO {
    export type CompanySizeEnum = 'MICRO' | 'SMALL' | 'MEDIUM' | 'LARGE';
    export const CompanySizeEnum = {
        MICRO: 'MICRO' as CompanySizeEnum,
        SMALL: 'SMALL' as CompanySizeEnum,
        MEDIUM: 'MEDIUM' as CompanySizeEnum,
        LARGE: 'LARGE' as CompanySizeEnum
    };
    export type CompanyTypeEnum = 'FINANCIAL' | 'NON_FINANCIAL';
    export const CompanyTypeEnum = {
        FINANCIAL: 'FINANCIAL' as CompanyTypeEnum,
        NONFINANCIAL: 'NON_FINANCIAL' as CompanyTypeEnum
    };
}


