import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {ComponentPortal} from '@angular/cdk/portal';

export interface SidebarEvents {
  name: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class SidebarService implements OnDestroy {
  private opened = false;
  private showSidebarToggleBtn = false;
  private showSidebarToggleBtnState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.showSidebarToggleBtn);
  private openedState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.opened);
  private sidebarPortalComponent: Subject<any> = new Subject<any>();
  private dataSubject: ReplaySubject<{ data: any; name: string }> = new ReplaySubject<{ data: any; name: string }>(3);
  private subscriptions: Subscription = new Subscription();
  private readonly sidebarEventsEmitter: EventEmitter<SidebarEvents> = new EventEmitter<SidebarEvents>();

  public $opened = this.openedState.asObservable().pipe(
    distinctUntilChanged()
  );

  public $showSidebarToggleBtn = this.showSidebarToggleBtnState.asObservable().pipe(
    distinctUntilChanged()
  );

  public $sidebarPortalComponent = this.sidebarPortalComponent.asObservable();

  public $data = this.dataSubject.asObservable();

  constructor(private router: Router) {
    this.subscriptions.add(this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      distinctUntilChanged(),
    ).subscribe(bc => this.close()));
  }

  public toggle() {
    this.opened = !this.opened;
    this.openedState.next(this.opened);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private close() {
    this.opened = false;
    this.showSidebarToggleBtn = false;
    this.openedState.next(this.opened);
    this.showSidebarToggleBtnState.next(this.showSidebarToggleBtn);
    this.sidebarPortalComponent.next(undefined);
  }

  pageWantSidebar(componentPortal: ComponentPortal<any>) {
    this.opened = false;
    this.showSidebarToggleBtn = true;
    this.openedState.next(this.opened);
    this.showSidebarToggleBtnState.next(this.showSidebarToggleBtn);
    this.sidebarPortalComponent.next(componentPortal);
  }

  emitData(data: { data: any; name: string }) {
    this.dataSubject.next(data);
  }

  emitSidebarEvent(event: SidebarEvents) {
    this.sidebarEventsEmitter.emit(event);
  }

  getSidebarEvents(): Observable<SidebarEvents> {
    return this.sidebarEventsEmitter.asObservable();
  }
}
