import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularMaterialModule} from '../../angular-material.module';
import {FlexLayoutModule} from '@angular/flex-layout';

import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {HeaderComponent} from './header/header.component';
import {SmartTableComponent} from './smart-table/smart-table.component';
import {SmartTableActionBarComponent} from './smart-table/smart-table-action-bar/smart-table-action-bar.component';
import {SmartFormComponent} from './smart-form/smart-form.component';
import {SmartFormTextComponent} from './smart-form/atoms/smart-form-text.component';
import {SmartFormEmailComponent} from './smart-form/atoms/smart-form-email.component';
import {SmartFormDropdownComponent} from './smart-form/atoms/smart-form-dropdown.component';
import {SmartFormHiddenComponent} from './smart-form/atoms/smart-form-hidden.component';
import {SmartFormI18NDialogComponent} from './smart-form-dialog/smart-form-i18-n-dialog.component';
import {SmartTableFilterComponent} from './smart-table/smart-table-filter/smart-table-filter.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {InfoPanelComponent} from './info-panel/info-panel.component';
import {PanelComponent} from './panel/panel.component';
import {SmartFormFileComponent} from './smart-form/atoms/smart-form-file.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {NumericEditableComponent} from './editable/numeric-editable.component';
import {SmartFormNumberComponent} from './smart-form/atoms/smart-form-number.component';
import {RouterModule} from '@angular/router';
import {SortIndicatorComponent} from './sort-indicator/sort-indicator.component';
import {StatusPillComponent} from './status-pill/status-pill.component';
import {ToggleButtonsComponent} from './toggle-buttons/toggle-buttons.component';
import {InfoRowsComponent} from './big-info-panel/info-rows.component';
import {DisplayAsChipListPipe} from '../pipes/pipes/display-as-chip-list.pipe';
import {MultiselectWithChipsComponent} from 'src/app/shared/toolkit/multiselect-with-chips/multiselect-with-chips.component';
import {PieChartModule} from '@swimlane/ngx-charts';
import {PieChartPercentageComponent} from './pie-chart-percentage/pie-chart-percentage.component';
import {ChipsMultiSelectComponent} from './chips-multi-select/chips-multi-select.component';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmDialogI18nComponent} from './confirm-dialog-i18n/confirm-dialog-i18n.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        FlexLayoutModule,
        RouterModule,
        PieChartModule,
        TranslateModule
    ],
  declarations: [
    BreadcrumbsComponent,
    HeaderComponent,
    SmartTableComponent,
    SmartTableActionBarComponent,
    SmartFormComponent,
    SmartFormTextComponent,
    SmartFormEmailComponent,
    SmartFormDropdownComponent,
    SmartFormI18NDialogComponent,
    SmartFormFileComponent,
    SmartFormNumberComponent,
    SmartFormHiddenComponent,
    SmartTableFilterComponent,
    SidebarComponent,
    InfoPanelComponent,
    PanelComponent,
    ConfirmDialogComponent,
    ConfirmDialogI18nComponent,
    NumericEditableComponent,
    SortIndicatorComponent,
    StatusPillComponent,
    ToggleButtonsComponent,
    InfoRowsComponent,
    DisplayAsChipListPipe,
    MultiselectWithChipsComponent,
    PieChartPercentageComponent,
    ChipsMultiSelectComponent,
  ],
  exports: [
    BreadcrumbsComponent,
    HeaderComponent,
    SmartTableComponent,
    SmartTableActionBarComponent,
    SmartFormComponent,
    SmartFormTextComponent,
    SmartFormEmailComponent,
    SmartFormDropdownComponent,
    SmartFormI18NDialogComponent,
    SmartFormFileComponent,
    SmartFormNumberComponent,
    SmartFormHiddenComponent,
    SmartTableFilterComponent,
    SidebarComponent,
    InfoPanelComponent,
    PanelComponent,
    ConfirmDialogComponent,
    NumericEditableComponent,
    SortIndicatorComponent,
    StatusPillComponent,
    ToggleButtonsComponent,
    InfoRowsComponent,
    MultiselectWithChipsComponent,
    ChipsMultiSelectComponent,
  ]
})

export class ToolkitModule {
}
