<app-panel [borderType]="borderType" [customCSSClassPostfix]="customCSSClassPostfix">
  <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
    <div class="info-panel-item" *ngFor="let item of data" fxLayout="column" fxLayoutAlign="center center" fxFlex
         fxLayoutGap="1.5rem">
      <div class="info-panel-label">{{item.label}}</div>
      <div *ngIf="!item.displayAsDoughnut" class="info-panel-value">
        <span [className]="item.color">{{item.value}}</span>
      </div>
      <app-pie-chart-percentage *ngIf="item.displayAsDoughnut" [value]="item.value" [className]="item.color"></app-pie-chart-percentage>
    </div>
  </div>
</app-panel>

