import {Component, Input, OnInit} from '@angular/core';
import {IndexFormData} from 'src/app/modules/annonymous/answers/model/index-form-data';
import {QuestionContentJson} from 'src/app/modules/admin/report-metadata-settings/models/question-content-json';
import {CompleteQuestionChecker} from 'src/app/modules/annonymous/answers/components/complete-question-checker.service';
import {DocumentService} from 'src/app/shared/services/document.service';
import {TranslateFacadeService} from '../../../../../shared/services/translate-facade.service';
import {ProposalAction} from '../../model/answer-proposal.model';
import {ProposalService} from '../../proposal.service';
import {first, map} from 'rxjs/operators';
import {
  ReportTopicAssigmentService
} from '../../../../user/csrd-report/components/csrd-dashboard/report-topic-assigment.service';
import {AnswerPerType} from '../../model/answer-per-type';

@Component({
  selector: 'app-index-form',
  templateUrl: './index-form.component.html',
  styleUrls: ['./index-form.component.scss'],
  providers: [CompleteQuestionChecker]
})
export class IndexFormComponent implements OnInit {

  @Input() reportId: number;
  @Input() isReviewer: boolean;
  @Input() data: IndexFormData;
  @Input() token: string;
  @Input() disabled: boolean;
  contents: QuestionContentJson[];
  proposalsAction: ProposalAction;
  expanded: boolean;
  complete: boolean;
  isAnyProposal: boolean;
  topicIds: number[];

  currentLang: string;

  constructor(
    private completePipe: CompleteQuestionChecker,
    private documentService: DocumentService,
    private readonly translate: TranslateFacadeService,
    private proposalService: ProposalService,
    private reportAssignmentService: ReportTopicAssigmentService
  ) {
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(langChangeEvent => this.currentLang = langChangeEvent.lang);
    this.contents = this.data.questions?.map(q => q.questionContent);
    this.checkIfComplete();

    const topics = this.data.indexContent?.topicIds;
    if (topics) {
      this.reportAssignmentService.getTopicAssignmentsByReportId(this.reportId).pipe(
        map(assignments => assignments
          .map(assignment => assignment.topicId)
          .filter(topicId => topics.includes(topicId)))
      ).subscribe(topicIds => this.topicIds = topicIds);
    }
    this.isAnyProposal = this.proposalService.isAnyProposal;
    this.proposalService.fetchGlobalAction().pipe(first()).subscribe((action) => {
      this.proposalsAction = action;
      this.isAnyProposal = false;
    });
  }

  checkIfComplete(): void {
    this.complete = this.completePipe.isAllComplete(this.data.questions);
    if (this.data.taxonomyCategory === 'IMPORTANT_CONTRIBUTION' && this.data.excludingIndex) {
      this.data.excludingIndex.excluded = this.isIndexFulfilled();
      if (this.data.excludingIndex.excluded) {
        this.data.excludingIndex.questions.forEach(question => {
          const {notApplicable, noCriteria, yes, no} = question.questionContent.answerTypes.TRUTH;
          const isNotApplicable = notApplicable && noCriteria && !yes && !no;
          let truthValue = 'yes';
          if (isNotApplicable) {
            truthValue = 'notApplicable';
          } else if (notApplicable) {
            truthValue = 'notApplicable';
          } else if (no) {
            truthValue = 'no';
          }
          if (this.topicIds) {
            this.topicIds.forEach(topicId => {
              question.answerContent.answer.TOPICS[topicId] = {TRUTH: {value: truthValue}} as AnswerPerType;
            });
            question.answerContent.customMetricName = this.data.customMetricName;
          } else {
            question.answerContent = {
              answer: {TRUTH: {value: truthValue}} as AnswerPerType,
              customMetricName: this.data.customMetricName
            };
          }
        });
      }
    }
  }

  showInstruction() {
    this.documentService.getDocumentFileUsingToken(this.data.indexInstructionId, this.token)
      .subscribe(file => window.open(URL.createObjectURL(file), '_blank'));
  }

  translateIndexName() {
    if (this.data.indexTranslation && this.data.indexTranslation[this.currentLang]?.name) {
      return this.data.indexTranslation[this.currentLang].name;
    }
    return this.data.indexName;
  }

  expand() {
    if (this.isAnyProposal) {
      return;
    }
    this.expanded = !this.expanded;
  }

  private isIndexFulfilled(): boolean {
    if (this.data.taxonomyJoinType) {
      return this.fulfilledAnswersCount() > 0;
    } else {
      return this.fulfilledAnswersCount() === this.data.questions.length;
    }
  }

  private fulfilledAnswersCount(): number {
    return this.data.questions.filter(question => {
      const usersAnswer = question.answerContent?.answer?.TRUTH?.value;
      return question.questionContent?.answerTypes?.TRUTH[usersAnswer] === true;
    }).length;
  }

}
