<div class="smart-table" *ngIf="dataSourceDef != null">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <app-smart-table-action-bar *ngIf="!hideCommandPanel"
                              [dataSourceDef]="dataSourceDef"
                              [tableHasData]="dataSource.length != 0"
                              [rightBarButtons]="rightBarButtons"
                              [hasExcelExport]="hasExcelExport"
                              [allFiltersOpened]="allFiltersOpened"
                              (filterChanged)="onFilterChanged($event)"></app-smart-table-action-bar>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear matSortDirection="asc"
           matSortActive="{{dataSourceDef?.columns[0]?.name}}">
      <ng-container *ngFor="let columnDef of dataSourceDef.columns">
        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'text'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{columnDef.label}}</th>
          <ng-container [ngSwitch]="clickableColumns.includes(columnDef.name)">
            <ng-container *ngSwitchCase="true">
              <td mat-cell *matCellDef="let row">
                <button mat-button color="primary"
                        (click)="onColumnClicked(columnDef.name, row)">{{row[columnDef.name]}}
                  <ng-container
                    *ngIf="columnDef.textColumnValuePostfix != null">{{columnDef.textColumnValuePostfix}}</ng-container>
                </button>
              </td>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <td mat-cell *matCellDef="let row">
                <span
                  class="{{columnDef.cellCustomCssClassFunction != null ? columnDef.cellCustomCssClassFunction(row, row[columnDef.name]) : ''}}"
                  [style.color]="columnDef.colorCodingFunction != null ? columnDef.colorCodingFunction(row[columnDef.name]) : (columnDef.globalColorCodingFunction != null ? columnDef.globalColorCodingFunction(row) : '')">{{columnDef.overrideDisplayedValueFunction != null ? columnDef.overrideDisplayedValueFunction(row, row[columnDef.name]) : row[columnDef.name]}}
                  <ng-container
                    *ngIf="columnDef.textColumnValuePostfix != null">{{columnDef.textColumnValuePostfix}}</ng-container>
                </span>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'date_time'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{columnDef.label}}</th>
          <ng-container [ngSwitch]="clickableColumns.includes(columnDef.name)">
            <ng-container *ngSwitchCase="true">
              <td mat-cell *matCellDef="let row">
                <button mat-button color="primary"
                        (click)="onColumnClicked(columnDef.name, row)">{{formatDateTime(row[columnDef.name])}}</button>
              </td>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <td mat-cell *matCellDef="let row">
                <span
                  class="{{columnDef.cellCustomCssClassFunction != null ? columnDef.cellCustomCssClassFunction(row, row[columnDef.name]) : ''}}"
                  [style.color]="columnDef.colorCodingFunction != null ? columnDef.colorCodingFunction(row[columnDef.name]) : (columnDef.globalColorCodingFunction != null ? columnDef.globalColorCodingFunction(row) : '')">{{formatDateTime(row[columnDef.name])}}
                </span>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'date'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{columnDef.label}}</th>
          <ng-container [ngSwitch]="clickableColumns.includes(columnDef.name)">
            <ng-container *ngSwitchCase="true">
              <td mat-cell *matCellDef="let row">
                <button mat-button color="primary"
                        (click)="onColumnClicked(columnDef.name, row)">{{formatDate(row[columnDef.name])}}</button>
              </td>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <td mat-cell *matCellDef="let row">
                <span
                  class="{{columnDef.cellCustomCssClassFunction != null ? columnDef.cellCustomCssClassFunction(row, row[columnDef.name]) : ''}}"
                  [style.color]="columnDef.colorCodingFunction != null ? columnDef.colorCodingFunction(row[columnDef.name]) : (columnDef.globalColorCodingFunction != null ? columnDef.globalColorCodingFunction(row) : '')">{{formatDate(row[columnDef.name])}}
                </span>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'boolean'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>{{columnDef.label}}</th>
          <td mat-cell *matCellDef="let row">
            <mat-icon>{{row[columnDef.name] === true ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'aggregate_boolean'">
          <th mat-header-cell *matHeaderCellDef>{{columnDef.label}}</th>
          <td mat-cell *matCellDef="let row">
            <mat-chip-list class="no-wrap">
              <div class="chip-wrapper">
                <mat-chip *ngFor="let aggregatedColumn of columnDef.aggregatedColumns"
                          [selected]="row[aggregatedColumn.name]" class="smart-table-chip">{{aggregatedColumn.label}}
                </mat-chip>
              </div>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'aggregate_comma_separated_boolean'">
          <th mat-header-cell *matHeaderCellDef>{{columnDef.label}}</th>
          <td mat-cell *matCellDef="let row">
            <mat-chip-list class="no-wrap">
              <div class="chip-wrapper">
                <mat-chip *ngFor="let aggregatedColumn of columnDef.aggregatedColumns"
                          [selected]="isCommaSeparatedAggregateColumnSelected(row[columnDef.name], aggregatedColumn)"
                          class="smart-table-chip">{{aggregatedColumn.label}}
                </mat-chip>
              </div>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'aggregate_from_json_array'">
          <th mat-header-cell *matHeaderCellDef>{{columnDef.label}}</th>
          <td mat-cell *matCellDef="let row">
            <mat-chip-list class="no-wrap">
              <div class="chip-wrapper">
                <mat-chip *ngFor="let aggregatedColumn of columnDef.aggregatedJsonArrayColumns"
                          [selected]="isJsonArrayAggregateColumnSelected(row[columnDef.name], aggregatedColumn)"
                          class="smart-table-chip">{{aggregatedColumn.label}}
                </mat-chip>
              </div>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="columnDef.name" *ngIf="columnDef.type === 'enum'">
          <th mat-header-cell *matHeaderCellDef>{{columnDef.label}}</th>
          <td mat-cell *matCellDef="let row">
            <span
              class="{{columnDef.cellCustomCssClassFunction != null ? columnDef.cellCustomCssClassFunction(row, row[columnDef.name]) : ''}}"
              fxLayout="row inline" fxLayoutAlign="center center" fxLayoutGap="1rem"
              [style.color]="columnDef.colorCodingFunction != null ? columnDef.colorCodingFunction(row[columnDef.name]) : (columnDef.globalColorCodingFunction != null ? columnDef.globalColorCodingFunction(row) : '')">
              <mat-icon
                *ngIf="columnDef.enumColumnIconFunction != null">{{columnDef.enumColumnIconFunction(row, row[columnDef.name])}}</mat-icon>
              <span>{{getEnumColumnLabel(row[columnDef.name], columnDef.enumColumnValues)}}</span>
            </span>
          </td>
        </ng-container>
      </ng-container>

      <ng-container [matColumnDef]="'rowActionButtonsColumn'" *ngIf="actionButtons.length != 0" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngFor="let actionButton of actionButtons">
            <ng-container *ngIf="actionButton.isVisible != null ? actionButton.isVisible(row) : true">
              <button *ngIf="actionButton.icon != null && actionButton.label == null" mat-icon-button
                      [class]="actionButton.customClass != null ? actionButton.customClass : ''"
                      matTooltip="{{actionButton.tooltip}}" color="{{actionButton.style}}"
                      (click)="callActionHandler(actionButton, row)">
                <mat-icon color="{{actionButton.style}}">{{actionButton.icon}}</mat-icon>
              </button>

              <button *ngIf="actionButton.icon != null && actionButton.label != null" mat-button
                      [class]="actionButton.customClass != null ? actionButton.customClass : ''"
                      matTooltip="{{actionButton.tooltip}}" color="{{actionButton.style}}"
                      (click)="callActionHandler(actionButton, row)">
                <mat-icon>{{actionButton.icon}}</mat-icon>
                {{actionButton.label}}
              </button>

              <button mat-button *ngIf="actionButton.icon == null"
                      [class]="actionButton.customClass != null ? actionButton.customClass : ''"
                      matTooltip="{{actionButton.tooltip}}" color="{{actionButton.style}}"
                      (click)="callActionHandler(actionButton, row)">
                {{actionButton.label}}
              </button>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getColumnsToDisplay(); sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: getColumnsToDisplay();"></tr>
    </table>
  </div>
  <mat-paginator *ngIf="hasPageSizeOptions" [length]="resultsLength" [pageSize]="pageSize"
                 [ngClass]="{hide: isShowMoreButtonActivated()}"
                 [pageSizeOptions]="pageSizeOptions"
  ></mat-paginator>
  <mat-paginator *ngIf="!hasPageSizeOptions" [length]="resultsLength" [pageSize]="pageSize"
                 [ngClass]="{hide: isShowMoreButtonActivated()}"></mat-paginator>
  <div *ngIf="isShowMoreButtonActivated() && resultsLength > pageSize" class="smart-table-show-more">
    <button class="smart-table-show-more-btn" mat-button (click)="onShowMoreClicked()" color="primary">{{ 'ui.common.table.showMore' | translate }}</button>
  </div>
</div>

