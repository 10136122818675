import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SmartFormField} from '../smart-form.api';

@Component({
  selector: 'app-smart-form-dropdown',
  styleUrls: ['./smart-field.scss'],
  template: `
    <mat-form-field appearance="standard" class="form-field">
      <mat-label>{{field.label}} <span *ngIf="field.required"> *</span></mat-label>
      <mat-select [id]="field.name" [formControl]="controller">
        <mat-option *ngFor="let opt of field.options" [value]="opt.key">
          {{opt.label}}
        </mat-option>
      </mat-select>
      <mat-error [id]="null" *ngIf="this.formGroup.controls[this.field.name].hasError('required')">
        Pole <strong>wymagane</strong>
      </mat-error>
    </mat-form-field>
  `
})
export class SmartFormDropdownComponent {
  @Input() field: SmartFormField;
  @Input() formGroup: FormGroup;

  get controller(): FormControl {
    return this.formGroup.controls[this.field.name] as FormControl;
  }

  constructor() {
  }

}
