import {Component, Input} from '@angular/core';
import {ReportGeneratorEndpointService, ReportVO} from '../../../openapi';
import {take} from 'rxjs/operators';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-report-download-summary',
  templateUrl: './report-download-summary.component.html',
  styleUrls: ['./report-download-summary.component.scss'],
})
export class ReportDownloadSummaryComponent {

  private readonly DOCUMENT_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  @Input() public report: ReportVO;
  @Input() private currentLang: string;

  public downloading = false;

  constructor(
    private readonly reportGeneratorEndpoint: ReportGeneratorEndpointService,
  ) {
  }

  downloadReportSummaryWord(): void {
    this.downloading = true;
    this.reportGeneratorEndpoint.createDocxReportSummary(this.report.id, this.currentLang)
      .pipe(take(1))
      .subscribe(
        (response) => {
          const blob = new Blob([response], {type: this.DOCUMENT_FILE_TYPE});
          fileSaver.saveAs(blob, 'raport-summary.docx');
          this.downloading = false;
        },
        () => this.downloading = false,
      );
  }
}
