import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortDirectionEnum} from './enums/sort-direction.enum';

@Component({
  selector: 'app-sort-indicator',
  templateUrl: './sort-indicator.component.html',
  styleUrls: ['./sort-indicator.component.scss']
})
export class SortIndicatorComponent implements OnInit {

  @Input() height: number;
  @Output() sortChange = new EventEmitter<SortDirectionEnum>();
  sortDirection: SortDirectionEnum = SortDirectionEnum.NONE;

  constructor() {
  }

  ngOnInit(): void {
  }

  onSortIndicatorClick(): void {
    switch (this.sortDirection) {
      case SortDirectionEnum.NONE:
        this.sortDirection = SortDirectionEnum.ASC;
        break;
      case SortDirectionEnum.ASC:
        this.sortDirection = SortDirectionEnum.DESC;
        break;
      case SortDirectionEnum.DESC:
        this.sortDirection = SortDirectionEnum.NONE;
        break;
    }
    this.sortChange.emit(this.sortDirection);
  }

  isSortDESC(): boolean {
    return this.sortDirection === SortDirectionEnum.DESC;
  }

  isSortASC() {
    return this.sortDirection === SortDirectionEnum.ASC;
  }
}
