import {Observable} from 'rxjs';
import {EventEmitter} from '@angular/core';

export enum SmartTableColumnType {
  TEXT = 'text',
  BOOLEAN = 'boolean',
  AGGREGATE_BOOLEAN = 'aggregate_boolean',
  AGGREGATE_COMMA_SEPARATED_BOOLEAN = 'aggregate_comma_separated_boolean',
  AGGREGATE_FROM_JSON_ARRAY = 'aggregate_from_json_array',
  DATE_TIME = 'date_time',
  DATE = 'date',
  ENUM = 'enum',
}

export interface SmartTableAggregatedColumn {
  readonly name: string;
  readonly label: string;
}

export interface SmartTableEnumColumn {
  readonly value: string;
  readonly label: string;
}

export interface SmartTableAggregatedJsonArrayColumn {
  readonly fieldName: string;
  readonly fieldValue: any;
  readonly label: string;
}

export interface SmartTableColumnClicked {
  readonly columnName: string;
  readonly rowValues: any;
}

export interface SmartTableColumnOptions {
  readonly autocomplete?: boolean;
  readonly aggregatedColumns?: SmartTableAggregatedColumn[];
  readonly aggregatedJsonArrayColumns?: SmartTableAggregatedJsonArrayColumn[];
  readonly enumColumnValues?: SmartTableEnumColumn[];
  readonly enumColumnIconFunction?: (row: any, value: any) => string | undefined;
  readonly colorCodingFunction?: (value: any) => string | undefined;
  readonly globalColorCodingFunction?: (value: any) => string | undefined;
  readonly cellCustomCssClassFunction?: (row: any, value: any) => string | undefined;
  readonly textColumnValuePostfix?: string;
  readonly overrideDisplayedValueFunction?: (row: any, value: any) => string | undefined;
}

export interface SmartTableActionButton {
  tooltip?: string;
  icon?: string;
  label?: string;
  style?: undefined | 'primary' | 'warn' | 'accent';
  customClass?: string;
  callback: (rowValues: any) => void;
  isVisible?: (rowValues: any) => boolean;
}

export class SmartTableColumn {
  readonly name: string;
  readonly label: string;
  readonly type: SmartTableColumnType;
  readonly autocomplete: boolean;
  readonly aggregatedColumns?: SmartTableAggregatedColumn[];
  readonly aggregatedJsonArrayColumns?: SmartTableAggregatedJsonArrayColumn[];
  readonly enumColumnValues?: SmartTableEnumColumn[];
  readonly textColumnValuePostfix?: string;
  readonly enumColumnIconFunction?: (row: any, value: any) => string | undefined = (_row, _value) => undefined;
  readonly colorCodingFunction?: (value: any) => string | undefined = (_value) => undefined;
  readonly globalColorCodingFunction?: (row: any) => string | undefined = (_row) => undefined;
  readonly cellCustomCssClassFunction?: (row: any, value: any) => string | undefined = (_row, _value) => undefined;
  readonly overrideDisplayedValueFunction?: (row: any, value: any) => string | undefined = (_row, _value) => undefined;

  constructor(name: string, label: string, type: SmartTableColumnType, options?: SmartTableColumnOptions) {
    this.name = name;
    this.label = label;
    this.type = type;
    this.autocomplete = (type === SmartTableColumnType.TEXT);

    this.aggregatedColumns = options?.aggregatedColumns != null ? options.aggregatedColumns : undefined;
    this.aggregatedJsonArrayColumns = options?.aggregatedJsonArrayColumns != null ? options.aggregatedJsonArrayColumns : undefined;
    this.enumColumnValues = options?.enumColumnValues != null ? options.enumColumnValues : undefined;
    this.textColumnValuePostfix = options?.textColumnValuePostfix != null ? options.textColumnValuePostfix : undefined;
    this.enumColumnIconFunction = options?.enumColumnIconFunction != null ? options.enumColumnIconFunction : undefined;
    this.colorCodingFunction = options?.colorCodingFunction != null ? options.colorCodingFunction : undefined;
    this.globalColorCodingFunction = options?.globalColorCodingFunction != null ? options.globalColorCodingFunction : undefined;
    this.autocomplete = options?.autocomplete != null ? options.autocomplete : (type === SmartTableColumnType.TEXT);
    this.cellCustomCssClassFunction = options?.cellCustomCssClassFunction != null ? options.cellCustomCssClassFunction : undefined;
    this.overrideDisplayedValueFunction = options?.overrideDisplayedValueFunction != null ? options.overrideDisplayedValueFunction : undefined;
  }
}

export class SmartTableDataSourceDef {
  readonly dataProvider: SmartTableDataProvider;
  readonly columns: SmartTableColumn[];
  readonly reloadEvent: EventEmitter<void> = new EventEmitter<void>();
  readonly columnVisibilityChangeEvent: EventEmitter<void> = new EventEmitter<void>();
  readonly hiddenColumnNames: string[] = [];

  filter: any = {};
  requiredFilter: any;

  constructor(dataProvider: SmartTableDataProvider,
              columns: SmartTableColumn[] = [],
              requiredFilter: any = {}) {
    this.dataProvider = dataProvider;
    this.columns = columns;
    this.requiredFilter = requiredFilter;
  }

  public addColumn(column: SmartTableColumn) {
    this.columns.push(column);
  }

  public reload() {
    this.reloadEvent.emit();
  }

  public hideColumns(columnNames: string[]) {
    this.hiddenColumnNames.length = 0;
    this.hiddenColumnNames.push(...columnNames);
    this.columnVisibilityChangeEvent.emit();
  }

  public getVisibleColumns(): string[] {
    const displayedColumns: string[] = [];
    this.columns.forEach(item => {
      if (!this.hiddenColumnNames.includes(item.name)) {
        displayedColumns.push(item.name);
      }
    });
    return displayedColumns;
  }

  public getVisibleColumnLabels(extractFromAggregates = true) {
    const visibleLabels: any = {};
    this.columns.forEach(item => {
      if (!this.hiddenColumnNames.includes(item.name)) {
        if (extractFromAggregates && item.type === SmartTableColumnType.AGGREGATE_BOOLEAN) {
          item.aggregatedColumns.forEach(aggregatedColumn => {
            visibleLabels[aggregatedColumn.name] = aggregatedColumn.label;
          });
        } else {
          visibleLabels[item.name] = item.label;
        }
      }
    });
    return visibleLabels;
  }

  setRequiredFilter(name: string, value: any) {
    this.requiredFilter[name] = value;
  }
}

export interface SmartTableDataProvider {
  fetchData(page: number, pageSize: number, filter: any, sortBy: string, sortDescending: boolean): Observable<SmartTablePageData>;

  exportData(filter: any, sortBy: string, sortDescending: boolean, dataSource: SmartTableDataSourceDef): Observable<Blob>;
}

export interface SmartTablePageData {
  total: number;
  items: Array<any>;
}
