<div class="action-bar" fxLayout="row" fxLayoutAlign="space-between end">
  <div class="action-bar-left" fxLayout="row" fxLayoutAlign="center end">
    <app-smart-table-filter [dataSourceDef]="dataSourceDef"
                            (filterChanged)="onFilterChanged($event)"
                            [allFiltersOpened]="allFiltersOpened"></app-smart-table-filter>
    <button *ngIf="hasExcelExport" mat-button (click)="exportToExcel()" color="primary" [disabled]="!tableHasData">
      <mat-icon>get_app</mat-icon>
      Excel
    </button>
  </div>
  <div class="action-bar-right" fxLayout="row" fxLayoutAlign="center end">
    <ng-container *ngFor="let btn of rightBarButtons">
      <button mat-button (click)="btn.callback()" color="{{btn.style}}">
        <mat-icon>{{btn.icon}}</mat-icon>
        {{btn.label}}</button>
    </ng-container>
  </div>
</div>
