import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionFormData} from 'src/app/modules/annonymous/answers/model/question-form-data';
import {
  QuestionContentJson,
  QuestionSubquery
} from 'src/app/modules/admin/report-metadata-settings/models/question-content-json';
import {AnswerContentJson} from 'src/app/modules/annonymous/answers/model/answer-content-json';
import {AnswerPerType} from 'src/app/modules/annonymous/answers/model/answer-per-type';
import {TranslateFacadeService} from '../../../../../shared/services/translate-facade.service';
import {ProposalAction, ProposalAnswer} from '../../model/answer-proposal.model';
import {ProposalService} from '../../proposal.service';
import {Observable} from 'rxjs';
import {Topic} from '../../../../admin/report-metadata-settings/models/topic';
import {map} from 'rxjs/operators';
import {TopicService} from '../../../../admin/report-metadata-settings/services/topic.service';

export type QuestionContent = QuestionContentJson | QuestionSubquery;

@Component({
  selector: 'app-question-answer-form',
  templateUrl: './question-answer-form.component.html',
  styleUrls: ['./question-answer-form.component.scss']
})
export class QuestionAnswerFormComponent implements OnInit {

  @Input() topicIds: number[] | undefined;
  @Input() isReviewer: boolean;
  @Input() customMetricName = undefined;
  @Input() data: QuestionFormData;
  @Input() content: QuestionContent;
  @Input() isSub: boolean;
  @Input() subIndex: number;
  @Input() firstInGroup: boolean;
  @Input() lastInGroup: boolean;
  @Input() token: string;
  @Input() disabled: boolean;
  @Input() proposalsAction: ProposalAction;
  @Output() valueChanged: EventEmitter<void> = new EventEmitter<void>();

  answer: AnswerPerType;
  comment: string;

  topics: Observable<Topic[]>;

  language: string;

  proposal: ProposalAnswer & { comment?: boolean };

  constructor(
    private readonly translate: TranslateFacadeService,
    private readonly proposalService: ProposalService,
    private readonly topicService: TopicService
  ) {
  }

  ngOnInit(): void {
    this.language = this.translate.currentLang;
    this.translate.onLangChange.subscribe(langChangeEvent => this.language = langChangeEvent.lang);

    if (!this.data.answerContent) {
      this.data.answerContent = this.initAnswerContent();
      this.emitChange();
    }
    if (this.data.answerContent.comment) {
      this.comment = this.data.answerContent.comment;
    }
    this.setAnswerVariable();

    if (this.topicIds) {
      this.topics = this.topicService.getChildTopics().pipe(
        map(topics => topics.filter(topic => this.topicIds.includes(topic.id)))
      );
    }

    this.proposal = this.proposalService.fetchProposal(this.data.questionId, this.subIndex);
  }

  initAnswerContent(): AnswerContentJson {
    return {
      answer: this.updateAnswerProperties({} as AnswerPerType),
      subAnswers: [],
      comment: '',
      customMetricName: this.customMetricName,
    } as AnswerContentJson;
  }

  setAnswerVariable(): void {
    if (this.isSub) {
      this.setSubAnswer();
    } else {
      this.setMainAnswer();
    }
  }

  private setSubAnswer(): void {
    if (this.data.answerContent.subAnswers && this.data.answerContent.subAnswers[this.subIndex]) {
      const answerPerType = this.data.answerContent.subAnswers[this.subIndex];
      this.answer = this.updateAnswerProperties(answerPerType);
    } else {
      this.answer = this.updateAnswerProperties({} as AnswerPerType);
      this.data.answerContent.subAnswers.push(this.answer);
    }
  }

  private setMainAnswer(): void {
    this.answer = this.updateAnswerProperties(this.data.answerContent.answer);
  }

  isCSRDForm(): boolean {
    return this.content.duplicateForEachTopic && this.topicIds?.length > 0;
  }

  updateAnswerProperties(answer: AnswerPerType, topic: boolean = false): AnswerPerType {
    const answerKeys: string[] = [];
    if (!topic && this.topicIds && this.topicIds.length > 0) {
      answerKeys.push('TOPICS');
      if (!answer.TOPICS) {
        answer.TOPICS = {};
      }

      this.topicIds.forEach(topicId => {
        const objectToUpdateProperties = answer.TOPICS[topicId] ? answer.TOPICS[topicId] : {} as AnswerPerType;
        answer.TOPICS[topicId] = this.updateAnswerProperties(objectToUpdateProperties, true);
      });
    } else {
      answerKeys.push(...Object.keys(this.content.answerTypes)
        .filter(key => ['TEXT', 'NUMBER', 'SUPPORT', 'OPTION', 'DATASOURCE', 'TRUTH', 'CHECKLIST'].includes(key)));
      if (this.content.usersText) {
        answerKeys.push('userText');
      }
    }

    answerKeys.forEach((key) => {
      // Create property if do not exist
      if (!(key in answer)) {
        answer[key] = {};
      }
    });

    // Remove if was deleted from question
    Object.keys(answer).forEach((key) => {
      if (!answerKeys.includes(key)) {
        delete answer[key];
      }
    });

    return answer;
  }

  emitChange(): void {
    const metricName = this.data.answerContent?.customMetricName;
    if (!metricName || metricName !== this.customMetricName) {
      this.data.answerContent.customMetricName = this.customMetricName;
    }

    this.valueChanged.emit();
  }
}
