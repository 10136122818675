import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../state/auth.state';
import {AuthenticationDTO} from '../../services/authentication.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private store: Store) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAuthenticated = this.store.selectSnapshot<boolean>(AuthState.isAuthenticated);
    if (isAuthenticated) {
      const principal = this.store.selectSnapshot<AuthenticationDTO>(AuthState.principal);
      request = this.addToken(request, principal.token);
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return throwError(error);
        // return this.handle401Error(request, next);  // token refresh
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: token
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return undefined;
  }
}
