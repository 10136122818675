import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AuthState} from '../../../../../state/auth.state';
import {HttpResponse} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {AccountPublicApiEndpointService} from '../../../../../openapi';
import {AuthLogInAction} from '../../../../../state/auth.actions';
import {TranslateFacadeService} from '../../../../../shared/services/translate-facade.service';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {

  loginForm: FormGroup;
  restoreForm: FormGroup;
  passwordForm: FormGroup;
  resetEmail: string;
  formState: 'login' | 'restore' | 'restore-token' | 'restore-token-send' = 'login';
  errorMessage: string;

  @Select(AuthState.wasAuthError)
  wasAuthError$: Observable<boolean>;

  loginFormUsernamePlaceholder: string = '';
  loginFormPasswordPlaceholder: string = '';
  restoreFormEmailPlaceholder: string = '';
  passwordFormTokenPlaceholder: string = '';
  passwordFormPasswordPlaceholder: string = '';
  passwordFormPassword2Placeholder: string = '';
  private translations: { [p: string]: string };

  constructor(private readonly store: Store,
              private readonly accountPublicApiEndpointService: AccountPublicApiEndpointService,
              private readonly translate: TranslateFacadeService) {
  }

  ngOnInit(): void {
    this.translate.translationsOnInit().subscribe(translations => this.assignPlaceholders(translations));
    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required)
    });

    this.restoreForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });

    this.passwordForm = new FormGroup(
      {
        token: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        password2: new FormControl('', [Validators.required])
      }
    );
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.store.dispatch(new AuthLogInAction(this.loginForm.controls.userName.value, this.loginForm.controls.password.value));
    }
  }

  onSubmitRestore() {
    if (this.restoreForm.valid) {
      this.accountPublicApiEndpointService.sendPasswordResetToken({email: this.restoreForm.controls.email.value})
        .pipe(take(1)).subscribe(_ => {
        this.resetEmail = this.restoreForm.controls.email.value;
        this.formState = 'restore-token';
      });
    }
  }

  onSubmitPassword() {
    this.errorMessage = null;
    if (this.passwordForm.valid) {
      const password: string = '' + this.passwordForm.controls.password.value;
      const password2: string = '' + this.passwordForm.controls.password2.value;

      if (password !== password2) {
        this.errorMessage = this.translations['ui.public.set-password.validation.passwordsDontMatch'];
      } else if (password.length < 8) {
        this.errorMessage = this.translations['ui.public.set-password.validation.passwordMinLength'];
      } else if (!this.hasDigits(password)) {
        this.errorMessage = this.translations['ui.public.set-password.validation.passwordDigit'];
      } else if (!this.hasLowerCaseLetters(password)) {
        this.errorMessage = this.translations['ui.public.set-password.validation.passwordSmallLetter'];
      } else if (!this.hasUpperCaseLetters(password)) {
        this.errorMessage = this.translations['ui.public.set-password.validation.passwordBigLetter'];
      } else if (!this.hasSpecialChars(password)) {
        this.errorMessage = this.translations['ui.public.set-password.validation.passwordSpecialChars'];
      } else {
        this.accountPublicApiEndpointService.resetPassword({
          email: this.resetEmail,
          password,
          token: '' + this.passwordForm.controls.token.value
        }, 'response')
          .pipe(take(1)).subscribe((res: HttpResponse<any>) => {
          if (res.status === 200) {
            this.formState = 'restore-token-send';
          } else {
            this.errorMessage = this.translations['ui.public.set-password.failed.'];
          }
        });
      }
    }
  }

  private hasDigits(password: string): boolean {
    return /(?=.*[0-9]).*/.test(password);
  }

  private hasLowerCaseLetters(password: string): boolean {
    return /(?=.*[a-z]).*/.test(password);
  }

  private hasUpperCaseLetters(password: string): boolean {
    return /(?=.*[A-Z]).*/.test(password);
  }

  private hasSpecialChars(password: string): boolean {
    return /.*[~!@#$%^&*()_-].*/.test(password);
  }

  showPasswordRestoreForm() {
    this.restoreForm.controls.email.setValue('');
    this.errorMessage = null;
    this.formState = 'restore';
  }

  showLoginForm() {
    this.loginForm.controls.userName.setValue('');
    this.loginForm.controls.password.setValue('');
    this.errorMessage = null;
    this.formState = 'login';
  }

  private assignPlaceholders(translation: { [key: string]: string }) {
    this.translations = translation;
    this.loginFormUsernamePlaceholder = translation['ui.public.login.login-form.input.username'];
    this.loginFormPasswordPlaceholder = translation['ui.public.login.login-form.input.password'];
    this.restoreFormEmailPlaceholder = translation['ui.public.login.restore-form.input.email'];
    this.passwordFormTokenPlaceholder = translation['ui.public.login.password-form.input.token'];
    this.passwordFormPasswordPlaceholder = translation['ui.public.login.password-form.input.password'];
    this.passwordFormPassword2Placeholder = translation['ui.public.login.password-form.input.password2'];
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }
}
