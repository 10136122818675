import {Injectable} from '@angular/core';
import {CreateTopicCommand, TopicEndpointService, TopicView} from '../../../../openapi';
import {Observable} from 'rxjs';
import {flatMap, map, mergeMap} from 'rxjs/operators';
import {Topic} from '../models/topic';

export type HierarchicalTopic = Topic & { children: Topic[] };

@Injectable({providedIn: 'root'})
export class TopicService {

  constructor(
    private readonly service: TopicEndpointService
  ) {
  }

  getTopics(): Observable<HierarchicalTopic[]> {
    return this.service.getAllTopics().pipe(
      map((topics: TopicView[]) => this.convertToHierarchical(topics))
    );
  }

  getChildTopics(): Observable<Topic[]> {
    return this.getTopics().pipe(
      map(topics => {
        const children = [];
        for (const topic of topics) {
          children.push(...topic.children);
        }
        return children;
      })
    );
  }

  getTopicsByParentId(parentId: number): Observable<Topic[]> {
    return this.service.getTopicsByParentId(parentId)
      .pipe(map((topics: TopicView[]) => topics.map(this.convertToTopic)));
  }

  getTopicById(id: number): Observable<Topic> {
    return this.service.getTopicById(id)
      .pipe(map(this.convertToTopic));
  }

  saveTopic(command: CreateTopicCommand): Observable<Topic> {
    return this.service.create(command)
      .pipe(map(this.convertToTopic));
  }

  updateTopic(id: number, topic: TopicView): Observable<Topic> {
    return this.service.update(id, topic)
      .pipe(map(this.convertToTopic));
  }

  deleteTopic(id: number): Observable<void> {
    return this.service._delete(id);
  }

  private convertToTopic(view: TopicView): Topic {
    return {...view} as Topic;
  }

  private convertToHierarchical(topics: TopicView[]): HierarchicalTopic[] {
    const parents = topics.filter(topic => !topic.parentId);
    return parents.map(parent => {
      const children = topics
        .filter(topic => topic.parentId === parent.id)
        .map(topic => this.convertToTopic(topic));

      return {...parent, children} as HierarchicalTopic;
    });
  }
}
