import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  SmartTableActionButton,
  SmartTableAggregatedColumn,
  SmartTableAggregatedJsonArrayColumn,
  SmartTableColumnClicked,
  SmartTableDataSourceDef,
  SmartTableEnumColumn
} from './smart-table.api';
import {MatPaginator} from '@angular/material/paginator';
import {interval, merge, of, Subject, Subscription} from 'rxjs';
import {catchError, debounce, delay, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {SmartTableActionBarButton} from './smart-table-action-bar/smart-table-action-bar.component';
import {MatSort} from '@angular/material/sort';
import {DateTime} from 'luxon';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../confirm-dialog/confirm-dialog.component';
import {Router} from '@angular/router';
import {TranslateFacadeService} from '../../services/translate-facade.service';

@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss']
})
export class SmartTableComponent implements OnInit, AfterViewInit, OnDestroy {
  dataSource = [];
  displayedColumns: string[] = [];
  resultsLength = 0;
  isLoadingResults = false;

  @Input()
  clickableColumns: string[] = [];

  @Input()
  hasExcelExport = true;

  @Input()
  hideCommandPanel = false;

  @Input()
  pageSize = 15;

  @Input()
  pageSizeOptions = [15, 50, 100];

  @Input()
  hasPageSizeOptions = true;

  @Input()
  showMoreRoute: string;

  @Output()
  showMoreClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  columnClicked: EventEmitter<SmartTableColumnClicked> = new EventEmitter<SmartTableColumnClicked>();

  @Input()
  allFiltersOpened = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  readonly dataSourceDef: SmartTableDataSourceDef;

  @Input()
  readonly rightBarButtons: SmartTableActionBarButton[];

  @Input()
  readonly actionButtons: SmartTableActionButton[] = [];

  private filterChangedStream: Subject<any> = new Subject<any>();
  private subscriptions: Subscription = new Subscription();
  private translations: { [p: string]: string };

  constructor(public dialog: MatDialog,
              private router: Router,
              private readonly translate: TranslateFacadeService) {
  }

  ngOnInit(): void {
    if (this.dataSourceDef != null) {
      this.displayedColumns = this.dataSourceDef.getVisibleColumns();

      this.subscriptions.add(this.filterChangedStream.asObservable().pipe(
        debounce(() => interval(300)),
        distinctUntilChanged((a, b) => {
          function jsonEqual(v1: any, v2: any) {
            return JSON.stringify(v1) === JSON.stringify(v2);
          }

          return jsonEqual(a, b);
        })
      ).subscribe(filter => {
        this.dataSourceDef.filter = filter;
        this.dataSourceDef.reload();
      }));

      this.subscriptions.add(this.dataSourceDef.columnVisibilityChangeEvent.subscribe(_ => {
        this.displayedColumns = this.dataSourceDef.getVisibleColumns();
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    if(this.dataSourceDef != null) {
      // If the user changes the sort order, reset back to the first page.
      this.subscriptions.add(this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0));

      merge(this.sort.sortChange, this.paginator.page, this.dataSourceDef.reloadEvent)
        .pipe(
          startWith({}),
          delay(0),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.dataSourceDef.dataProvider.fetchData(
              this.paginator.pageIndex,
              this.paginator.pageSize,
              {...this.dataSourceDef.filter, ...this.dataSourceDef.requiredFilter},
              this.sort.active,
              this.sort.direction === 'desc'
            );
          }),
          map(data => {
            this.isLoadingResults = false;
            this.resultsLength = data.total;
            return data.items;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return of([]);
          })
        ).subscribe(data => this.dataSource = data);


      this.subscriptions.add(this.translate.translationsOnInit().subscribe((translations: { [p: string]: string }) => {
        this.translations = translations;
        this.paginator._intl.itemsPerPageLabel = translations['ui.common.table.rowsOnPage'] ?? 'Wierszy na stronie';
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return translations['ui.common.no-data'] ?? 'Brak danych';
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1} - ${endIndex} z ${length}`;
        };
      }));
    }
  }

  onFilterChanged($event: any) {
    this.filterChangedStream.next($event);
  }

  onColumnClicked(columnName: string, rowValues: any) {
    this.columnClicked.emit({
      columnName,
      rowValues
    });
  }

  formatDateTime(dateTimeValue: any): string {
    if (!dateTimeValue) {
      return '';
    }
    return DateTime.fromISO(dateTimeValue).toFormat('dd.MM.yyyy / HH:mm');
  }

  formatDate(dateValue: any): string {
    if (!dateValue) {
      return '';
    }
    return DateTime.fromISO(dateValue).toFormat('dd.MM.yyyy');
  }

  getColumnsToDisplay() {
    if (this.actionButtons.length !== 0) {
      return [...this.displayedColumns, 'rowActionButtonsColumn'];
    }
    return this.displayedColumns;
  }

  callActionHandler(actionButton: SmartTableActionButton, row: any) {
    if (actionButton.style !== 'warn') {
      actionButton.callback(row);
    } else {
      const message = this.translations['ui.common.table.confirm'] || `Na pewno wykonać?`;
      const dialogData: ConfirmDialogModel = {
        title: actionButton.tooltip,
        message
      };

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult === true) {
          actionButton.callback(row);
        }
      });
    }
  }

  getEnumColumnLabel(rowElement: any, enumColumnValues: SmartTableEnumColumn[]) {
    const valueAsString = '' + rowElement;
    const smartTableEnumColumn = enumColumnValues.find(item => item.value === valueAsString);
    if (smartTableEnumColumn !== undefined) {
      return smartTableEnumColumn.label;
    }
    return rowElement;
  }

  isShowMoreButtonActivated(): boolean {
    return this.showMoreRoute != null || this.showMoreClicked.observers.length > 0;
  }

  onShowMoreClicked() {
    if (this.showMoreClicked.observers.length > 0) {
      this.showMoreClicked.emit();
    } else {
      this.router.navigate([this.showMoreRoute]);
    }
  }

  isCommaSeparatedAggregateColumnSelected(rowElement: any, aggregatedColumn: SmartTableAggregatedColumn) {
    const values = `${rowElement}`.split(',');
    if (values.find(item => item === aggregatedColumn.name) != null) {
      return true;
    }
    return false;
  }

  isJsonArrayAggregateColumnSelected(rowElement: any, aggregatedColumn: SmartTableAggregatedJsonArrayColumn) {
    const values: Array<any> = rowElement;
    return values.find(value => {
      return value[aggregatedColumn.fieldName] === aggregatedColumn.fieldValue;
    }) != null;
  }
}
