import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountEndpointService } from './api/accountEndpoint.service';
import { AccountPublicApiEndpointService } from './api/accountPublicApiEndpoint.service';
import { AdminDatabaseOrganizationEndpointService } from './api/adminDatabaseOrganizationEndpoint.service';
import { AdminDepartmentEndpointService } from './api/adminDepartmentEndpoint.service';
import { AdminPkdEndpointService } from './api/adminPkdEndpoint.service';
import { AdminTaxonomyProjectQuantitativeDataEndpointService } from './api/adminTaxonomyProjectQuantitativeDataEndpoint.service';
import { AdminTaxonomyQuantitativeDataEndpointService } from './api/adminTaxonomyQuantitativeDataEndpoint.service';
import { AnswerEndpointService } from './api/answerEndpoint.service';
import { CsrdEndpointService } from './api/csrdEndpoint.service';
import { DatabaseAccountPublicApiEndpointService } from './api/databaseAccountPublicApiEndpoint.service';
import { DatabaseLinkEndpointService } from './api/databaseLinkEndpoint.service';
import { DatabaseNotificationEndpointService } from './api/databaseNotificationEndpoint.service';
import { DatabaseOrganizationEndpointService } from './api/databaseOrganizationEndpoint.service';
import { DatabaseReportEndpointService } from './api/databaseReportEndpoint.service';
import { DatabaseTaxonomyEndpointService } from './api/databaseTaxonomyEndpoint.service';
import { DatabaseTaxonomyProjectEndpointService } from './api/databaseTaxonomyProjectEndpoint.service';
import { DocumentEndpointService } from './api/documentEndpoint.service';
import { IndexEndpointService } from './api/indexEndpoint.service';
import { LoginEndpointService } from './api/loginEndpoint.service';
import { OrganizationEndpointService } from './api/organizationEndpoint.service';
import { OrganizationPackageEndpointService } from './api/organizationPackageEndpoint.service';
import { OrganizationPublicEndpointService } from './api/organizationPublicEndpoint.service';
import { OrganizationReportMetricsEndpointService } from './api/organizationReportMetricsEndpoint.service';
import { OrganizationReportPublicEndpointService } from './api/organizationReportPublicEndpoint.service';
import { OrganizationReportSettingsEndpointService } from './api/organizationReportSettingsEndpoint.service';
import { OrganizationSettingsEndpointService } from './api/organizationSettingsEndpoint.service';
import { PackagesEndpointService } from './api/packagesEndpoint.service';
import { PkdEndpointService } from './api/pkdEndpoint.service';
import { PublicDepartmentEndpointService } from './api/publicDepartmentEndpoint.service';
import { PublicDocumentEndpointService } from './api/publicDocumentEndpoint.service';
import { QuestionEndpointService } from './api/questionEndpoint.service';
import { ReportEndpointService } from './api/reportEndpoint.service';
import { ReportGeneratorEndpointService } from './api/reportGeneratorEndpoint.service';
import { ReportTopicAssigmentEndpointService } from './api/reportTopicAssigmentEndpoint.service';
import { ReviewerEndpointService } from './api/reviewerEndpoint.service';
import { SectionEndpointService } from './api/sectionEndpoint.service';
import { TopicEndpointService } from './api/topicEndpoint.service';
import { TranslationEndpointService } from './api/translationEndpoint.service';
import { UserOrganizationEndpointService } from './api/userOrganizationEndpoint.service';
import { UserOrganizationPackageEndpointService } from './api/userOrganizationPackageEndpoint.service';
import { UserOrganizationReportEndpointService } from './api/userOrganizationReportEndpoint.service';
import { VariantEndpointService } from './api/variantEndpoint.service';
import { VersionEndpointService } from './api/versionEndpoint.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
