import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SmartFormField} from '../smart-form.api';

function transformToNumberString(x: any, decimalPlaces: number) {
  const val = x.toString()
    .replace(/\./g, ',') // replace . with ,
    .replace(/[,](?=.*[,])/g, '') // remove all , but last
    .replace(/(?!^)-/g, ''); // remove all - but first

  if (decimalPlaces !== undefined) {
    const valueParts = val.split(',');

    if (valueParts[0] === '') {
      valueParts[0] = '0';
    } else if (valueParts[0] === '-') {
      valueParts[0] = '-0';
    }

    if (decimalPlaces === 0) {
      return valueParts[0];
    }

    if (valueParts.length === 1) {
      return valueParts[0] + ',' + '0'.repeat(decimalPlaces);
    }

    const lengthOfDecimalPoints = valueParts[1].length;
    if (lengthOfDecimalPoints < decimalPlaces) {
      return valueParts[0] + ',' + valueParts[1] + '0'.repeat(decimalPlaces - lengthOfDecimalPoints);
    } else if (lengthOfDecimalPoints === decimalPlaces) {
      return valueParts[0] + ',' + valueParts[1];
    } else {
      return valueParts[0] + ',' + valueParts[1].substr(0, decimalPlaces);
    }
  }

  return val;
}

@Component({
  selector: 'app-smart-form-number',
  styleUrls: ['./smart-field.scss'],
  template: `
    <mat-form-field appearance="standard" class="form-field">
      <mat-label>{{field.label}} <span *ngIf="field.required"> *</span></mat-label>
      <input matInput [id]="field.name" [name]="field.name" [formControl]="controller"
             [placeholder]="field.placeholder" (blur)="_onBlur()" (keyup.enter)="_onKeyEnter()"
             (ngModelChange)="onChange($event)">

      <mat-error [id]="null" *ngIf="this.formGroup.controls[this.field.name].hasError('required')">
        Pole <strong>wymagane</strong>
      </mat-error>
    </mat-form-field>
  `
})
export class SmartFormNumberComponent implements OnInit {
  @Input() field: SmartFormField;
  @Input() formGroup: FormGroup;

  decimalPoints?: number;
  max?: number;
  min?: number;

  value: string;
  formattedValue: string;

  get isValid() {
    return this.controller.valid;
  }

  get isDirty() {
    return this.controller.dirty;
  }

  get controller(): FormControl {
    return this.formGroup.controls[this.field.name] as FormControl;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.value = this.controller.value;
    this.decimalPoints = this.field.digits;
    this.max = this.field.max;
    this.min = this.field.min;
  }

  private valueAsNumber() {
    return Number(this.value.replace(',', '.'));
  }

  _onBlur() {
    this.formatValue(this.controller.value);
  }

  _onKeyEnter() {
    this.formatValue(this.controller.value);
  }

  onChange($event: any) {
    if ($event.toString().trim() === '') {
      this.value = '';
    } else {
      const val = $event.toString().replace(/[^\d,.-]/g, '');
      this.value = val;
      if ($event !== val) {
        this.controller.setValue(val);
      }
    }
  }

  private formatValue(value: string | null) {
    if (value !== null && value !== undefined && value.trim() !== '') {
      this.value = transformToNumberString(value, this.decimalPoints);
      this.formattedValue = this.value;
    } else {
      this.value = '';
      this.formattedValue = '';
    }

    const numValue = this.valueAsNumber();
    if (!isNaN(numValue) && this.min !== undefined) {
      if (numValue < this.min) {
        this.value = transformToNumberString(this.min, this.decimalPoints);
        this.formattedValue = this.value;
      }
    }

    if (!isNaN(numValue) && this.max !== undefined) {
      if (numValue > this.max) {
        this.value = transformToNumberString(this.max, this.decimalPoints);
        this.formattedValue = this.value;
      }
    }

    if (this.controller.value !== this.value) {
      this.controller.setValue(this.value);
    }
  }
}
