/**
 * SReport API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VariantVO { 
    id?: number;
    name?: string;
    type?: VariantVO.TypeEnum;
    parentVariantId?: number;
    order?: number;
}
export namespace VariantVO {
    export type TypeEnum = 'REPORT' | 'REPORT_TYPE' | 'OPTIONAL';
    export const TypeEnum = {
        REPORT: 'REPORT' as TypeEnum,
        REPORTTYPE: 'REPORT_TYPE' as TypeEnum,
        OPTIONAL: 'OPTIONAL' as TypeEnum
    };
}


