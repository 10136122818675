import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../state/auth.state';
import unlogedRoutes from '../../routes/unloged.routes';

@Injectable({
  providedIn: 'root'
})
export class AppAccessGuardService implements CanActivate {

  constructor(private router: Router,
              private store: Store) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this.store.selectSnapshot<boolean>(AuthState.isAuthenticated);
    if (!isAuthenticated) {
      this.router.resetConfig(unlogedRoutes);
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
