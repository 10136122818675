import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SmartTableDataSourceDef} from '../smart-table.api';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-smart-table-action-bar',
  templateUrl: './smart-table-action-bar.component.html',
  styleUrls: ['./smart-table-action-bar.component.scss']
})
export class SmartTableActionBarComponent implements OnInit {
  readonly fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  @Input()
  readonly dataSourceDef: SmartTableDataSourceDef;

  @Input()
  rightBarButtons: SmartTableActionBarButton[];

  @Input()
  tableHasData: boolean;

  @Input()
  hasExcelExport: boolean;

  @Output()
  filterChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  allFiltersOpened: boolean;

  ngOnInit(): void {
    this.rightBarButtons = this.rightBarButtons ?? [];
  }

  onFilterChanged($event: any) {
    this.filterChanged.emit($event);
  }

  exportToExcel() {
    this.dataSourceDef.dataProvider.exportData(
      {...this.dataSourceDef.filter, ...this.dataSourceDef.requiredFilter},
      this.dataSourceDef.columns[0].name,
      false,
      this.dataSourceDef
    )
      .subscribe(response => {
        const blob = new Blob([response], {type: this.fileType});
        fileSaver.saveAs(blob, 'export.xlsx');
      });
  };
}

export interface SmartTableActionBarButton {
  label: string;
  icon: string;
  style: string;
  callback: () => void;
}
