<div class="yes-no-question-container">
  <div class="question"><ng-content></ng-content></div>
  <div class="label">{{noLabel}}</div>
  <mat-slide-toggle [(ngModel)]="value"
                    (ngModelChange)="changed.emit(value)"
                    [disabled]="disabled"
                    color="primary">
  </mat-slide-toggle>
  <div class="label">{{yesLabel}}</div>
</div>
