<div class="modal-container">
    <div class="circle" *ngIf="!edit && !customIcon && showIcon"><div class="plus">+</div></div>
    <div class="circle" *ngIf="edit && !customIcon && showIcon"><mat-icon>edit</mat-icon></div>
    <div class="circle" *ngIf="!!customIcon && showIcon"><mat-icon>{{customIcon}}</mat-icon></div>
    <h1 mat-dialog-title>{{edit ? headerEdit : headerAdd}}</h1>
    <div *ngIf="showError" class="error-message">
        {{errorMessage ? errorMessage : 'Wystąpił błąd.Spróbuj jeszcze raz, lub skontaktuj się z dostawcą oprogramowania.'}}
    </div>
    <mat-dialog-content>
        <div class="content-container">
            <ng-content></ng-content>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="buttonsEnabled">
        <button class="button cancel" mat-button mat-dialog-close>{{'ui.save-dialog.action.cancel' | translate }}</button>
        <button class="button save" mat-button (click)="save()" [disabled]="isSaving || !validForSave">
            {{isSaving ? '' : (saveButtonCode | translate)}}<mat-spinner *ngIf="isSaving" [diameter]="25" color="accent"></mat-spinner>
        </button>
    </mat-dialog-actions>
    <button mat-button *ngIf="buttonsEnabled && edit && canDelete" (click)="delete()"
            [disabled]="isSaving"><mat-icon color="warn">delete</mat-icon> {{'ui.save-dialog.action.delete' | translate }}</button>
</div>
